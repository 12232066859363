
const pointNameShort = process.env.VUE_APP_POINT_NAME_SHORT
export default [

    // Customs ...
    {
        status: 30000,
        name: 'Customs ...',
        reason: '',
        color: 'red',
        action: {
            userRegister: false,
            userLogin: false,
            userForgotPassword: false,
            userResentActiveEmail: false,
            userChangeProfile: false,
            userChangePassword: false,
            imageCreate: false,
            imageBoot: false,
            imageShutdown: false,
            imageConnect: false,
            imageClone: false,
            imageDelete: false,
            imageChangeServicePackage: false,
            imageRename: false,
        }
    },

    // Distributed Denial Of Service Attack (DDoS Attack)
    {
        status: 10000,
        name: 'DDoS Attack',
        reason: 'Distributed Denial Of Service Attack (DDoS Attack)',
        color: 'red',
        action: {
            userRegister: true,
            userLogin: true,
            userForgotPassword: true,
            userResentActiveEmail: true,
            userChangeProfile: true,
            userChangePassword: true,
            imageCreate: true,
            imageBoot: true,
            imageShutdown: true,
            imageConnect: true,
            imageClone: true,
            imageDelete: true,
            imageChangeServicePackage: true,
            imageRename: true,
        }
    },

    // Use multiple accounts
    {
        status: 20000,
        name: 'Use multiple accounts',
        reason: 'Use multiple accounts',
        color: 'red',
        action: {
            userRegister: true,
            userLogin: false,
            userForgotPassword: false,
            userResentActiveEmail: true,
            userChangeProfile: false,
            userChangePassword: false,
            imageCreate: false,
            imageBoot: false,
            imageShutdown: false,
            imageConnect: false,
            imageClone: false,
            imageDelete: false,
            imageChangeServicePackage: false,
            imageRename: false,
        }
    },

    // Use multiple accounts to get free point verified email
    {
        status: 20001,
        name: `Cheat get free ${pointNameShort} verified email`,
        reason: `Use multiple accounts to get free 5 ${pointNameShort} verified email`,
        color: 'red',
        action: {
            userRegister: true,
            userLogin: false,
            userForgotPassword: false,
            userResentActiveEmail: false,
            userChangeProfile: false,
            userChangePassword: false,
            imageCreate: false,
            imageBoot: true,
            imageShutdown: true,
            imageConnect: true,
            imageClone: true,
            imageDelete: false,
            imageChangeServicePackage: false,
            imageRename: false,
        }
    },
]