
import vmMachines from '@/api/gpuHub/vmMachines';
import shiftStaffApi from '@/api/administrator/shiftStaffApi';
import systemTrackingCalendar from '@/api/common/systemTrackingCalendar.js';
import userTagClassifyApi from '@/api/common/userTagClassify';
import moment from "moment";
moment().isoWeekday(1);

const fnGetCalendarIndex = (commit, numberOfStaff) => {
    //console.log('isafter', isafter);
    moment().isoWeekday(1);
    let currentHourPresent = moment().hours();
    if (currentHourPresent >= 0 && currentHourPresent < 4) {
        commit("get_calendarIndex", 0);
        return;
    }
    if (numberOfStaff === 4) {
        if (currentHourPresent >= 4 && currentHourPresent < 12) {
            commit("get_calendarIndex", 1);
            return;
        }
        if (currentHourPresent >= 12 && currentHourPresent < 20) {
            commit("get_calendarIndex", 2);
            return;
        }

        // if (currentHourPresent >= 20 && currentHourPresent < 20) {
        //     commit("get_calendarIndex", 3);
        //     return;
        // }

        if (currentHourPresent >= 20 && currentHourPresent < 24) {
            commit("get_calendarIndex", 3);
            return;
        }
    }
    if (numberOfStaff === 5) {
        if (currentHourPresent >= 4 && currentHourPresent < 8) {
            commit("get_calendarIndex", 1);
            return;
        }
        if (currentHourPresent >= 8 && currentHourPresent < 12) {
            commit("get_calendarIndex", 2);
            return;
        }

        if (currentHourPresent >= 12 && currentHourPresent < 16) {
            commit("get_calendarIndex", 3);
            return;
        }

        if (currentHourPresent >= 16 && currentHourPresent < 20) {
            commit("get_calendarIndex", 4);
            return;
        }

        if (currentHourPresent >= 20 && currentHourPresent < 24) {
            commit("get_calendarIndex", 5);
            return;
        }
    }
    commit("get_calendarIndex",  -1);
}

const state = {
    listServicePack: [],
    listAllServicePack: [],
    availabilityServiceList: [],
    availabilityServiceAIList: [],
    technicalShift: [],
    calendarIndex: -1,
    currentDayOfWeek: 1,
    userClassify: [],
    userClassifyAll: [],
    numberOfStaff: null,
};

const getters = {
    listServicePack: state => state.listServicePack,
    listAllServicePack: state => state.listAllServicePack,
    availabilityServiceList: state => state.availabilityServiceList,
    technicalShift: state => state.technicalShift,    
    calendarIndex: state => state.calendarIndex,
    currentDayOfWeek: state => state.currentDayOfWeek,
    userClassify: state => state.userClassify,
    userClassifyAll: state => state.userClassifyAll,
};

const mutations = {

    // // Dialog notification
    // show_common_error(state, errorObj) {
    //     let msg = '';
    //     switch (errorObj.message) {
    //         case ERROR_SYSTEM_MESSAGE.error404:
    //             msg = Vue.prototype.$lang.message.error404;
    //             break;
    //         case ERROR_SYSTEM_MESSAGE.error500:
    //             msg = Vue.prototype.$lang.message.error500;
    //             break;
    //         default:
    //             msg = error.message;
    //             break;
    //     }
    //     commonActionHandle.showCustomError(
    //         Vue.prototype.$lang.dialog.connectionFailed,
    //         msg
    //     );
    // },

    // // Unknow error
    // show_unkown_error(state, res, title) {
    //     console.error(res);
    //     commonActionHandle.showUnknowError(title);
    // },

    get_service_pack(state, listServicePack) {
        state.listServicePack = listServicePack;
        state.gpuHubServicePack = [];
        state.remoteFarmServicePack = [];
        state.cpuHubServicePack = [];
        listServicePack.forEach(element => {
            switch (element.serviceType) {
                case 1:
                    state.gpuHubServicePack.push(element);
                    break;
                case 2:
                    state.remoteFarmServicePack.push(element);
                    break;
                case 3:
                    state.cpuHubServicePack.push(element);
                    break;
            }
        });
    },

    get_availability_service(state, listService) {
        state.availabilityServiceList = listService;
    },
    get_availability_service_ai(state, listService) {
        state.availabilityServiceAIList = listService;
    },

    get_technical_shift(state, technicalShift) {
        state.technicalShift = technicalShift;
    },

    get_calendarIndex(state, calendarIndex) {
        state.calendarIndex = calendarIndex;
    },

    get_currentDayOfWeek(state, currentDayOfWeek) {
        state.currentDayOfWeek = currentDayOfWeek;
    },
    get_all_classify_by_staff(state, userClassifyVal) {
         state.userClassify = userClassifyVal;
    },
    get_all_classify(state, userClassifyVal) {
         state.userClassifyAll = userClassifyVal;
    },
    set_numberOfStaff(state, numberOfStaff) {
        state.numberOfStaff = numberOfStaff;
    },
    // // Update server status
    // proccessing_update_server_status(state, data) {
    //     data.forEach(element => {
    //         state.availabilityServiceList.find(item => item.package === element.package).statusText = element.status_text;
    //     });
    // },

    // // Get user configuration
    // get_user_configuration(state, data) {
    //     state.userConfigurationList = data;
    // }
};

const actions = {
    getAllClassifyByStaff({ commit }) {
        userTagClassifyApi.getAllClassifyByStaff().then((res) => {
            try {
                if (res.data.result === 0) {
                    commit("get_all_classify_by_staff", res.data.data);
                }
            } catch (error) {
                console.log("getAllClassifyByStaff -> error", error);
            }
        })
        .catch(error => {
            console.log("getAllClassifyByStaff -> error", error);
        });
    },
    getAllClassify({ commit }) {
        userTagClassifyApi.getAllClassify().then((res) => {
            try {
                if (res.data.result === 0) {
                    commit("get_all_classify", res.data.data);
                }
            } catch (error) {
                console.log("get_all_classify -> error", error);
            }
        })
        .catch(error => {
            console.log("get_all_classify -> error", error);
        });
    },
    // // Show error 404 500
    // showCommonError({ commit }, errorObj) {
    //     commit("show_common_error", errorObj);
    // },

    // // Show unknow error
    // showUnkownError({ commit }, res, title) {
    //     commit("show_unkown_error", res, title);
    // },

    // Get availability service
    getAvailabilityService({ commit }) {
        vmMachines.getAllServerReport()
            .then((res) => {
                try {
                    //console.log('ServerReport', res);
                    var sortData = res.data.data.data.sort((a, b) => {
                        return a.adminSortOrder - b.adminSortOrder;
                    });
                    var visiblePackageResult = sortData.filter(x => !x.hideOnAdmin);
                    commit("get_availability_service", visiblePackageResult);

                } catch (error) {
                    console.log(error);
                }
            })
            .catch(error => {
                console.log(error);
            });

        // vmMachines.getAllServerReportAI()
        //     .then((res) => {
        //         try {
        //             //console.log('ServerReportAI', res);
        //             var sortData = res.data.data.data.sort((a, b) => {
        //                 return a.adminSortOrder - b.adminSortOrder;
        //             });
        //             var visiblePackageResult = sortData.filter(x => !x.hideOnAdmin);
        //             commit("get_availability_service_ai", visiblePackageResult);

        //         } catch (error) {
        //             console.log(error);
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
    },

    // Get service pack list
    getServicePack({ commit }) {
        vmMachines.getPackageList()
            .then((res) => {
                try {
                    var sortData = res.data.data.data.sort((a, b) => {
                        return a.adminSortOrder - b.adminSortOrder;
                    });
                    //var hidePackageResult = sortData.filter(x => !x.hideOnAdmin);
                    commit("get_service_pack", sortData);
                } catch (error) {
                    console.log(error);
                }
            })
            .catch(error => {
                console.log(error);
            });
    },
    getCalendarIndex({ commit, state }) {
        if(state.numberOfStaff === null){
            shiftStaffApi.getNumberOfTechStaff()
                .then((res) => {
                    try {
                        var numberOfStaff = res.data.numberOfStaff;
                        fnGetCalendarIndex(commit, numberOfStaff);
                        commit('set_numberOfStaff', numberOfStaff);
                    } catch (error) {
                        console.log(error);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
        else fnGetCalendarIndex(commit, state.numberOfStaff);
        //var numberOfStaff = 5; // moment().isAfter('2023-01-15T23:59:59Z');
        
    },    
    currentDayOfWeek({ commit }) {
        moment().isoWeekday(1);
        let currentHourPresent = moment().hours();
        let dayOfW = moment().day();
        if (currentHourPresent >= 0 && currentHourPresent <= 24) {
            if (dayOfW === 0) {
                commit("get_currentDayOfWeek", 7);
                return;
            }
            commit("get_currentDayOfWeek",  dayOfW);
            return;
        }
        else {

            if (dayOfW === 1) {
                commit("get_currentDayOfWeek", 0);
                return;
            }

            if (dayOfW > 1) {
                commit("get_currentDayOfWeek",  dayOfW - 1);
                return;
            }
            else {
                commit("get_currentDayOfWeek",  6);
                return;
            }
        }
        
    },
    getTechnicalShift({ commit }) {
        systemTrackingCalendar.getData().then(response => {
            if (response.data && response.data.result === 0 && response.data.data !== null) {
                response.data.data.forEach(x => {
                    x.userInfoMon = JSON.parse(x.mon);
                    x.userInfoTue = JSON.parse(x.tue);
                    x.userInfoWed = JSON.parse(x.wed);
                    x.userInfoThu = JSON.parse(x.thu);
                    x.userInfoFri = JSON.parse(x.fri);
                    x.userInfoSat = JSON.parse(x.sat);
                    x.userInfoSun = JSON.parse(x.sun);
                });
                commit("get_technical_shift", response.data.data);
            }
            else {
                commit("get_technical_shift", []);
                if (response.data && response.data.message !== null && response.data.message !== '') {
                    console.error(response.data.message || this.$lang.common.error);
                }
            }
        }).catch(error => {
            console.error(error);
            commit("get_technical_shift", []);
        });
    },
};

export default { namespaced: true, state, getters, mutations, actions };
