

export default {

    //PAYMENT GATEWAY HISTORY VIEW

    PAYMENT_GATEWAY_HISTORY_VIEW: 50100,



    //FIXED RENTAL

    FIXED_RENTAL_VIEW: 40100,
    FIXED_RENTAL_MODIFY: 40200,
    FIXED_RENTAL_DELETE: 40300,
    FIXED_RENTAL_CREATE: 40400,
    FIXED_RENTAL_SETTINGS: 40500,



    //SYSTEM MANAGER ___ Restricted email domain

    SYSTEM_MANAGER_VIEW: 60100,



    //SYSTEM MANAGER ___ Restricted email domain

    SYSTEM_MANAGER_RESTRICTED_EMAIL_DOMAIN_VIEW: 60200,
    SYSTEM_MANAGER_RESTRICTED_EMAIL_DOMAIN_MODIFY: 60201,
    SYSTEM_MANAGER_RESTRICTED_EMAIL_DOMAIN_CREATE: 60202,
    SYSTEM_MANAGER_RESTRICTED_EMAIL_DOMAIN_DELETE: 60203,



    //SYSTEM MANAGER ___ Restricted IP

    SYSTEM_MANAGER_RESTRICTED_IP_VIEW: 60600,
    SYSTEM_MANAGER_RESTRICTED_IP_MODIFY: 60601,
    SYSTEM_MANAGER_RESTRICTED_IP_CREATE: 60602,
    SYSTEM_MANAGER_RESTRICTED_IP_DELETE: 60603,



    //SYSTEM MANAGER ___ Restricted software

    SYSTEM_MANAGER_RESTRICTED_SOFTWARE_VIEW: 60500,
    SYSTEM_MANAGER_RESTRICTED_SOFTWARE_MODIFY: 60501,
    SYSTEM_MANAGER_RESTRICTED_SOFTWARE_CREATE: 60502,
    SYSTEM_MANAGER_RESTRICTED_SOFTWARE_DELETE: 60503,



    //SYSTEM MANAGER ___ Staff management

    SYSTEM_MANAGER_STAFF_MANAGEMENT_VIEW: 60300,
    SYSTEM_MANAGER_STAFF_MANAGEMENT_MODIFY: 60301,
    SYSTEM_MANAGER_STAFF_MANAGEMENT_CREATE: 60302,
    SYSTEM_MANAGER_STAFF_MANAGEMENT_DELETE: 60303,



    //SYSTEM MANAGER ___ Role management

    SYSTEM_MANAGER_ROLE_MANAGEMENT_VIEW: 60400,
    SYSTEM_MANAGER_ROLE_MANAGEMENT_MODIFY: 60401,
    SYSTEM_MANAGER_ROLE_MANAGEMENT_CREATE: 60402,
    SYSTEM_MANAGER_ROLE_MANAGEMENT_DELETE: 60403,



    //SYSTEM MANAGER ___ Excluded IP

    SYSTEM_MANAGER_EXCLUDED_IP_VIEW: 60700,
    SYSTEM_MANAGER_EXCLUDED_IP_MODIFY: 60701,
    SYSTEM_MANAGER_EXCLUDED_IP_CREATE: 60702,
    SYSTEM_MANAGER_EXCLUDED_IP_DELETE: 60703,



    //SYSTEM MANAGER ___ Admin functions

    SYSTEM_MANAGER_ADMIN_FUNCTIONS_VIEW: 60800,
    SYSTEM_MANAGER_ADMIN_FUNCTIONS_MODIFY: 60801,
    SYSTEM_MANAGER_ADMIN_FUNCTIONS_CREATE: 60802,
    SYSTEM_MANAGER_ADMIN_FUNCTIONS_DELETE: 60803,
};