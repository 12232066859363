export default [

    //Testing
    {
        status: 'TESTING',
        name: 'Testing',
        color: 'darkorange',
        icon: '/transactionProvider/testing.png',
    },

    //Refund
    {
        status: 'REFUND',
        name: 'Refund',
        color: 'red',
        icon: '/transactionProvider/refund.png',
    },

    //Banking 
    {
        status: 'BANKING',
        name: 'VietcomBank',
        color: '#4CAF50',
        icon: '/transactionProvider/banking.png',
    },

    //Stripe
    {
        status: 'CARD',
        name: 'Stripe',
        color: 'red',
        icon: '/transactionProvider/stripe.png',
    },

    //Others
    {
        status: 'OTHERS',
        name: 'Others',
        color: 'darkorange',
        icon: '/transactionProvider/others.png',
    },
    //Bonus
    {
        status: 'BONUS',
        name: 'Bonus',
        color: 'darkorange',
        icon: '/transactionProvider/bonus.png',
    },
]