import axios from "axios";
import { EXECUTE_RESULT } from "@/constant/config";
const queryString = require('query-string');

export default {
    getStaffShiftData: function (offset) {
        return axios({
            method: "GET",
            url: `/api/staff-shift/get-staff-shift?offsetWeek=${offset}`,
            withCredentials: true,
        });
    },
    getStaffScheduled: function (data) {
        let queryParams = queryString.stringify(data);
        return axios({
            method: "GET",
            url: `/api/staff-shift/get-staff-scheduled?${queryParams}`,
            withCredentials: true,
        });
    },
    getCurrentStaff: function () {
        return axios({
            method: "GET",
            url: `/api/staff-shift/get-current-staff`,
            withCredentials: true,
        });
    },
    setStaff(data) {
        return axios({
            method: "POST",
            url: `/api/staff-shift/set-staff`,
            data: data,
            withCredentials: true,
        });
    },
    checkIn: function (data) {
        return axios({
            method: "POST",
            url: `/api/staff-shift/check-in`,
            data: data,
            withCredentials: true,
        });
    }
}