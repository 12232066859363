<template>
    <div style="width: 100%; height: calc(100dvh - 0.032rem);" class="d-flex flex-column overflow-hidden">
        <header class="header">
            <nav class="navbar navbar-expand-lg navbar-dark theme-bg-dark d-flex">
                <div class="theme-bg-shapes-right"></div>
                <div class="theme-bg-shapes-left"></div>
                <div class="branding docs-branding">
                    <div class="container-fluid position-relative pt-0">
                        <div class="docs-logo-wrapper position-relative">
                            <div class="site-logo">
                                <a class="navbar-brand d-flex align-items-center" href="index.html">
                                    <img class="logo-icon mr-2" src="/static/image/iRENDER-logo-text-white-full.svg" alt="logo"
                                         style="height: 2.5rem;">
                                    <!--<span class="logo-text text-white d-none d-md-block pt-2 mb-0">Notification<span class="text-alt"></span></span>-->
                                </a>
                            </div>
                        </div><!--//docs-logo-wrapper-->
                    </div><!--//container-->
                </div><!--//branding-->

                <button class="navbar-toggler" type="button" data-toggle="collapse" 
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent"
                     style="z-index: 10">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            <a class="nav-link" href="/dashboard">Home</a>
                        </li>

                        <li class="nav-item">
                            <router-link class="nav-link" to="/layout-2/push-notification">
                                <i class="el-icon-bell"></i> Push notification
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/layout-2/email-notification">
                                <i class="el-icon-message"></i> Email notification
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/layout-2/template-management">
                                <i class="el-icon-brush"></i> Template
                            </router-link>
                        </li>
                        <!--<li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Push notifications
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <router-link class="dropdown-item" to="#">Promotion</router-link>
                                <router-link class="dropdown-item" to="#">Another action</router-link>
                                <div class="dropdown-divider"></div>
                                <router-link class="dropdown-item" to="#">Something else here</router-link>
                            </div>
                        </li>-->
                        <!--<li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Email notifications
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <router-link class="dropdown-item" to="#">Promotion</router-link>
                                <router-link class="dropdown-item" to="#">Another action</router-link>
                                <div class="dropdown-divider"></div>
                                <router-link class="dropdown-item" to="#">Something else here</router-link>
                            </div>
                        </li>-->
                    </ul>
                    <!--<form class="form-inline my-2 my-lg-0">
                        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                    </form>-->
                </div>
            </nav>
            
        </header><!--//header-->

        
        <router-view></router-view>  
        

        <section class="cta-section text-center py-2 theme-bg-dark position-relative m-0">
            <div class="theme-bg-shapes-right"></div>
            <div class="theme-bg-shapes-left"></div>
            <div class="footer-bottom text-center">

                <!--<ul class="social-list list-unstyled pb-4 mb-0">
                    <li class="list-inline-item"><a href="javascript:void(0);"><i class="fab fa-github fa-fw"></i></a></li>
                    <li class="list-inline-item"><a href="javascript:void(0);"><i class="fab fa-twitter fa-fw"></i></a></li>
                    <li class="list-inline-item"><a href="javascript:void(0);"><i class="fab fa-slack fa-fw"></i></a></li>
                    <li class="list-inline-item"><a href="javascript:void(0);"><i class="fab fa-product-hunt fa-fw"></i></a></li>
                    <li class="list-inline-item"><a href="javascript:void(0);"><i class="fab fa-facebook-f fa-fw"></i></a></li>
                    <li class="list-inline-item"><a href="javascript:void(0);"><i class="fab fa-instagram fa-fw"></i></a></li>
                </ul>-->
                <!--//social-list-->
                <!--/* This template is free as long as you keep the footer attribution link. If you'd like to use the template without the attribution link, you can buy the commercial license via our website: themes.3rdwavemedia.com Thank you for your support. :) */-->
                <small class="copyright text-white">
                    Developed with <i class="fas fa-heart" style="color: #fafafa;"></i> by 
                    <a class="theme-link text-white text-decoration-none" href="javascript:void(0);">Someone</a>
                </small>


            </div>
        </section><!--//cta-section-->
    </div>
</template>

<script>
    export default {
        data() {
            return {};
        },

        mounted() {
            this.$store.dispatch("auth/getUserInfo");
            this.$store.dispatch("auth/getUserRole");
            this.$store.dispatch("getListStaff");
            this.$store.dispatch("getEmailAllowedChangeSource", "QA");  
            this.$store.dispatch("common/currentDayOfWeek");
            this.$store.dispatch("common/getCalendarIndex");
            this.$store.dispatch("common/getTechnicalShift");
        },
    }
</script>
