import { ROUTE_URL } from "@/constant/route";
import DefaultLayout from "@/components/layouts/DefaultLayout";
import CodeDocsLayout from "@/components/layouts/CodeDocsLayout";
import Error404 from "@/components/layouts/error404";
const layout2Mode = "layout2";

export const publicRoute = [
  { path: "*", component: Error404 },
  {
    path: "/forbidden",
    component: () => import("@/components/layouts/forbidden.vue"),
    meta: { title: "Dashboard", group: "apps", icon: "" },
  },
  {
    path: "/login",
    name: "componentLogin",
    component: () => import("@/views/auth/Login.vue"),
  },
];

export const protectedRoute = [
  {
    path: "/",
    component: DefaultLayout,
    meta: { title: "Dashboard", group: "apps", icon: "" },
    redirect: "dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: { title: "Dashboard", requiresAuth: true, icon: "fas fa-tasks", decentralizationName: null },
        component: () => import("@/views/dashboard/Dashboard.vue"),
      },
      {
        path: "/dashboard2",
        name: "Dashboard2",
        meta: { title: "Dashboard 2", requiresAuth: true, icon: "fas fa-tasks", decentralizationName: null },
        component: () => import("@/views/dashboard/Dashboard2.vue"),
      },
      {
        path: "/staff-ranking",
        name: "StaffRanking",
        meta: { title: "Staff Ranking", requiresAuth: true, icon: "fas fa-tasks", decentralizationName: null },
        component: () => import("@/views/administrator/StaffRanking.vue"),
      },
      {
        path: "/staff-check-in",
        name: "StaffCheckin",
        meta: { title: "Staff Checkin", requiresAuth: true, icon: "fas fa-tasks", decentralizationName: null },
        component: () => import("@/views/administrator/StaffCheckin.vue"),
      },
      {
        path: "/statistics",
        name: "Statistics",
        meta: { title: "Statistics", requiresAuth: true, icon: "fas fa-tasks", decentralizationName: null },
        component: () => import("@/views/administrator/Statistics.vue"),
      },
      {
        path: "/recharge-test-statistics",
        name: "Recharge-Test-Statistics",
        meta: { title: "Recharge-Test-Statistics", requiresAuth: true, icon: "fas fa-tasks", decentralizationName: null },
        component: () => import("@/views/administrator/rechargeTestStatistics.vue"),
      },
      {
        path: "/reports",
        name: "Reports",
        meta: { title: "Statistics", requiresAuth: true, icon: "fas fa-tasks", decentralizationName: null },
        component: () => import("@/views/administrator/Reports.vue"),
      },
      {
        path: "/export-task",
        name: "Export task",
        meta: { title: "ExportTask", requiresAuth: true, icon: "fas fa-tasks", decentralizationName: null },
        component: () => import("@/views/exportTask/exportTask.vue"),
      },
      {
        path: "/chat-conversation",
        name: "ChatConversation",
        meta: { title: "Chat Conversation", requiresAuth: true, icon: "fas fa-tasks", decentralizationName: null },
        component: () => import("@/views/conversations/ConversationsList.vue"),
      },
      {
        path: "/users",
        name: "Users",
        meta: { title: "List iRender's users", requiresAuth: true, icon: "fas fa-users", decentralizationName: "USER_VIEW" },
        component: () => import("@/views/users/UsersList.vue"),
      },
      {
        path: "/prime-user-statistics",
        name: "PrimeUserStatistics",
        meta: { title: "Prime User Statistics", requiresAuth: true, icon: "fas fa-users", decentralizationName: null },
        component: () => import("@/views/primeUserStatistics/primeUserStatistics.vue"),
      },
      {
        path: "/prime-recharge-report",
        name: "PrimeRechargeReport",
        meta: { title: "Prime Recharge Report", requiresAuth: true, icon: "fas fa-users", decentralizationName: null },
        component: () => import("@/views/reportPrime/reportPrime.vue"),
      },
      {
        path: "/blacklist-users",
        name: "BlacklistUsers",
        meta: { title: "List iRender's Blacklist users", requiresAuth: true, icon: "fas fa-users", decentralizationName: "USER_VIEW" },
        component: () => import("@/views/users/BlacklistUsers.vue"),
      },
      {
        path: "/paid-users",
        name: "PaidUsers",
        meta: { title: "List iRender's Paid users", requiresAuth: true, icon: "fas fa-users", decentralizationName: "USER_VIEW" },
        component: () => import("@/views/users/PaidUsers.vue"),
      },
      {
        path: "/users-idle",
        name: "Users Idle",
        meta: { title: "List iRender's users idle for long time", requiresAuth: true, icon: "fas fa-users", decentralizationName: "USER_VIEW" },
        component: () => import("@/views/users/UsersIdleList.vue"),
      },
      {
        path: "/users-top-most-used",
        name: "UsersTopMostUsed",
        meta: { title: "List iRender's users most used", requiresAuth: true, icon: "fas fa-users", decentralizationName: "USER_VIEW" },
        component: () => import("@/views/users/UsersMostUserdList.vue"),
      },
      {
        path: "/user-active-view",
        name: "UserActiveView",
        meta: { title: "User Active View", requiresAuth: true, icon: "fas fa-users", decentralizationName: null },
        component: () => import("@/views/userActive/userActiveView.vue"),
      },
      {
        path: "/job-sync",
        name: "JobSync",
        meta: { title: "List job sync", requiresAuth: true, icon: "fas fa-users", decentralizationName: null },
        component: () => import("@/views/jobSync/JobSyncList.vue"),
      },
      {
        path: "/gpuhub-sync-view",
        name: "GPUHubSync",
        meta: { title: "GPU Hub Sync", requiresAuth: true, icon: "fas fa-users", decentralizationName: null },
        component: () => import("@/views/gpuHub/gpuhubSync/gpuhubSyncView.vue"),
      },
      {
        path: "/gpuhub-sync-abandoned",
        name: "GPUHubSyncAbandoned",
        meta: { title: "GPU Hub Sync Abandoned", requiresAuth: true, icon: "fas fa-users", decentralizationName: null },
        component: () => import("@/views/gpuHub/gpuhubSync/XZStorageAbandoned.vue"),
      },
      {
        path: "/rental-news",
        name: "RentalNews",
        meta: { title: "Rental News", requiresAuth: true, icon: "fas fa-users", decentralizationName: "RENTAL_NEW" },
        component: () => import("@/views/rentalNews/RentalNewsView.vue"),
      },
      //GPU rental
      {
        path: "/gpuhub-machines",
        name: "GPUHubMachines",
        meta: { title: "Machines of GPU Hub", requiresAuth: true, icon: "fas fa-desktop", decentralizationName: "MACHINE_VIEW" },
        component: () => import("@/views/gpuHub/vmMachines/vmMachine.vue"),
      },
      {
        path: "/b2b-machines",
        name: "B2bGPUHubMachines",
        meta: { title: "Machines of GPU Hub", requiresAuth: true, icon: "fas fa-desktop", decentralizationName: "MACHINE_VIEW" },
        component: () => import("@/views/gpuHub/vmMachines/vmMachine.vue"),
      },
      {
        path: "/gpuhub-machines-fpt",
        name: "GPUHubMachinesFpt",
        meta: { title: "Machines Fpt of GPU Hub", requiresAuth: true, icon: "fas fa-desktop", decentralizationName: "MACHINE_VIEW" },
        component: () => import("@/views/gpuHub/vmMachines/vmMachinesFpt.vue"),
      },
      {
        path: "/fixed-rental",
        name: "FixedRental",
        meta: { title: "Machines of GPU Hub", requiresAuth: true, icon: "fas fa-desktop", decentralizationName: "FIXED_RENTAL_VIEW" },
        component: () => import("@/views/gpuHub/fixedRental/FixedRental.vue"),
      },
      {
        path: "/rdp-gateway-history",
        name: "RdpGatewayHistory",
        meta: { title: "Rdp Gateway History", requiresAuth: true, icon: "fas fa-desktop", decentralizationName: "RDP_GATEWAY_VIEW" },
        component: () => import("@/views/gpuHub/rdpGateway/RdpGatewayHistory.vue"),
      },
      {
        path: "/gpuhub-servers",
        name: "GPUHubServers",
        meta: { title: "Server of GPU Hub", requiresAuth: true, icon: "fas fa-desktop", decentralizationName: "GPUHUBSERVER_VIEW" },
        component: () => import("@/views/gpuHub/vmMachines/vmServer.vue"),
      },
      {
        path: "/gpuhub-machines-abandoned",
        name: "GPUHubMachinesAbandoned",
        meta: { title: "Machines has been abandoned", requiresAuth: true, icon: "fas fa-desktop", decentralizationName: "MACHINE_VIEW" },
        component: () => import("@/views/gpuHub/vmMachines/vmMachineAbandoned.vue"),
      },
      {
        path: "/gpuhub-billing",
        name: "GPUHubBilling",
        meta: { title: "Billing of GPU Hub", requiresAuth: true, icon: "fas fa-file-invoice", decentralizationName: "GPUHUBBILLING_VIEW" },
        component: () => import("@/views/gpuHub/billing/billing.vue"),
      },
      {
        path: "/gpuhub-invoices",
        name: "GPUHubInvoice",
        meta: { title: "Invoices of GPU Hub", requiresAuth: true, icon: "fas fa-file-invoice", decentralizationName: "GPUHUBINVOICE_VIEW" },
        component: () => import("@/views/gpuHub/invoices/invoices.vue"),
      },
      {
        path: "/gpuhub-transactions",
        name: "GPUHubTransaction",
        meta: { title: "Transactions of GPU Hub", requiresAuth: true, icon: "fas fa-file-invoice", decentralizationName: "TRANSACTION_VIEW" },
        component: () => import("@/views/gpuHub/transactions/transactions.vue"),
      },
      {
        path: "/refund-transactions",
        name: "PaymentRefundTransaction",
        meta: { title: "Refund transactions", requiresAuth: true, icon: "fas fa-file-invoice", decentralizationName: "TRANSACTION_VIEW" },
        component: () => import("@/views/gpuHub/transactions/refundTransaction.vue"),
      },
      {
        path: "/gpuhub-service-package",
        name: "GPUHubServicePackage",
        meta: { title: "Service Package of GPU Hub", requiresAuth: true, icon: "fas fa-box", decentralizationName: null },
        component: () => import("@/views/gpuHub/others/servicePackage.vue"),
      },
      {
        path: "/gpuhub-payment-bonus",
        name: "GPUHubPaymentBonus",
        meta: { title: "Payment Bonus of GPU Hub", requiresAuth: true, icon: "fas fa-percent", decentralizationName: "TRANSACTION_VIEW" },
        component: () => import("@/views/gpuHub/others/paymentBonus.vue"),
      },
      {
        path: "/gpuhub-promotion",
        name: "GPUHubPromotion",
        meta: { title: "Promotion GPU Hub", requiresAuth: true, icon: "fas fa-gift", decentralizationName: "COUPON_VIEW" },
        component: () => import("@/views/promotion/promotion.vue"),
      },
      {
        path: "/promotion-mngt",
        name: "GPUHubPromotionMngt",
        meta: { title: "Promotion Management GPU Hub", requiresAuth: true, icon: "fas fa-gift", decentralizationName: "COUPON_VIEW" },
        component: () => import("@/views/adminManager/promotion/Promotion.vue"),
      },
      {
        path: "/banner-mngt",
        name: "GPUHubBannerMngt",
        meta: { title: "Banner Management GPU Hub", requiresAuth: true, icon: "fas fa-gift", decentralizationName: "COUPON_VIEW" },
        component: () => import("@/views/adminManager/banner/Banner.vue"),
      },
      {
        path: "/admin-department",
        name: "Admin department manager",
        meta: { title: "Department manager", requiresAuth: true, icon: "fas fa-building", decentralizationName: null },
        component: () => import("@/views/administrator/department/Department.vue"),
      },

      // Users
      {
        path: "/users-history",
        name: "UsersHistory",
        meta: { title: "List iRender's users", requiresAuth: true, icon: "fas fa-users", decentralizationName: "USERHISTORY_VIEW" },
        component: () => import("@/views/users/UsersHistory.vue"),
      },
      {
        path: "/request-from-user",
        name: "Request from user",
        meta: { title: "List iRender's request from user", requiresAuth: true, icon: "fas fa-users", decentralizationName: "USERREQUEST__VIEW" },
        component: () => import("@/views/users/RequestFromUser.vue"),
      },

      // Admin manager
      {
        path: "/staff",
        name: "StaffList",
        meta: { title: "iRender's staff", requiresAuth: true, icon: "fas fa-users", decentralizationName: "SYSTEM_MANAGER_VIEW" },
        component: () => import("@/views/administrator/StaffList.vue"),
      },
      {
        path: "/staff-history",
        name: "StaffHistory",
        meta: { title: "iRender's staff activities", requiresAuth: true, icon: "fas fa-users", decentralizationName: "SYSTEM_MANAGER_VIEW" },
        component: () => import("@/views/adminManager/StaffHistory.vue"),
      },
      {
        path: "/system-manager",
        name: "SystemManager",
        meta: { title: "List iRender's users", requiresAuth: true, icon: "fas fa-users", decentralizationName: "SYSTEM_MANAGER_VIEW" },
        component: () => import("@/views/adminManager/SystemManager.vue"),
      },
      {
        path: `/email-notification`,
        name: "email-notification1",
        meta: { title: "email-notification", requiresAuth: true, icon: "fas fa-tasks", decentralizationName: "SYSTEM_MANAGER_VIEW" },
        component: () => import("@/views2/email-notification/index.vue"),
      },
      {
        path: `/push-notification`,
        name: "push-notification1",
        meta: { title: "push-notification", requiresAuth: true, icon: "fas fa-tasks", decentralizationName: "SYSTEM_MANAGER_VIEW" },
        component: () => import("@/views2/push-notification/index.vue"),
      },
      {
        path: "/system-settings",
        name: "SystemSettings",
        meta: { title: "List iRender's users", requiresAuth: true, icon: "fas fa-users", decentralizationName: "SYSTEM_MANAGER_VIEW" },
        component: () => import("@/views/adminManager/SystemSettings.vue"),
      },
      {
        path: "/stripe-history",
        name: "StripeHistory",
        meta: { title: "List iRender's users", requiresAuth: true, icon: "fas fa-users", decentralizationName: "SYSTEM_MANAGER_VIEW" },
        component: () => import("@/views/paymentBilling/StripeHistory.vue"),
      },
      {
        path: "/extra-service-request",
        name: "ExtraServiceRequest",
        meta: { title: "Extra service request", requiresAuth: true, icon: "fas fa-users", decentralizationName: "EXTRA_SERVICE_VIEW" },
        component: () => import("@/views/extraServiceRequest/request.vue"),
      },
      {
        path: `/${ROUTE_URL.RATING}`,
        name: "Rating",
        meta: { title: "Extra service request", requiresAuth: true, icon: "fas fa-users", decentralizationName: "USER_VIEW" },
        component: () => import("@/views/Rating/Rating.vue"),
      },
      {
        path: `/software-license`,
        name: "SoftwareLicense",
        meta: { title: "Software License", requiresAuth: true, icon: "fas fa-user-clock", decentralizationName: null },
        component: () => import("@/views/adminManager/systemManager/SoftwareLicenseManagement.vue"),
      },
      {
        path: `/technical-shift`,
        name: "TechnicalShift",
        meta: { title: "Technical shift", requiresAuth: true, icon: "fas fa-user-clock", decentralizationName: "USER_VIEW" },
        component: () => import("@/views2/system-tracking-calendar/index.vue"),
      },
      {
        path: `/parsec-user`,
        name: "ParsecUser",
        meta: { title: "Parsec User", requiresAuth: true, icon: "fas fa-user-clock", decentralizationName: "USER_VIEW" },
        component: () => import("@/views/parsec/Parsec.vue"),
      },
    ],
  },
  {
    path: `/${layout2Mode}`,
    component: CodeDocsLayout,
    meta: { title: "Dashboard", group: "apps", icon: "" },
    redirect: `${layout2Mode}/push-notification`,
    children: [
      {
        path: `/${layout2Mode}/push-notification`,
        name: "push-notification2",
        meta: { title: "push-notification", requiresAuth: true, icon: "fas fa-tasks" },
        component: () => import("@/views2/push-notification/index.vue"),
      },
      {
        path: `/${layout2Mode}/email-notification`,
        name: "email-notification",
        meta: { title: "email-notification", requiresAuth: true, icon: "fas fa-tasks" },
        component: () => import("@/views2/email-notification/index.vue"),
      },
    ],
  },
];
