import { Notification, Message } from 'element-ui';
import moment from 'moment';
import { mapState } from "vuex";

import commonActionHandle from "@/commonActionHandle";

// Constant  
import { API_CALL_STATUS, POPUP_ID, PAGINATION_STATUS } from "@/constant/baseConstant";

// Component
import CaretTop from "@/views/components/Icon/CaretTop.vue";
import CaretBottom from "@/views/components/Icon/CaretBottom.vue";

import ColumnUser from "@/views/components/column/User.vue";
import ColumnBalance from "@/views/components/column/Balance.vue";

export default {

    components: {
        CaretTop,
        CaretBottom,

        ColumnUser,
        ColumnBalance
    },

    data() {
        return {
            baseUrl: window.location.origin,
            popupId: POPUP_ID,

            countError: 0,
            smoothTimeOut: 1000,

            msgPage: 'unknown',
            isLoading: false,
            loadingStatus: {
                color: '#9E9E9E',
                date: moment().format("DD/MM/YYYY hh:mm:ss [GMT]Z"),
                text: 'unknow',
            },
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },

    methods: {

        //#region Router url
        pushUrl(routeUrl) {
            this.$router.push(routeUrl);
        },
        //#endregion

        //#region Notification element
        notificationSuccess(title, message) {
            Message.success({
                title: title,
                dangerouslyUseHTMLString: true,
                duration: 3000,
                offset: 60,
                showClose: false,
                message: message,
                type: 'success'
            });
        },
        notificationInfo(title, message) {
            Message.message({
                title: title,
                dangerouslyUseHTMLString: true,
                duration: 3000,
                offset: 60,
                showClose: false,
                message: message,
                type: 'warning'
            });
        },
        notificationWarning(title, message) {
            Message.warning({
                title: title,
                dangerouslyUseHTMLString: true,
                duration: 3000,
                offset: 60,
                showClose: false,
                message: message,
                type: 'warning'
            });
        },
        notificationError(title, message) {
            Message.error({
                title: title,
                dangerouslyUseHTMLString: true,
                duration: 3000,
                offset: 60,
                showClose: false,
                message: message,
                type: 'error'
            });
        },
        stripHtml(html) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        },
        // notificationSuccess(title, message) {
        //     Notification.success({
        //         title: title,
        //         dangerouslyUseHTMLString: true,
        //         duration: 3000,
        //         offset: 60,
        //         showClose: false,
        //         message: message,
        //         type: 'success'
        //     });
        // },
        // notificationInfo(title, message) {
        //     Notification.message({
        //         title: title,
        //         dangerouslyUseHTMLString: true,
        //         duration: 3000,
        //         offset: 60,
        //         showClose: false,
        //         message: message,
        //         type: 'warning'
        //     });
        // },
        // notificationWarning(title, message) {
        //     Notification.warning({
        //         title: title,
        //         dangerouslyUseHTMLString: true,
        //         duration: 3000,
        //         offset: 60,
        //         showClose: false,
        //         message: message,
        //         type: 'warning'
        //     });
        // },
        // notificationError(title, message) {
        //     Notification.error({
        //         title: title,
        //         dangerouslyUseHTMLString: true,
        //         duration: 3000,
        //         offset: 60,
        //         showClose: false,
        //         message: message,
        //         type: 'error'
        //     });
        // },
        //#endregion

        //#region Get information client
        getInformationClient() {
            let information = {
                sourceRequest: 'WEB',
                browserDetails: '',
                timeZone: '',
                resolutionDevice: '',
            };
            let navigatorList = {
                appCodeName: navigator.appCodeName,
                appName: navigator.appName,
                appVersion: navigator.appVersion,
                cookieEnabled: navigator.cookieEnabled,
                geolocation: navigator.geolocation,
                language: navigator.language,
                onLine: navigator.onLine,
                platform: navigator.platform,
                product: navigator.product,
                userAgent: navigator.userAgent
            };
            try {
                information.browserDetails = JSON.stringify(navigatorList);

                let offset = new Date().getTimezoneOffset(),
                    o = Math.abs(offset);
                let timeZoneBrowse = (offset < 0 ? "+" : "-") + Math.floor(o / 60);
                information.timeZone = timeZoneBrowse;

                information.resolutionDevice = `${window.screen.width}x${window.screen.height}`;
            } catch {
                // empty
            }
            return information;
        },
        //#endregion        

        //#region Display duration
        caclDurationDiffDate(startTime, endTime) {
            let totalDurations = moment.duration(moment(endTime).diff(moment(startTime))).asDays();
            return Number.parseFloat(Number.parseFloat(totalDurations).toFixed(2).toString()) + ' day';
        },
        //#endregion

        //#region Skip description
        skipDescription(value) {
            this.isShowInformation = value;
        },
        //#endregion

        //#region Open Pop-up
        openPopup(id) {
            $(`#${id}`).modal({ backdrop: "static" });
        },
        //#endregion

        //#region Close Pop-up
        closePopup(id) {
            $(`#${id}`).modal("hide");
        },
        //#endregion

        //#region Process Catch api request
        processCatchApiRequest(error, msg, tryCatchCount) {
            if (tryCatchCount) {
                this.countError += 1;
                if (error.name === 'Error') {
                    if (this.countError === tryCatchCount) {
                        this.showDialogExpired();
                    }
                }
            } else {
                if (error.name === 'Error') {
                    this.showDialogExpired();
                } else {
                    this.commonError(error, msg);
                }
                this.feedStatusLoading(API_CALL_STATUS.FAILED);
            }
        },
        //#endregion

        //#region Session has expired
        showDialogExpired() {
            if (this.$route.path !== '/login') {
                commonActionHandle.showCustomWarning(
                    'The current session has expired !',
                    'Please re-login to continue using the service.',
                    () => {
                        setTimeout(() => {
                            location.href = "/login"
                        }, 500);
                    },
                    () => {
                        this.countError = 0;
                    },
                    'Logout',
                    '',
                    false,
                );
            }
        },
        //#endregion

        //#region Common error
        commonError(error, msg) {
            console.log(error);
            this.$store.dispatch("common/showUnkownError", error, msg);
        },
        //#endregion

        //#region  Feed status
        feedStatusLoading(type, data) {
            switch (type) {
                case API_CALL_STATUS.LOADING:
                    this.loadingStatus = {
                        color: '#9E9E9E',
                        date: moment().format("DD/MM/YYYY hh:mm:ss [GMT]Z"),
                        text: 'Loading data ...',
                    }
                    this.msgPage = 'Loading ...';
                    this.isLoading = true;
                    this.isShowErrorInformation = false;
                    break;
                case API_CALL_STATUS.SUCCESS:
                    setTimeout(() => {
                        this.loadingStatus = {
                            color: 'green',
                            date: moment().format("DD/MM/YYYY hh:mm:ss [GMT]Z"),
                            text: 'The data has been updated successfully !',
                        }
                        if (data) {
                            if (data.length > 0) {
                                this.msgPage = 'Success';
                            } else {
                                this.msgPage = 'No data';
                            }
                        }
                        this.isLoading = false;
                    }, 500);
                    break;
                case API_CALL_STATUS.FAILED:
                    setTimeout(() => {
                        this.loadingStatus = {
                            color: 'red',
                            date: moment().format("DD/MM/YYYY hh:mm:ss [GMT]Z"),
                            text: 'Updated data failed !',
                        }
                        this.msgPage = 'Failed !';
                        this.isLoading = false;
                        setTimeout(() => {
                        }, 500);
                    }, 500);
                    break;
                default:
                    this.loadingStatus = {
                        color: '#9E9E9E',
                        date: moment().format("DD/MM/YYYY hh:mm:ss [GMT]Z"),
                        text: 'unknow',
                    }
                    this.msgPage = 'unknow';
                    this.isLoading = false;
                    break;
            }
        },
        //#endregion

        //#region Display duration 
        caclDuration(uptime) {
            let sec = uptime;
            let hrs = Math.floor(sec / 3600);
            let min = Math.floor((sec - (hrs * 3600)) / 60);
            let result = '';
            if (sec > 0) {
                if (sec < 1) {
                    sec = parseFloat(sec).toFixed(2);
                } else {
                    result += (hrs > 0 ? hrs + 'h' : '');
                    result += (min > 0 ? min + 'm' : '');
                }
            }
            else result = '-- : --';
            return result;
        },
        //#endregion

        //#region Map to component
        mapColumnUser(element) {
            let result = {};
            try {
                result = {
                    username: element.username,
                    registerAt: element.userCreatedAt,
                    region: element.region,
                }
            } catch {
                result = {};
            }
            return result;
        },

        mapColumnBalance(element) {
            let result = {};
            try {
                result = {
                    username: element.username,
                    totalRecharge: element.totalRecharge,
                    balance: element.balance,
                    totalImage: element.totalImage,
                    imageThreshold: element.imageThreshold,
                    totalStorage: element.totalStorage,
                    storageThreshold: element.storageThreshold,
                }
            } catch {
                result = {};
            }
            return result;
        },
        //#endregion
    },
}