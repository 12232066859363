import axios from "axios";
const queryString = require('query-string');

export default {
    getAllLicenseView(requestData) {
        let queryParams = queryString.stringify(requestData);
        return axios({
            method: "GET",
            url: `/api/software-license/get-all-license-view?${queryParams}`,
            withCredentials: true,
        });
    },
    getItemLicenseView(licenseId) {
        return axios({
            method: "GET",
            url: `/api/software-license/get-item-license-view/${licenseId}`,
            withCredentials: true,
        });
    },
    insertOrUpdateLicense(data) {
        return axios({
            method: "POST",
            url: `/api/software-license/insert-update-license`,
            data: data,
            withCredentials: true,
        });
    },
    clearUseLicense(licenseId) {
        return axios({
            method: "GET",
            url: `/api/software-license/clear-use-license/${licenseId}`,
            withCredentials: true,
        });
    },
    setActiveLicense(licenseId, machineId) {
        return axios({
            method: "GET",
            url: `/api/software-license/set-use-license/${licenseId}/${machineId}`,
            withCredentials: true,
        });
    },
    deleteLicense(licenseId) {
        return axios({
            method: "GET",
            url: `/api/software-license/delete-license/${licenseId}`,
            withCredentials: true,
        });
    },
    restoreLicense(licenseId) {
        return axios({
            method: "GET",
            url: `/api/software-license/restore-license/${licenseId}`,
            withCredentials: true,
        });
    },
    getLicenseStatistics() {
        return axios({
            method: "GET",
            url: `/api/software-license/get-license-statistics`,
            withCredentials: true,
        });
    }
}