const pointNameShort = process.env.VUE_APP_POINT_NAME_SHORT
export default [

    //Login cookie
    {
        status: 10000,
        source: 'Web',
        name: 'Login',
        description: 'Logged in successfully !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/login.png',
    },
    {
        status: 10001,
        source: 'Web',
        name: 'Login',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/login.png',
    },
    {
        status: 10002,
        source: 'Web',
        name: 'Login',
        description: 'An error has occurred at the server',
        details: 'UserManager.GetByUsername --- prc_Get_By_Username_User',
        color: 'red',
        icon: '/userLog/login.png',
    },
    {
        status: 10003,
        source: 'Web',
        name: 'Login',
        description: 'An error has occurred at the server',
        details: 'UserManager.GetByEmailAdress --- prc_Get_By_Email_User',
        color: 'red',
        icon: '/userLog/login.png',
    },
    {
        status: 10004,
        source: 'Web',
        name: 'Login',
        description: 'User does not exist',
        details: '',
        color: 'darkorange',
        icon: '/userLog/login.png',
    },
    {
        status: 10005,
        source: 'Web',
        name: 'Login',
        description: 'User has not verified email',
        details: '',
        color: 'darkorange',
        icon: '/userLog/login.png',
    },
    {
        status: 10006,
        source: 'Web',
        name: 'Login',
        description: 'Wrong password',
        details: '',
        color: 'darkorange',
        icon: '/userLog/login.png',
    },
    {
        status: 10007,
        source: 'Web',
        name: 'Login',
        description: 'User blocked',
        details: '',
        color: 'darkorange',
        icon: '/userLog/login.png',
    },
    {
        status: 10008,
        source: 'Web',
        name: 'Login',
        description: 'Could not find user status information',
        details: '',
        color: 'red',
        icon: '/userLog/login.png',
    },


    //Login token 
    {
        status: 10100,
        source: 'App Sync',
        name: 'Login',
        description: 'Logged in successfully !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/login.png',
    },
    {
        status: 10101,
        source: 'GpuHub Sync',
        name: 'Login',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/login.png',
    },
    {
        status: 10102,
        source: 'GpuHub Sync',
        name: 'Login',
        description: 'An error has occurred at the server',
        details: 'UserManager.GetByUsername --- prc_Get_By_Username_User',
        color: 'red',
        icon: '/userLog/login.png',
    },
    {
        status: 10103,
        source: 'GpuHub Sync',
        name: 'Login',
        description: 'An error has occurred at the server',
        details: 'UserManager.GetByEmailAdress --- prc_Get_By_Email_User',
        color: 'red',
        icon: '/userLog/login.png',
    },
    {
        status: 10104,
        source: 'GpuHub Sync',
        name: 'Login',
        description: 'User does not exist',
        details: '',
        color: 'darkorange',
        icon: '/userLog/login.png',
    },
    {
        status: 10105,
        source: 'GpuHub Sync',
        name: 'Login',
        description: 'User has not verified email',
        details: '',
        color: 'darkorange',
        icon: '/userLog/login.png',
    },
    {
        status: 10106,
        source: 'GpuHub Sync',
        name: 'Login',
        description: 'Wrong password',
        details: '',
        color: 'darkorange',
        icon: '/userLog/login.png',
    },
    {
        status: 10107,
        source: 'GpuHub Sync',
        name: 'Login',
        description: 'User blocked',
        details: '',
        color: 'darkorange',
        icon: '/userLog/login.png',
    },
    {
        status: 10108,
        source: 'GpuHub Sync',
        name: 'Login',
        description: 'Could not find user status information',
        details: '',
        color: 'red',
        icon: '/userLog/login.png',
    },


    //Register
    {
        status: 20000,
        source: 'Web',
        name: 'Register',
        description: 'Registration success !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/register.png',
    },
    {
        status: 20001,
        source: 'Web',
        name: 'Register',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/register.png',
    },
    {
        status: 20002,
        source: 'Web',
        name: 'Register',
        description: 'An error has occurred at the server',
        details: 'UserManager.GetByUsername --- prc_Get_By_Username_User',
        color: 'red',
        icon: '/userLog/register.png',
    },
    {
        status: 20003,
        source: 'Web',
        name: 'Register',
        description: 'An error has occurred at the server',
        details: 'UserManager.GetByEmailAdress --- prc_Get_By_Email_User',
        color: 'red',
        icon: '/userLog/register.png',
    },
    {
        status: 20004,
        source: 'Web',
        name: 'Register',
        description: 'Username is already taken',
        details: '',
        color: 'darkorange',
        icon: '/userLog/register.png',
    },
    {
        status: 20005,
        source: 'Web',
        name: 'Register',
        description: 'Email is already taken',
        details: '',
        color: 'darkorange',
        icon: '/userLog/register.png',
    },
    {
        status: 20006,
        source: 'Web',
        name: 'Register',
        description: 'An error has occurred at the server',
        details: 'UserManager.Insert --- prc_Insert_User',
        color: 'red',
        icon: '/userLog/register.png',
    },


    //Resent active email
    {
        status: 30000,
        source: 'Web',
        name: 'Resend activation email',
        description: 'Resent active email successfully !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/resent-active-email.png',
    },
    {
        status: 30001,
        source: 'Web',
        name: 'Resend activation email',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/resent-active-email.png',
    },
    {
        status: 30002,
        source: 'Web',
        name: 'Resend activation email',
        description: 'An error has occurred at the server',
        details: 'UserManager.GetByEmailAdress --- prc_Get_By_Email_User',
        color: 'red',
        icon: '/userLog/resent-active-email.png',
    },
    {
        status: 30003,
        source: 'Web',
        name: 'Resend activation email',
        description: 'Email address does not exist',
        details: '',
        color: 'darkorange',
        icon: '/userLog/resent-active-email.png',
    },
    {
        status: 30004,
        source: 'Web',
        name: 'Resend activation email',
        description: 'Account has been activated before',
        details: '',
        color: 'darkorange',
        icon: '/userLog/resent-active-email.png',
    },
    {
        status: 30005,
        source: 'Web',
        name: 'Resend activation email',
        description: 'Cannot send activation email',
        details: '',
        color: 'red',
        icon: '/userLog/resent-active-email.png',
    },


    //Forgot password
    {
        status: 40000,
        source: 'Web',
        name: 'Forgot password',
        description: 'Password reset successfully sent !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/forgot-password.png',
    },
    {
        status: 40001,
        source: 'Web',
        name: 'Forgot password',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/forgot-password.png',
    },
    {
        status: 40002,
        source: 'Web',
        name: 'Forgot password',
        description: 'An error has occurred at the server',
        details: 'UserManager.GetByEmailAdress --- prc_Get_By_Email_User',
        color: 'red',
        icon: '/userLog/forgot-password.png',
    },
    {
        status: 40003,
        source: 'Web',
        name: 'Forgot password',
        description: 'Email address does not exist',
        details: '',
        color: 'darkorange',
        icon: '/userLog/forgot-password.png',
    },
    {
        status: 40004,
        source: 'Web',
        name: 'Forgot password',
        description: 'An error has occurred at the server',
        details: 'PasswordResetTokenManager.GetByUserId --- prc_Get_ByUserID_PasswordResetToken',
        color: 'red',
        icon: '/userLog/forgot-password.png',
    },
    {
        status: 40005,
        source: 'Web',
        name: 'Forgot password',
        description: 'An error has occurred at the server',
        details: 'PasswordResetTokenManager.Insert --- prc_Insert_PasswordResetToken',
        color: 'red',
        icon: '/userLog/forgot-password.png',
    },
    {
        status: 40006,
        source: 'Web',
        name: 'Forgot password',
        description: 'An error has occurred at the server',
        details: 'PasswordResetTokenManager.Update --- prc_Update_PasswordResetToken',
        color: 'red',
        icon: '/userLog/forgot-password.png',
    },


    //Change password
    {
        status: 50000,
        source: 'Web',
        name: 'Change password',
        description: 'Password changed successfully !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/CHANGE_PASSWORD.png',
    },
    {
        status: 50001,
        source: 'Web',
        name: 'Change password',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/CHANGE_PASSWORD.png',
    },
    {
        status: 50002,
        source: 'Web',
        name: 'Forgot password',
        description: 'Account does not exist',
        details: '',
        color: 'red',
        icon: '/userLog/CHANGE_PASSWORD.png',
    },
    {
        status: 50003,
        source: 'Web',
        name: 'Forgot password',
        description: 'Current password is incorrect',
        details: '',
        color: 'darkorange',
        icon: '/userLog/CHANGE_PASSWORD.png',
    },


    //Update profile
    {
        status: 60000,
        source: 'Web',
        name: 'Update profile',
        description: 'Successful profile update !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/UPDATE_PROFILE.png',
    },
    {
        status: 60001,
        source: 'Web',
        name: 'Update profile',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/UPDATE_PROFILE.png',
    },
    {
        status: 60002,
        source: 'Web',
        name: 'Update profile',
        description: 'An error has occurred at the server',
        details: 'UserManager.UpdateProfileUser --- prc_Update_UserInformation',
        color: 'red',
        icon: '/userLog/UPDATE_PROFILE.png',
    },


    //Logout
    {
        status: 70000,
        source: 'Web',
        name: 'Logout',
        description: 'Successfully logged out !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/logout.png',
    },
    {
        status: 70001,
        source: 'Web',
        name: 'Logout',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/logout.png',
    },
    {
        status: 70002,
        source: 'Web',
        name: 'Logout',
        description: 'Account does not exist',
        details: '',
        color: 'red',
        icon: '/userLog/logout.png',
    },


    //Create image
    {
        status: 80000,
        source: 'Web',
        name: 'Create image',
        description: 'Successfully created image !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80001,
        source: 'Web',
        name: 'Create image',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80002,
        source: 'Web',
        name: 'Create image',
        description: 'Account does not exist',
        details: 'UserManager.GetByIdEx --- prc_Get_ByID_User',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80003,
        source: 'Web',
        name: 'Create image',
        description: `Account balance is less than 0 ${pointNameShort}`,
        details: '',
        color: 'darkorange',
        icon: '/userLog/create-image.png',
        isShowIdMachine: true
    },
    {
        status: 80004,
        source: 'Web',
        name: 'Create image',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.GetVMMachimeByUserId --- sp_get_vm_machine_by_userId',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80005,
        source: 'Web',
        name: 'Create image',
        description: 'An error has occurred at the server',
        details: 'RentalServicePackManager.GetAll --- prc_Get_All_RentalServicePack',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80006,
        source: 'Web',
        name: 'Create image',
        description: 'An error has occurred at the server',
        details: 'RentalServicePackManager.GetAll --- prc_Get_All_RentalServicePack --- No Data',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80007,
        source: 'Web',
        name: 'Create image',
        description: 'An error has occurred at the server',
        details: 'Service package not match with DB',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80008,
        source: 'Web',
        name: 'Create image',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.GetUserConfiguration --- sp_get_user_configuration',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80009,
        source: 'Web',
        name: 'Create image',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.GetUserConfiguration --- sp_get_user_configuration --- No Data',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80010,
        source: 'Web',
        name: 'Create image',
        description: 'An error has occurred at the server',
        details: 'User level not match with DB',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80011,
        source: 'Web',
        name: 'Create image',
        description: 'Limited number of images according to customer level',
        details: '',
        color: 'darkorange',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80012,
        source: 'Web',
        name: 'Create image',
        description: 'Limited minimum balance to create service pack',
        details: '',
        color: 'darkorange',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80013,
        source: 'Web',
        name: 'Create image',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.Insert --- sp_insert_vm_machine',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80014,
        source: 'Web',
        name: 'Create image',
        description: 'An error has occurred at the server',
        details: 'UserVmMachineManager.Insert --- sp_insert_user_vm_machine',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80015,
        source: 'Web',
        name: 'Create image',
        description: 'An error has occurred at the server',
        details: 'UserVmMachineManager.GetImageThreshold --- sp_get_image_threshold',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 80016,
        source: 'Web',
        name: 'Create image',
        description: 'An error has occurred at the server',
        details: 'UserVmMachineManager.GetImageThreshold --- sp_get_image_threshold --- No Data',
        color: 'red',
        icon: '/userLog/CREATE_IMAGE.png',
        isShowIdMachine: true
    },


    //Boot machine
    {
        status: 90000,
        source: 'Web',
        name: 'Boot machine',
        description: 'Request to boot successfully !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/BOOT_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 90001,
        source: 'Web',
        name: 'Boot machine',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/BOOT_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 90002,
        source: 'Web',
        name: 'Boot machine',
        description: 'Account does not exist',
        details: 'UserManager.GetByIdEx --- prc_Get_ByID_User',
        color: 'red',
        icon: '/userLog/BOOT_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 90003,
        source: 'Web',
        name: 'Boot machine',
        description: 'An error has occurred at the server',
        details: 'Image not match with user',
        color: 'red',
        icon: '/userLog/BOOT_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 90004,
        source: 'Web',
        name: 'Boot machine',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.CheckServicePackAvailability --- sp_check_machine_canboot',
        color: 'red',
        icon: '/userLog/BOOT_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 90004,
        source: 'Web',
        name: 'Boot machine',
        description: 'Package is full',
        details: '',
        color: 'darkorange',
        icon: '/userLog/BOOT_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 90005,
        source: 'Web',
        name: 'Boot machine',
        description: 'Package is full',
        details: '',
        color: 'darkorange',
        icon: '/userLog/BOOT_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 90006,
        source: 'Web',
        name: 'Boot machine',
        description: `Account balance is less than 0 ${pointNameShort}`,
        details: '',
        color: 'darkorange',
        icon: '/userLog/BOOT_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 90007,
        source: 'Web',
        name: 'Boot machine',
        description: 'Total deposit is less than 50 $',
        details: '',
        color: 'darkorange',
        icon: '/userLog/BOOT_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 90008,
        source: 'Web',
        name: 'Boot machine',
        description: 'Limit minimum balance to boot service pack',
        details: '',
        color: 'darkorange',
        icon: '/userLog/BOOT_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 90009,
        source: 'Web',
        name: 'Boot machine',
        description: 'Limit minimum balance to boot service pack',
        details: 'VmMachineManager.Update --- sp_update_vm_machine',
        color: 'red',
        icon: '/userLog/BOOT_MACHINE.png',
        isShowIdMachine: true
    },


    //Shutdown machine
    {
        status: 100000,
        source: 'Web',
        name: 'Shutdown image',
        description: 'Request to successfully shutdown !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/shutdown-machine.png',
        isShowIdMachine: true
    },
    {
        status: 100001,
        source: 'Web',
        name: 'Shutdown image',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/shutdown-machine.png',
        isShowIdMachine: true
    },
    {
        status: 100002,
        source: 'Web',
        name: 'Shutdown image',
        description: 'Account does not exist',
        details: 'UserManager.GetByIdEx --- prc_Get_ByID_User',
        color: 'red',
        icon: '/userLog/shutdown-machine.png',
        isShowIdMachine: true
    },
    {
        status: 100003,
        source: 'Web',
        name: 'Shutdown image',
        description: 'An error has occurred at the server',
        details: 'Image not match with user',
        color: 'red',
        icon: '/userLog/shutdown-machine.png',
        isShowIdMachine: true
    },
    {
        status: 100004,
        source: 'Web',
        name: 'Shutdown image',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.Update --- sp_update_vm_machine',
        color: 'red',
        icon: '/userLog/shutdown-machine.png',
        isShowIdMachine: true
    },


    //Change service package
    {
        status: 110000,
        source: 'Web',
        name: 'Change service package',
        description: 'Package changed successfully !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/CHANGE_PACKAGE_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 110001,
        source: 'Web',
        name: 'Change service package',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/CHANGE_PACKAGE_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 110002,
        source: 'Web',
        name: 'Change service package',
        description: 'Account does not exist',
        details: 'UserManager.GetByIdEx --- prc_Get_ByID_User',
        color: 'red',
        icon: '/userLog/CHANGE_PACKAGE_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 110003,
        source: 'Web',
        name: 'Change service package',
        description: 'An error has occurred at the server',
        details: 'Image not match with user',
        color: 'red',
        icon: '/userLog/CHANGE_PACKAGE_MACHINE.png',
        isShowIdMachine: true
    },
    {
        status: 110004,
        source: 'Web',
        name: 'Change service package',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.ChangeServicePackage --- sp_change_service_package',
        color: 'red',
        icon: '/userLog/CHANGE_PACKAGE_MACHINE.png',
        isShowIdMachine: true
    },


    //Clone image
    {
        status: 120000,
        source: 'Web',
        name: 'Clone image',
        description: 'Request for successful cloning !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120001,
        source: 'Web',
        name: 'Clone image',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120002,
        source: 'Web',
        name: 'Clone image',
        description: 'Account does not exist',
        details: 'UserManager.GetByIdEx --- prc_Get_ByID_User',
        color: 'red',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120003,
        source: 'Web',
        name: 'Clone image',
        description: 'An error has occurred at the server',
        details: 'Image not match with user',
        color: 'red',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120004,
        source: 'Web',
        name: 'Clone image',
        description: `Account balance is less than 0 ${pointNameShort}`,
        details: '',
        color: 'darkorange',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120005,
        source: 'Web',
        name: 'Clone image',
        description: 'Customers have never recharged',
        details: '',
        color: 'darkorange',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120006,
        source: 'Web',
        name: 'Clone image',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.GetVMMachimeByUserId --- sp_get_vm_machine_by_userId',
        color: 'red',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120007,
        source: 'Web',
        name: 'Clone image',
        description: 'An error has occurred at the server',
        details: 'RentalServicePackManager.GetAll --- prc_Get_All_RentalServicePack',
        color: 'red',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120008,
        source: 'Web',
        name: 'Clone image',
        description: 'An error has occurred at the server',
        details: 'RentalServicePackManager.GetAll --- prc_Get_All_RentalServicePack --- No Data',
        color: 'red',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120009,
        source: 'Web',
        name: 'Clone image',
        description: 'An error has occurred at the server',
        details: 'Service package not match with DB',
        color: 'red',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120010,
        source: 'Web',
        name: 'Clone image',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.GetUserConfiguration --- sp_get_user_configuration',
        color: 'red',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120011,
        source: 'Web',
        name: 'Clone image',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.GetUserConfiguration --- sp_get_user_configuration --- No Data',
        color: 'red',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120012,
        source: 'Web',
        name: 'Clone image',
        description: 'An error has occurred at the server',
        details: 'User level not match with DB',
        color: 'red',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120013,
        source: 'Web',
        name: 'Clone image',
        description: 'Limited number of images according to customer level',
        details: '',
        color: 'darkorange',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120014,
        source: 'Web',
        name: 'Clone image',
        description: 'Limited minimum balance to create service pack',
        details: '',
        color: 'darkorange',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 120015,
        source: 'Web',
        name: 'Clone image',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.CloneImage --- sp_insert_clone_vm_machine',
        color: 'red',
        icon: '/userLog/CLONE_IMAGE.png',
        isShowIdMachine: true
    },


    //Download machine
    //public const int DownloadMachine_Success = 130000;
    //public const int DownloadMachine_Exception = 130001;
    //public const int DownloadMachine_UserNotExist = 130002;
    //public const int DownloadMachine_UserAndMachineNotMatch = 130003;
    //public const int DownloadMachine_FileRDPNotExist = 130004;


    //Rename image
    {
        status: 140000,
        source: 'Web',
        name: 'Rename image',
        description: 'Image renamed successfully!',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/RENAME_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 140001,
        source: 'Web',
        name: 'Rename image',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/RENAME_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 140002,
        source: 'Web',
        name: 'Rename image',
        description: 'Account does not exist',
        details: 'UserManager.GetByIdEx --- prc_Get_ByID_User',
        color: 'red',
        icon: '/userLog/RENAME_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 140003,
        source: 'Web',
        name: 'Rename image',
        description: 'An error has occurred at the server',
        details: 'Image not match with user',
        color: 'red',
        icon: '/userLog/RENAME_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 140004,
        source: 'Web',
        name: 'Rename image',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.RenameMachine --- sp_rename_vm_machine',
        color: 'red',
        icon: '/userLog/RENAME_IMAGE.png',
        isShowIdMachine: true
    },


    //Remove image
    {
        status: 150000,
        source: 'Web',
        name: 'Remove image',
        description: 'Successfully removed the machine image !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/REMOVE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 150001,
        source: 'Web',
        name: 'Remove image',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/REMOVE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 150002,
        source: 'Web',
        name: 'Remove image',
        description: 'Account does not exist',
        details: 'UserManager.GetByIdEx --- prc_Get_ByID_User',
        color: 'red',
        icon: '/userLog/REMOVE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 150003,
        source: 'Web',
        name: 'Remove image',
        description: 'An error has occurred at the server',
        details: 'Image not match with user',
        color: 'red',
        icon: '/userLog/REMOVE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 150004,
        source: 'Web',
        name: 'Remove image',
        description: 'Machine is running',
        details: '',
        color: 'red',
        icon: '/userLog/REMOVE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 150005,
        source: 'Web',
        name: 'Remove image',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.Update --- sp_update_vm_machine',
        color: 'red',
        icon: '/userLog/REMOVE_IMAGE.png',
        isShowIdMachine: true
    },
    {
        status: 150006,
        source: 'Web',
        name: 'Remove image',
        description: 'Machine has fixed rental',
        details: '',
        color: 'darkorange',
        icon: '/userLog/REMOVE_IMAGE.png',
        isShowIdMachine: true
    },

    //Request notification machine
    {
        status: 160000,
        source: 'Web',
        name: 'Request free package notification',
        description: 'Submit request successfully !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/request-notification-machine.png',
        isShowPackage: true
    },
    {
        status: 160001,
        source: 'Web',
        name: 'Request free package notification',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/request-notification-machine.png',
        isShowPackage: true
    },
    {
        status: 160002,
        source: 'Web',
        name: 'Request free package notification',
        description: 'Account does not exist',
        details: 'UserManager.GetByIdEx --- prc_Get_ByID_User',
        color: 'red',
        icon: '/userLog/request-notification-machine.png',
        isShowPackage: true
    },
    {
        status: 160003,
        source: 'Web',
        name: 'Request free package notification',
        description: 'An error has occurred at the server',
        details: 'VmMachineManager.RequestNotificationMachine --- sp_insert_request_notification_machine',
        color: 'red',
        icon: '/userLog/request-notification-machine.png',
        isShowPackage: true
    },

    // Processing recharge Paypal
    {
        status: 170000,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Created payment url success !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170001,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170002,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Account does not exist',
        details: 'UserManager.GetByIdEx --- prc_Get_ByID_User',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170003,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'GpuPaymentInvoiceManager.CheckAvailableMethod --- RentalPaymentInvoice_CheckAvailableMethod',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170004,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'GpuPaymentInvoiceManager.CheckAvailableMethod --- RentalPaymentInvoice_CheckAvailableMethod --- No Data',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170005,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Payment method not available',
        details: '',
        color: 'orange',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170006,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Deposit amount is lower than the minimum',
        details: '',
        color: 'darkorange',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170007,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Deposit amount is higher than the maximum',
        details: '',
        color: 'darkorange',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170008,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'GpuPaymentInvoiceManager.Insert --- prc_Insert_PaymentInvoice',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },

    // Processing recharge Stripe
    {
        status: 170100,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Created payment url success !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170101,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170102,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Account does not exist',
        details: 'UserManager.GetByIdEx --- prc_Get_ByID_User',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170103,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'GpuPaymentInvoiceManager.CheckAvailableMethod --- RentalPaymentInvoice_CheckAvailableMethod',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170104,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'GpuPaymentInvoiceManager.CheckAvailableMethod --- RentalPaymentInvoice_CheckAvailableMethod --- No Data',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170105,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Payment method not available',
        details: '',
        color: 'orange',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170106,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Deposit amount is lower than the minimum',
        details: '',
        color: 'darkorange',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170107,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Deposit amount is higher than the maximum',
        details: '',
        color: 'darkorange',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170108,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'GpuPaymentInvoiceManager.Insert --- prc_Insert_PaymentInvoice',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },

    // Processing recharge Ngan Luong
    {
        status: 170200,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Created payment url success !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170201,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170202,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Account does not exist',
        details: 'UserManager.GetByIdEx --- prc_Get_ByID_User',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170203,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'GpuPaymentInvoiceManager.CheckAvailableMethod --- RentalPaymentInvoice_CheckAvailableMethod',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170204,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'GpuPaymentInvoiceManager.CheckAvailableMethod --- RentalPaymentInvoice_CheckAvailableMethod --- No Data',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170205,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Payment method not available',
        details: '',
        color: 'orange',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170206,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Deposit amount is lower than the minimum',
        details: '',
        color: 'darkorange',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170207,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Deposit amount is higher than the maximum',
        details: '',
        color: 'darkorange',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170208,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'GpuPaymentInvoiceManager.Insert --- prc_Insert_PaymentInvoice',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },

    // Processing recharge MoMo
    {
        status: 170300,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Created payment url success !',
        details: '',
        color: 'forestgreen',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170301,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'Exception !',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170302,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Account does not exist',
        details: 'UserManager.GetByIdEx --- prc_Get_ByID_User',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170303,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'GpuPaymentInvoiceManager.CheckAvailableMethod --- RentalPaymentInvoice_CheckAvailableMethod',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170304,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'GpuPaymentInvoiceManager.CheckAvailableMethod --- RentalPaymentInvoice_CheckAvailableMethod --- No Data',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170305,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Payment method not available',
        details: '',
        color: 'orange',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170306,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Deposit amount is lower than the minimum',
        details: '',
        color: 'darkorange',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170307,
        source: 'Web',
        name: 'Processing recharge',
        description: 'Deposit amount is higher than the maximum',
        details: '',
        color: 'darkorange',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170308,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'GpuPaymentInvoiceManager.Insert --- prc_Insert_PaymentInvoice',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
    {
        status: 170309,
        source: 'Web',
        name: 'Processing recharge',
        description: 'An error has occurred at the server',
        details: 'MoMo request failed !',
        color: 'red',
        icon: '/userLog/processing-recharge.png',
    },
]