import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
const queryString = require('query-string');

export default {

    extends: baseComponent,

    props: {
        element: {
            type: Object,
            default: null
        },
    },

    data() {
        return {

        };
    },

    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
        }),
    },

    created() {

    },

    mounted() {

    },

    methods: {

    }
}