import { usersCnf } from '@/constant/config.js';
import axios from "axios";
export default {
    getAllClassify() {
        return axios({
            method: "GET",
            url: usersCnf.getAllClassify,
            withCredentials: true,
        });
    },
    getAllClassifyByStaff() {
        return axios({
            method: "GET",
            url: usersCnf.getAllClassifyByStaff,
            withCredentials: true,
        });
    },
    createClassify(item) {
        return axios({
            method: "POST",
            url: usersCnf.createClassify,
            data: item,
            withCredentials: true
        });
    },
    modifyClassify(item) {
        return axios({
            method: "POST",
            url: usersCnf.modifyClassify,
            data: item,
            withCredentials: true
        });
    },
    deleteClassify(tagId) {
        return axios({
            method: "GET",
            url: `${usersCnf.deleteClassify}/${tagId}`,
            withCredentials: true,
        });
    },
    setClassify(userId, classifyId) {
        return axios({
            method: "GET",
            url: `${usersCnf.setClassify}/${userId}/${classifyId}`,
            withCredentials: true,
        });
    },
    clearClassify(userId, classifyId) {
        return axios({
            method: "GET",
            url: `${usersCnf.clearClassify}/${userId}/${classifyId}`,
            withCredentials: true,
        });
    },
    enabledPackage(requestData) {
        return axios({
            method: "POST",
            url: `${usersCnf.enabledPackage}`,
            data: requestData,
            withCredentials: true,
        });
    },
    packageInfo(classifyId) {
        return axios({
            method: "GET",
            url: `${usersCnf.packageInfo}/${classifyId}`,
            withCredentials: true,
        });
    }
};
