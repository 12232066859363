
/*eslint-disable */
export const regex = {
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    onlyDigit: /^[0-9]+$/,
    noSpecialChar: /^[a-zA-Z0-9]+$/,
    phone: /^[+0-9]+$/,
    noSpace: /^\S*$/u,
};

export const socketIO = {
    url: process.env.VUE_APP_SOCKET_IO_URL,
    previewSocketUrl: process.env.VUE_APP_SOCKET_IO_STREAM_URL,
    IRENDER_JOB_PREVIEW_TOPIC: "irender.job.single_preview",
    IRENDER_CMD_ADDUSER: "add user",

    urlServerState: process.env.VUE_APP_SOCKET_EVENT_URL,
    urlServerSocketIO4: process.env.VUE_APP_SOCKET4_URL, 
    eventServerState: "irender.machine.state.report.admin",
    eventServerHardwareState: "irender.machine.state.report.hardwareState",
    eventServerUbuntuHardwareState: "irender.machine.state.report.hardwareState_ubuntu",

    machineReadyIssuesEvent: 'irender.machine.readyIssues.admin',

};

export const EXECUTE_RESULT = {
    SUCCESS: 0,
    ERROR: 1,
};

export const API_CALL_STATUS = {
    LOADING: 0,
    SUCCESS: 1,
    FAILED: 2,
};

export const syncStatus = {
    "0": { text : "Not sync", cssClass : "text-muted" },
    "1": { text : "Syncing", cssClass : "text-warning" },
    "2" : { text : "Synced", cssClass : "text-success" }
}

export const providerSelection = [
    { id: 'TESTING', text: 'TESTING', isTargetMainBalance: false },
    { id: 'BANKING', text: 'BANKING', isTargetMainBalance: true },
    { id: 'CARD', text: 'CARD', isTargetMainBalance: true },
    { id: 'PAYPAL', text: 'PAYPAL', isTargetMainBalance: true },
    { id: "MOMO", text: "MOMO", isTargetMainBalance: true },
    { id: 'ONEPAY', text: 'ONEPAY', isTargetMainBalance: true },
    { id: "NGANLUONG", text: "NGANLUONG", isTargetMainBalance: true },
    { id: "PAYONEER", text: "PAYONEER", isTargetMainBalance: true },
    { id: 'REFUND', text: 'REFUND', isTargetMainBalance: true },
    { id: 'COUPON', text: 'COUPON', isTargetMainBalance: false },
    { id: 'OTHERS', text: 'OTHERS', isTargetMainBalance: false },
    { id: "BONUS", text: "BONUS", isTargetMainBalance: false },
    { id: "REWARD_ACTIVATED_EMAIL", text: "REWARD_ACTIVATED_EMAIL", isTargetMainBalance: false },
    { id: 'REVENUE_TRANSACTION', text: 'REVENUE_TRANSACTION', isTargetMainBalance: true },
];

export const authServiceConfig = {
    getEnableTwoFactorAuthInfo: `/api/auth/get-enable-tfa-info`,
    postEnableTwoFactorAuth: `/api/auth/post-enable-tfa`,
    precheckTwoFactorEnabled: `/api/auth/get-precheck-enable-tfa-info`,
    LoginUrl: `/api/auth/login`,
    getStartupPopupEvent: "/api/auth/get-start-popup-event",
    isAuthenticatedUrl: "/api/auth/isAuthenticated",
    addFcmToken: "/api/auth/add-fcm-token",
    logoutUrl: "/api/auth/logout",
    changePasswordUrl: "/api/auth/change-password",
    getAllUser: "/api/auth/get-all-user",
    getAllRoles: "/api/auth/get-role",
    getAllUsersNotInDept: "/api/auth/get-all-user-not-in-dept/{0}",
    getUserOfDepartment: "/api/auth/get-all-user-in-dept/{0}",
    getRankingUserOfDepartment: "/api/auth/get-ranking-user-in-dept",
    getUserInRole: `${process.env.VUE_APP_API_HOST}api/auth/get-user-in-role/{0}`,
    getDecentralizationUrl: "api/auth/decentralization",
    basicInformationUrl: "api/auth/basic-information",
    decentralizationInfoUrl: "api/auth/decentralization-information",
    getStaffHistoryUrl: "/api/auth/get-staff-history",
    systemRetrictedIpAddressAddUrl: "/api/auth/system-restricted-ip-address-add",
    systemRetrictedIpAddressViewUrl: "/api/auth/system-retricted-ip-address-view",
    policyVerifiedEmailViewUrl: "/api/auth/policy-verified-email-view",
    policyVerifiedEmailModifyUrl: "/api/auth/policy-verified-email-modify",
    policyDepositLimitViewUrl: "/api/auth/policy-deposit-limit-view",
    policyDepositLimitModifyUrl: "/api/auth/policy-deposit-limit-modify",
    systemManagerActInDoubtViewUrl: "/api/auth/act-in-doubt-view",
    systemManagerActInDoubtModifyUrl: "/api/auth/act-in-doubt-modify",
    reportUserUrl: "/api/auth/report-user",
    reportIpAddressUrl: "/api/auth/report-ip-address",
    getUserHistoryCodeUrl: "/api/auth/get-user-history-code",

    staffManagementViewUrl: "/api/system-manager/staff-management-view",
    staffManagementCreateUrl: "/api/system-manager/staff-management-create",
    staffManagementModifyUrl: "/api/system-manager/staff-management-modify",
    staffManagementDeleteUrl: "/api/system-manager/staff-management-delete",

    roleManagementViewUrl: "/api/system-manager/role-management-view",
    roleManagementCreateUrl: "/api/system-manager/role-management-create",
    roleManagementModifyUrl: "/api/system-manager/role-management-modify",
    roleManagementDeleteUrl: "/api/system-manager/role-management-delete",

    decentralizedViewUrl: "/api/system-manager/decentralized-view",

    restrictedEmailDomainViewUrl: "/api/system-manager/restricted-email-domain-view",
    restrictedEmailDomainCreateUrl: "/api/system-manager/restricted-email-domain-create",
    restrictedEmailDomainModifyUrl: "/api/system-manager/restricted-email-domain-modify",
    restrictedEmailDomainDeleteUrl: "/api/system-manager/restricted-email-domain-delete",

    restrictedSoftwareViewUrl: "/api/system-manager/restricted-software-view",
    restrictedSoftwareCreateUrl: "/api/system-manager/restricted-software-create",
    restrictedSoftwareModifyUrl: "/api/system-manager/restricted-software-modify",
    restrictedSoftwareDeleteUrl: "/api/system-manager/restricted-software-delete",

    restrictedIpViewUrl: "/api/system-manager/restricted-ip-view",
    restrictedIpCreateUrl: "/api/system-manager/restricted-ip-create",
    restrictedIpModifyUrl: "/api/system-manager/restricted-ip-modify",
    restrictedIpDeleteUrl: "/api/system-manager/restricted-ip-delete",

    excludedIpViewUrl: "/api/system-manager/excluded-ip-view",
    excludedIpCreateUrl: "/api/system-manager/excluded-ip-create",
    excludedIpModifyUrl: "/api/system-manager/excluded-ip-modify",
    excludedIpDeleteUrl: "/api/system-manager/excluded-ip-delete",

    adminFunctionsViewUrl: "/api/system-manager/admin-functions-view",
    adminFunctionsCreateUrl: "/api/system-manager/admin-functions-create",
    adminFunctionsModifyUrl: "/api/system-manager/admin-functions-modify",
    adminFunctionsDeleteUrl: "/api/system-manager/admin-functions-delete",
};

export const usersCnf = {
    getListUser: "/api/users/get-users",
    exportListUser: "/api/users/export-users-data",
    getUserDetail: "/api/users/get-user-detail/{0}",
    getUserDetailByUsername: "/api/users/get-user-detail-by-username/{0}",
    getUserReferal: "/api/users/get-user-referal/{0}",
    getUserHistory: "/api/user-history/get-user-history/{0}",
    getListUserIdle: "/api/users/get-users-idle/{0}",
    exportListUserIdle: "/api/users/export-users-idle/{0}",
    getListUserSameIp: "/api/users/get-users-same-ip/{0}",
    getUsersBlacklist: "/api/users/get-users-blacklist",
    getPrimeUserList: "/api/users/get-users-prime-lessinfo",
    getPrimeUserReportData: "/api/users/get-users-report-data",
    getPrimeUserReportDataFull: "/api/users/get-users-report-data-full",
    exportUsersBlacklist: "/api/export-users-blacklist",
    getUsersPaid: "/api/users/get-users-paid",
    exportUsersPaid: "/api/users/export-users-paid",
    getUsersByStaff: "/api/users/get-users-by-staff/{0}/0",
    getPaidUsersByStaff: "/api/users/get-users-by-staff/{0}/1",
    getValMinimumRechargeOfPrimeUser: `/api/users/get-minimum-recharge-prime-user`,
    getAllTop100MostUsed: "/api/users/get-users-top-most-used",
    getUsersReferalList: "/api/users/get-users-referal-list/{0}",
    removeUser: "/api/users/remove-user/{0}",
    saveDetailUserNote: `/api/comments/save-note`,
    getNoteById: `/api/comments/get-note-by-id/{0}`,
    getNoteByUser: `/api/comments/get-note/{0}/{1}`,
    deleteNoteById: `/api/comments/delete-note-by-id/{0}`,
    getLowBalanceUser: `/api/users/get-low-balance-user`,
    warningLowBalanceUser: `/api/users/warning-user-low-balance/{0}`,
    rechargeBalance: `/api/users/recharge-balance-user`,
    balanceDeduction: `/api/users/balance-deduction-user`,
    getTotalRechargeInfo: `/api/users/get-total-recharge-info/{0}`,
    getUserSource: `/api/users/get-user-source`,
    sendWarningUserIdle: `/api/users/send-warning-user-idle`,
    updateSourceUser: `/api/users/update-source-user/{0}/{1}`,
    setRegisterSource: `/api/users/set-register-source/{0}/{1}`,
    updateUserTakeCare: `/api/users/update-user-take-care`,
    updateUserTakeCareMultiple: `/api/users/update-user-take-care-multiple`,
    acceptTakeCare: `/api/users/accept-user`,
    deactiveUser: `/api/users/deactive`,
    addtoSystemUser: `/api/users/add-system-user`,
    verifyEmailManual: `/api/users/verify-email-user`,
    sendEmailManual: `/api/users/send-verify-email-user`,
    sendEmailReactiveManual: `/api/users/send-reactive-email-user`,
    getIpLocation: 'https://ip-geo.myrender.vn/json/{0}',
    resyncUserData: `/api/users/re-sync-data`,
    changeSettingsUserUrl: "/api/users/change-settings-user",
    getUsersHistoryUrl: "/api/user-history/get-users-history",
    getCountRealTransaction: "/api/users/get-count-real-transaction/{0}",
    getAdditionalInfo1: "/api/users/get-additional-info/{0}",
    getAdditionalInfoCountSameIp: "/api/users/get-additional-info-count-same-ip/{0}",
    getLicenseInfo: "/api/users/get-license-info/{0}",
    getLicenseConnected: "/api/users/get-license-client-connected/{0}",
    setLicenseInfo: "/api/users/set-license-info",
    disconnectedLicenseClient: "/api/users/disconnect-license-client",
    checkLicenseClient: "/api/users/check-license-client",
    getAllUserConfiguaration: "/api/rental/get-user-configuration",
    setUserConfiguaration: "/api/users/set-user-configuration",
    setSeedingInfo: "/api/users/set-seeding-info",
    getIpAnalysisUrl: "/api/user-history/get-ip-analysis",

    getUserSameIpViewUrl: "/api/users/user-same-ip-view",
    getUserActivityMatchViewUrl: "/api/users/user-activity-match-view",
    basicUserInformationViewByStripeCustomerIdUrl: "/api/users/basic-user-information-view-by-stripe-customer-id",
    changeUserPrioriry: "/api/users/change-priority",

    getAllClassify: "/api/user-tag-classify/get-all-classify",
    getAllClassifyByStaff: "/api/user-tag-classify/get-all-classify-by-staff",
    createClassify: "/api/user-tag-classify/create-classify",
    modifyClassify: "/api/user-tag-classify/modify-classify",
    deleteClassify: "/api/user-tag-classify/delete-classify",
    setClassify: "/api/user-tag-classify/set-classify",
    clearClassify: "/api/user-tag-classify/clear-classify",
    enabledPackage: "/api/user-tag-classify/enabled-package",
    packageInfo: "/api/user-tag-classify/package-info",
    getAllLicenseConnected: "/api/rental/get-license-client-connected",
    getEstimateUse: "/api/users/get-estimate-use",
    getRenderSoftwareFocusByUser: "/api/users/get-render-software-focus",
    getlazyLoadInfo: "/api/users/get-lazyload-user-info/{0}",
    saveSettingsUsers: "/api/users/save-user-settings",
    setMaxonNodelockLicense: "/api/users/set-maxon-nodelock-license",
};

export const exportTaskCnf = {
    getTaskList: `/api/export-task/list`,
    removeTask: `/api/export-task/remove`,
    cancelTask: `/api/export-task/cancel`,
    downloadTask: `/api/export-task/download`,
}

export const requestAwsInfo = {
    getCostDailyAsync: `/api/aws-cost-explorer/get-cost-daily`,
    getCostExplorerMetrics: `/api/aws-cost-explorer/get-cost-explorer-metrics`,
}

export const requestUserConfig = {
    getUserRequestUrl: `/api/users-request/get-user-request`,
    getUserRequestDetailUrl: `/api/users-request/get-user-request-detail/{0}`,
}

export const paymentApiConfig = {
    getInvoice: "/api/invoices/get-invoices",
    getTransaction: "/api/transactions/get-transactions",
    getRenderBilling: "/api/render-billing/get-render-billing",
    getDetailRenderBilling: "/api/render-billing/get-detail-render-billing",
}

export const gpuHubCnf = {
    getListMachine: "/api/vmMachines/get-machine",
    getMachineByUser: "/api/vmMachines/get-machine-by-user/{0}",
    getListMachineAbandoned: "/api/vmMachines/get-machine-abandoned",
    notifiedSelectedMachines: "/api/vmMachines/notify-selected-machines",
    exportListMachineAbandoned: "/api/vmMachines/export-machine-abandoned",
    removeMachine: "/api/vmMachines/remove-machine",
    removeSelectedMachine: "/api/vmMachines/remove-selected-machines",
    shutdownMachine: "/api/vmMachines/shutdown-machine",
    resetMachine: "/api/vmMachines/reset-machine",
    trustedImage: "/api/vmMachines/trusted-image/{0}",
    updateBootReady: "/api/vmMachines/update-boot-ready/{0}",
    createBilling: "/api/vmMachines/create-billing/{0}",
    createBillingFromHistory: "/api/vmMachines/create-billing-from-history/{0}",
    createBillingManual: "/api/vmMachines/create-billing-manual",
    getMachineBootHistoryData: "/api/vmMachines/get-boot-history/{0}",
    getStatisticMachineBootHistoryData: "/api/vmMachines/get-statistic-boot-history/{0}",
    getCountImageOverState: "/api/vmMachines/get-count-image-over-state",
    getSoftwareInstalledByMachineId: "/api/vmMachines/get-software-installed/{0}",
    getProccessByMachineId: "/api/vmMachines/get-proccess/{0}",
    getEventLogByMachineId: "/api/vmMachines/get-event-log/{0}",
    fixedRentalDetailsViewByPackage: "/api/vmMachines/fixed-rental-details-view-by-package",
    getListPackage: "/api/rental/get-service-pack-list",
    getAllServerReport: "/api/rental/get-server-report",

    getAllCategory: "/api/rental/get-category-list",
    getAllSoftware: "/api/rental/get-software-list",
    rdpGatewayAdminGetAll: "/api/rental/get-rdp-gateway-history-view",
    rdpGatewayAdminGetAllGateway: "/api/rental/get-rdp-gateway-server",
    rdpGatewayAdminGetGatewayMetrics: "/api/rental/get-rdp-gateway-metrics",

    getListBilling: "/api/gpuhub-billing/get-billing",
    getListBillingByMachine: "/api/gpuhub-billing/get-billing-by-machine/{0}",
    getListInvoice: "/api/gpuhub-invoice/get-invoice",
    getInvoiceInitedByUser: "/api/gpuhub-invoice/get-invoice-inited-by-user",
    trackInvoice: "/api/gpuhub-invoice/track-invoice/{0}",
    verifyPayment: "/api/gpuhub-invoice/verify-invoice/{0}",
    getListTransaction: "/api/gpuhub-transaction/get-transaction",
    exportListTransaction: "/api/gpuhub-transaction/export-transaction",
    updateTransaction: "/api/gpuhub-transaction/update-transaction",
    deleteTransaction: "/api/gpuhub-transaction/delete-transaction",

    getRechargeTestStatistics: "/api/gpuhub-transaction/get-recharge-test-statistics",
    getRechargeTestStatisticsDetail: "/api/gpuhub-transaction/get-recharge-test-statistics-detail",
    exportRechargeTestStatistics: "/api/gpuhub-transaction/export-recharge-test-statistics",

    //Service package
    getServicePackage: "/api/service-package/get-service-package",
    createServicePackage: "/api/service-package/create-service-package",
    updateServicePackage: "/api/service-package/update-service-package",
    deleteServicePackage: "/api/service-package/delete-service-package/{0}",

    getTotalRecharge: "/api/rental/get-total-recharge",
    getTotalRechargeByStaff: "/api/rental/get-total-recharge-by-staff/{0}",

    getListServers: "/api/vmServer/get-all-servers",
    getServerComment: "/api/vmServer/get-server-comment",
    addServerComment: "/api/vmServer/add-server-comment",
    getServerStateInfo: "/api/vmServer/get-server-state-infomations",

    getListRefundTransaction: "/api/RealRefundTransaction/get-refund-transaction",
    addRefundTransaction: "/api/RealRefundTransaction/add-refund-transaction",
    markRefunded: "/api/gpuhub-transaction/mark-refunded",

    confirmToKeep: "/api/vmMachines/keep-confirm-image",
    getClientImageStorageInfo: "/api/rental/get-client-image-storage-info",
    getMachineIssues: "/api/vmMachines/get-machine-issues",
    getLowBalanceReport: "/api/rental/get-low-balance-report",
};

export const vmMachineHistoryCnf = {
    getMachineHistory: "/api/vm-machine-history/get-machine-history/{0}",
};

export const paymentServiceConfig = {
    getStripeHistory: "/api/payment/stripe-history-view",
};

export const fixedRentalConfig = {
    getFixedRentalUrl: "/api/fixed-rental/fixed-rental-view",
    getFixedRentalDetailsUrl: "/api/fixed-rental/fixed-rental-details-view",
    fixedRentalCreateNewUrl: "/api/fixed-rental/fixed-rental-create-new",
    fixedRentalEditUrl: "/api/fixed-rental/fixed-rental-details-modify",
    getMachineListByUsernameUrl: "/api/fixed-rental/get-machine-list-by-username/{0}/{1}",
    fixedRentalChangeImageUrl: "/api/fixed-rental/fixed-rental-details-modify-change-image",
    addImageUrl: "/api/fixed-rental/fixed-rental-modify-add-image",
    changeStatusUrl: "/api/fixed-rental/fixed-rental-details-modify-change-status",
    settingsUrl: "/api/fixed-rental/fixed-rental-details-settings",
    removeUrl: "/api/fixed-rental/fixed-rental-remove",
};

export const renderRequestCnf = {
    getElementsList: "/api/render-request/get-render-request",
    updateStatus: "/api/render-request/update-status/{0}/{1}",
    updateNote: "/api/render-request/update-note/{0}/{1}",
    takeCareThis: "/api/render-request/take-care-this/{0}",
};

export const chartDataCnf = {
    getMachineUserdChartData: "/api/chart/get-machine-used/{0}",
    getMachineBootingChartData: "/api/chart/get-machine-booting/{0}",
    getUserCreatedChartData: "/api/chart/get-user-created/{0}",
    getUserRechargeMoreThanOneChartData: "/api/chart/get-user-recharge-more/{0}",
    getMachineCreatedChartData: "/api/chart/get-machine-created/{0}",
    getTotalRechargeChartData: "/api/chart/get-total-recharge/{0}",
    getTotalRechargeByStaffChartData: "/api/chart/get-total-recharge-by-staff/{0}",
    getTotalUsingTimeChartData: "/api/chart/get-total-using-time/{0}",
    getCountUserRechargeByLevel: "/api/chart/get-recharge-count-by-level/{0}?source={1}",
    getCountMasterNodeByCategory: "/api/chart/get-count-node-by-category/{0}",
    getCountMasterNodeBySoftware: "/api/chart/get-count-node-by-software/{0}",
    getPercentMasterNodeByCategory: "/api/chart/get-percent-node-by-category",
    getPercentMasterNodeBySoftware: "/api/chart/get-percent-node-by-software",
    getCountBlackListUser: "/api/chart/get-count-blacklist-user/{0}",
    getCountPaidUserWithRecharge: "/api/chart/get-count-paid-user/{0}/{1}",
    getBootingAvgStatisticsByDate: "/api/chart/get-avg-boot-duration-by-date/{0}",
    getBootingSpentShutdownAvgStatisticsByDate: "/api/chart/get-avg-boot-spent-shutdown-duration-by-date/{0}/{1}",
    getRevenueByCountry: "/api/chart/get-revenue-by-country/{0}",
    getRevenueDetailOfRegion: "/api/chart/get-revenue-detail-of-region",
    getUserByCountry: "/api/chart/get-user-by-country/{0}",
    getTop10RegionRevenueAndUser: "/api/chart/get-top-10-revenue-by-country/{0}",
    getUserByTop10Country: "/api/chart/get-user-top-10-country",
    getSoftwareInstalledStatistics: "/api/chart/get-software-install-statistics",
    getProccessStatistics: "/api/chart/get-proccess-statistics",
    getStatisticsUserRechargeAfterRegister: "/api/chart/get-statistics-recharge-after-register",
    getRevenueByMonth: "/api/chart/get-revenue-by-month/{0}",
    getCountUserRechargeLevelByMonth: "/api/chart/get-count-user-recharge-level-by-month/{0}",
    getStatisticsRegisteredRechargeSameDate: "/api/chart/get-statistics-registered-recharged-same/{0}",
    getSpentOnPackageByMonth: "/api/chart/get-spent-on-package-by-month/{0}",
    getUserActivityDetail: "/api/chart/get-user-activity-detail",
    getRevenueByDateAccordingRechargeTime: "/api/chart/get-revenue-according-rechage-time",
    avgAmountOnRechargeTime: "/api/chart/get-avg-amount-on-recharge-time",
    statisticOnSpecialUser: "/api/chart/get-statistics-special-user",
    statisticS3TransferOutByDate: "/api/chart/get-statistic-transfer-out-s3-by-date",
    getTransferOutS3TopCostByMonth: "/api/chart/get-transfer-out-s3-top-cost-by-month",
    getVipRechargeStatistics: "/api/chart/get-vip-recharge-statistics",
};

export const promotionCnf = {
    saveAndSendPromotUrl: "/api/promotion/save-and-send",
    getAllPromotionUrl: "/api/promotion/get-all-promotion",
    deletePromotionUrl: "/api/promotion/delete-promotion/{0}",
    savePromotionUrl: "/api/promotion/save-message",
    coppyPromotionUrl: "/api/promotion/copy-message",
    generateCouponUrl: "/api/promotion/generate-coupons",
    createCampaignUrl: "/api/promotion/create-campain",
    getAllCampaignUrl: "/api/promotion/get-all-campain",
    editCampaignUrl: "/api/promotion/edit-campain",
    removeCampaignUrl: "/api/promotion/remove-campain",
    deactiveCampaignUrl: "/api/promotion/deactive-campain",
    activeCampaignUrl: "/api/promotion/active-campain",
    getAllCouponUrl: "/api/promotion/get-all-coupon",
    filterCampaignUrl: "/api/promotion/filter-campain",
    updateNoteUrl: "/api/promotion/update-note",
    updateAllowedBooting: "/api/promotion/update-allowed-booting",
};

export const vmMachineExtraServiceConf = {
    listExtraServices: "api/vmMachineExtraService/list",
    getExtraServices : "api/vmMachineExtraService/get/{0}",
    markedDone : "api/vmMachineExtraService/marked-done/{0}",
    markedDenied : "api/vmMachineExtraService/marked-denined/{0}",
    removeExtraServices : "api/vmMachineExtraService/remove/{0}"
}

export const userExtraServiceConf = {
    listExtraServices: "api/userExtraService/list",
    getExtraServices : "api/userExtraService/get/{0}",
    markedDone : "api/userExtraService/marked-done/{0}",
    markedDenied : "api/userExtraService/marked-denined/{0}"
}

export const extraServiceRequestConf = {
    listExtraServiceRequest: "api/extraServiceRequest/list",
    getExtraServiceRequest : "api/extraServiceRequest/get/{0}",
    changedStatus : "api/extraServiceRequest/change-status",
    markedReject: "api/extraServiceRequest/marked-reject/{0}",
    getCountRequest: "api/extraServiceRequest/get-count-request",
    closeIssues: "api/extraServiceRequest/close-issues",
}

export const notificationConf = {
    getEmailTask: "api/notification/get-email-task",
    saveEmailTask: "api/notification/save-email-task",
    startStopEmailTask: "api/notification/start-stop-email-task",
    sendNotificationManual: "api/notification/send-notification-manual",
};

export const agencyCnf = {
    enableAgency: "api/agency/enable-agency-account",
};

export const jobSyncCnf = {
    getDataView: "/api/sync-mng/get-data-view",
    resyncJob: "/api/sync-mng/resync-job",
    cancelJob: "/api/sync-mng/cancel-job",
};

export const userTransferingCnf = {
    getDataView: "/api/user-transfering/get-data-view",
    getUserTransferingDetail: "/api/user-transfering/get-detail-user",
    getTransferingAllStepCount: "/api/user-transfering/get-count-realtime-transfer",
    getRealtimeTransferingByStep: "/api/user-transfering/get-realtime-transfer",
};

export const rentalNewsCnf = {
    getDataView: "/api/rental-news/get-data-view",
    getById: "/api/rental-news/get-news-by-id",
    insertOrUpdate: "/api/rental-news/insert-or-update",
    deleteById: "/api/rental-news/delete-news-by-id",
};

export const supportTicketConf = {
    getTicketCategory: '/api/support-ticket/get-ticket-category',
    getTicketType: '/api/support-ticket/get-ticket-type',
    getQuantilyTicket: '/api/support-ticket/get-quantily-ticket-type',
    submitTicket: '/api/support-ticket/submit-ticket'
};

const analyzeStatusDict = {
    "0": "Chưa xử lý",
    "1": "Đã tiếp nhận",
    "2": "Đang xử lý",
    "31": "Đã nhập thành công",
    "32": "Đã nhập lỗi",
    "4": "Đã xử lý xong",
    "5": "Đã xử lý lỗi",
};

const colorDict = {
    "0": "text-muted",
    "7": "text-primary",
    "-71": "text-primary",
    "5": "text-primary",
    "42": "text-primary",
    "43": "text-success",
    "8": "text-success",
    "-43": "text-danger",
};

const periodUnitCategories = {
    "1": "Week",
    "2": "Month",
    "3": "Year",
};

export const GPURENTAL_STATUS_CODE = {
    SqlUser_GetByEmailAdress_Success: 1000000, //prc_Get_By_Email_User
    SqlUser_GetByEmailAdress_Failed: 1000001,
    SqlUser_GetByUsername_Success: 1000100, //prc_Get_By_Username_User
    SqlUser_GetByUsername_Failed: 1000101,
    SqlUser_Insert_Success: 1000200, //prc_Insert_User
    SqlUser_Insert_Failed: 1000201,
    SqlUser_Delete_Success: 1000300, //prc_Delete_User
    SqlUser_Delete_Failed: 1000301,
    SqlUser_GetById_Success: 1000400, //prc_Get_ByID_User
    SqlUser_GetById_Failed: 1000401,
    SqlUser_UpdateNewPassword_Success: 1000500, //prc_UpdatePassword_ByID_User
    SqlUser_UpdateNewPassword_Failed: 1000501,
    SqlUser_UpdateProfileUser_Success: 1000600, //prc_Update_User
    SqlUser_UpdateProfileUser_Failed: 1000601,

    SqlUserRentalWallet_GetByUserId_Success: 1010000, //prc_Get_ByUserID_UserRentalWallet
    SqlUserRentalWallet_GetByUserId_Failed: 1010001,
    SqlUserRentalWallet_Insert_Success: 1010100, //prc_Insert_UserRentalWallet
    SqlUserRentalWallet_Insert_Failed: 1010101,

    SqlPasswordResetToken_GetByUserId_Success: 1020000, //prc_Get_ByUserID_PasswordResetToken
    SqlPasswordResetToken_GetByUserId_Failed: 1020001,
    SqlPasswordResetToken_Insert_Success: 1020100, //prc_Insert_PasswordResetToken
    SqlPasswordResetToken_Insert_Failed: 1020101,
    SqlPasswordResetToken_Update_Success: 1020200, //prc_Update_PasswordResetToken
    SqlPasswordResetToken_Update_Failed: 1020201,

    AffiliateService_RegistAffiliate_Success: 2000000,
    AffiliateService_RegistAffiliate_Failed: 2000001,
    AffiliateService_RegistAffiliate_Timeout: 2000002,

    UnknownError: 1000,
    InvalidInfo: 1001,

    ForgotPassword_Success: 2000,
    ForgotPassword_EmailNotExist: 2001,

    Register_Success: 3000,
    Register_UsernameExist: 3001,
    Register_EmailExist: 3002,

    Login_Success: 4000,
    Login_UsernameNotExist: 4001,
    Login_PasswrodIncorrect: 4002,
    Login_UserHasLocked: 4003,
    Login_NonActivate: 4004,

    Wallet_GetUserRentalWallet_Success: 5000,
    Wallet_GetUserRentalWallet_Failed: 5001,

    Logout_Success: 6000,
    Logout_Failed: 6001,

    Rental_UserNotExist: 7000,
    Rental_LimitedImage: 7001,
    Rental_CantGetWallet: 7002,
    Rental_MachineNotExist: 7003,
    Rental_BalanceNotEnough: 7004,

    ListMachine_Success: 8000,

    ChangePassword_Success: 9000,
    ChangePassword_Failed: 9001,

    ListServicePack_Success: 10000,

    CreateMachine_Success: 11000,
    CreateMachine_LimitedBalance: 11001,
    CreateMachine_LimitedMinimumBalanceToCreateServicePack: 11002,

    BootMachine_Success: 12000,
    BootMachine_LimitedMiniumBalanceToBootServicePack: 12001,
    BootMachine_NoMachineAvailability: 12002,
    BootMachine_LimitTotalMoneyDeposited: 12003,

    ShutdownMachine_Success: 13000,

    GetProfile_Success: 14000,
    GetProfile_Failed: 14001,

    UpdateProfile_Success: 15000,

    RenameMachine_Success: 16000,

    Remove_Success: 17000,
    Remove_MachineRunningCantRemove: 17001,

    DownloadMachine_FileRDPNotExist: 18000,

    Payment_GetIrenderPoint_Success: 19000,
    Payment_GetIrenderPoint_Failed: 19001,

    Wallet_GetTempBalanceUser_Success: 20000,

    Cancel_Success: 21000,

    GetPaymentPolicy_Success: 22000,

    GetParameter_Success: 23000,

    GetMasterList_Success: 24000,

    RequestImage_Success: 25000,
    RequestImage_LimitedMinimumBalanceToRequest: 25001,

    DashboardGetInformationMachine_Success: 26000,
    DashboardGetInformationBilling_Success: 26001,

    GetAllServerReport_Success: 27000,

    ResetMachine_Success: 28000,

    GetCategoryList_Success: 29000,

    GetSoftwareList_Success: 30000,

    Payment_Pay_Success: 31000,
    Payment_LimitMinimumAmount: 31001,

    GetBasicUserInformation_Success: 32000,

    ReSendEmail_Success: 33000,
    ReSendEmail_Failed: 33001,
    ReSendEmail_AccountHadActive: 33002,

    CloneImage_Success: 34000,
    CloneImage_Failed: 34001,
    CloneImage_LimitedNumberCopy: 34002,
    CloneImage_LimitedRechargedUser: 34003,

    GetUserConfiguration_Success: 35000,

    PreBootMachine_Success: 36000,

    ChangeServicePackage_Success: 37000,

    RequestNotificationMachine_Success: 38000,
};

export const USER_LOG_STATUS_CODE = {
    //Login cookie
    Login_Cookie_Success: 10000,
    Login_Cookie_Exception: 10001,
    Login_Cookie_GetByUsername: 10002,
    Login_Cookie_GetByEmailAdress: 10003,
    Login_Cookie_UserNotExist: 10004,
    Login_Cookie_NonActivate: 10005,
    Login_Cookie_WrongPassword: 10006,
    Login_Cookie_UserBlocked: 10007,
    Login_Cookie_UserNoStatus: 10008,

    //Login token
    Login_Token_Success: 10100,
    Login_Token_Exception: 10101,
    Login_Token_GetByUsername: 10102,
    Login_Token_GetByEmailAdress: 10103,
    Login_Token_UserNotExist: 10104,
    Login_Token_NonActivate: 10105,
    Login_Token_WrongPassword: 10106,
    Login_Token_UserBlocked: 10107,
    Login_Token_UserNoStatus: 10108,

    //Register
    Register_Success: 20000,
    Register_Exception: 20001,
    Register_GetByUsername: 20002,
    Register_GetByEmailAdress: 20003,
    Register_UsernameExist: 20004,
    Register_EmailExist: 20005,
    Register_Insert: 20006,

    //Resent active email
    ResentEmail_Success: 30000,
    ResentEmail_Exception: 30001,
    ResentEmail_GetByEmailAdress: 30002,
    ResentEmail_EmailNotExist: 30003,
    ResentEmail_AccountActivated: 30004,
    ResentEmail_CantSendEmail: 30005,

    //Forgot password
    ForgotPassword_Success: 40000,
    ForgotPassword_Exception: 40001,
    ForgotPassword_GetByEmailAdress: 40002,
    ForgotPassword_EmailNotExist: 40003,
    ForgotPassword_GetByUserId: 40004,
    ForgotPassword_Insert: 40005,
    ForgotPassword_Update: 40006,

    //Change password
    ChangePassword_Success: 50000,
    ChangePassword_Exception: 50001,
    ChangePassword_UserNotExist: 50002,
    ChangePassword_OldPasswordInvalid: 50003,

    //Update profile
    UpdateProfile_Success: 60000,
    UpdateProfile_Exception: 60001,
    UpdateProfile_UpdateProfileUser: 60002,

    //Logout
    Logout_Success: 70000,
    Logout_Exception: 70001,
    Logout_UserNotExist: 70002,

    //Create machine
    CreateMachine_Success: 80000,
    CreateMachine_Exception: 80001,
    CreateMachine_UserNotExist: 80002,
    CreateMachine_BalanceNotEnough: 80003,
    CreateMachine_GetVMMachimeByUserId: 80004,
    CreateMachine_GetAll: 80005,
    CreateMachine_GetAll_NoData: 80006,
    CreateMachine_ServicePackNotFound: 80007,
    CreateMachine_GetUserConfiguration: 80008,
    CreateMachine_GetUserConfiguration_NoData: 80009,
    CreateMachine_UserLevelNotMatch: 80010,
    CreateMachine_LimitedImageByUserLevel: 80011,
    CreateMachine_LimitedMinimumBalance: 80012,
    CreateMachine_InsertVmMachine: 80013,
    CreateMachine_InsertUserVmMachine: 80014,

    //Boot machine
    BootMachine_Success: 90000,
    BootMachine_Exception: 90001,
    BootMachine_UserNotExist: 90002,
    BootMachine_UserAndMachineNotMatch: 90003,
    BootMachine_CheckServicePackAvailability: 90004,
    BootMachine_NoMachineAvailability: 90005,
    BootMachine_BalanceNotEnough: 90006,
    BootMachine_LimitTotalMoneyDeposited: 90007,
    BootMachine_LimitedMinimumBalance: 90008,
    BootMachine_Update: 90009,

    //Shutdown machine
    ShutdownMachine_Success: 100000,
    ShutdownMachine_Exception: 100001,
    ShutdownMachine_UserNotExist: 100002,
    ShutdownMachine_UserAndMachineNotMatch: 100003,
    ShutdownMachine_Update: 100004,

    //Change service package
    ChangeServicePackageMachine_Success: 110000,
    ChangeServicePackageMachine_Exception: 110001,
    ChangeServicePackageMachine_UserNotExist: 110002,
    ChangeServicePackageMachine_UserAndMachineNotMatch: 110003,
    ChangeServicePackageMachine_ChangeServicePackage: 110004,

    //Clone image
    CloneImage_Success: 120000,
    CloneImage_Exception: 120001,
    CloneImage_UserNotExist: 120002,
    CloneImage_UserAndMachineNotMatch: 120003,
    CloneImage_BalanceNotEnough: 120004,
    CloneImage_LimitedUserLevel: 120005,
    CloneImage_GetVMMachimeByUserId: 120006,
    CloneImage_GetAll: 120007,
    CloneImage_GetAll_NoData: 120008,
    CloneImage_ServicePackNotFound: 120009,
    CloneImage_GetUserConfiguration: 120010,
    CloneImage_GetUserConfiguration_NoData: 120011,
    CloneImage_UserLevelNotMatch: 120012,
    CloneImage_LimitedImageByUserLevel: 120013,
    CloneImage_LimitedMinimumBalance: 120014,
    CloneImage_CloneImage: 120015,

    //Download machine
    //DownloadMachine_Success : 130000,
    //DownloadMachine_Exception : 130001,
    //DownloadMachine_UserNotExist : 130002,
    //DownloadMachine_UserAndMachineNotMatch : 130003,
    //DownloadMachine_FileRDPNotExist : 130004,

    //Rename image
    RenameImage_Success: 140000,
    RenameImage_Exception: 140001,
    RenameImage_UserNotExist: 140002,
    RenameImage_UserAndMachineNotMatch: 140003,
    RenameImage_RenameMachine: 140004,

    //Remove image
    RemoveImage_Success: 150000,
    RemoveImage_Exception: 150001,
    RemoveImage_UserNotExist: 150002,
    RemoveImage_UserAndMachineNotMatch: 150003,
    RemoveImage_MachineStillActivity: 150004,
    RemoveImage_Update: 150005,
}

export const editorConfig = {
    //height: 400,
    extraPlugins: 'placeholder,colorbutton,divarea,editorplaceholder,emoji,justify,panelbutton,autolink,button,codesnippet,embed,font,tableresize',
    filebrowserBrowseUrl: '/CKFinderScripts/ckfinder.html',
    filebrowserImageBrowseUrl: '/CKFinderScripts/ckfinder.html?type=Images',
    filebrowserFlashBrowseUrl: '/CKFinderScripts/ckfinder.html?type=Flash',
    filebrowserUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Files',
    filebrowserImageUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Images',
    filebrowserFlashUploadUrl: '/ckfinder/connector?command=QuickUpload&type=Flash',
    filebrowserWindowWidth: '1000',
    filebrowserWindowHeight: '700',
    resize_enabled: false,
}

export const tinyEditorInit = {
    selector: 'textarea#full-featured-non-premium',
    plugins: 'print preview paste importcss searchreplace autolink autosave directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars emoticons',
    imagetools_cors_hosts: ['picsum.photos'],
    menubar: 'file edit view insert format tools table',
    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
    toolbar_sticky: true,
    autosave_ask_before_unload: true,
    autosave_interval: '30s',
    autosave_prefix: '{path}{query}-{id}-',
    autosave_restore_when_empty: false,
    autosave_retention: '2m',
    image_advtab: true,
    link_list: [
        { title: 'https://irendering.net/', value: 'https://irendering.net/' },
        { title: 'https://irender.vn/', value: 'https://irender.vn/' },
        { title: 'https://app.gpuhub.net/', value: 'https://app.gpuhub.net/' },
        { title: 'https://docs.gpuhub.net/', value: 'https://docs.gpuhub.net/' },
    ],
    image_list: [],
    image_class_list: [
        { title: 'None', value: '' },
        { title: 'Rounded', value: 'rounded' },
        { title: 'Circle', value: 'rounded-circle' },
        { title: 'Thumbnail', value: 'img-thumbnail' },
        { title: 'Float-left', value: 'float-left' },
        { title: 'Float-right', value: 'float-right' },
        { title: 'Centered Image', value: 'mx-auto d-block' },
        { title: 'Responsive Images', value: 'img-fluid' }
    ],
    importcss_append: true,
    file_picker_callback: function (callback, value, meta) {
        /* Provide file and text for the link dialog */
        if (meta.filetype === 'file') {
            window.selectFileWithCKFinder((fileUrl) => {
                callback(fileUrl, { text: 'iRender Vietnam JSC' });
            });
        }

        /* Provide image and alt text for the image dialog */
        if (meta.filetype === 'image') {
            window.selectFileWithCKFinder((fileUrl) => {
                callback(fileUrl, { alt: 'iRender Vietnam JSC' });
            });
        }

        /* Provide alternative source and posted for the media dialog */
        if (meta.filetype === 'media') {
            window.selectFileWithCKFinder((fileUrl) => {
                callback(fileUrl, { text: 'iRender Vietnam JSC' });
            });
        }
    },
    templates: [
        { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
        { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
        { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
    ],
    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
    height: 600,
    image_caption: true,
    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
    noneditable_noneditable_class: 'mceNonEditable',
    toolbar_mode: 'wrap',
    contextmenu: 'link image imagetools table',
    skin: 'oxide',
    content_css: 'default',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
};

export const pickerOptions = {
    shortcuts: [{
        text: 'Today',
        onClick(picker) {
            const start = new Date();
            const end = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: 'Yesterday',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: 'This month',
        onClick(picker) {
            const dNow = new Date();
            const end = new Date(dNow.getFullYear(), dNow.getMonth() + 1, 0);
            const start = new Date(dNow.getFullYear(), dNow.getMonth(), 1);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: 'The last 5 days',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 5);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: 'The last 10 days',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 10);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: 'The last 20 days',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 20);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: 'The last 30 days',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
        }
    }]
};

export { analyzeStatusDict, colorDict, periodUnitCategories }