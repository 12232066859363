export const actInDoubtListStatus = {
    notProcess: 0,
    processing: 1,
    processed: 100,
}

export const actInDoubtStatus = [
    {
        status: actInDoubtListStatus.notProcess,
        name: 'Not process',
        color: '#1E88E5',
    },
    {
        status: actInDoubtListStatus.processing,
        name: 'Processing',
        color: 'darkorange',
    },
    {
        status: actInDoubtListStatus.processed,
        name: 'Processed',
        color: '#BDBDBD',
    },
]