<template>
    <div style="background-image: url('/static/image/slider/4.jpg')"
         class="pos-relative">
        <div class="wrapper"
             :style="` background-image: url('/img/bg-tet.jpg?t=${currentDateUncache}'); background-size: cover; background-position: bottom; `">
            <!-- Navbar -->
            <nav class="main-header navbar navbar-expand navbar-white navbar-light"
                 style="height: 4rem; background-size: cover; background-position: center top; background-color: rgba(24, 24, 24, 0.64); border: none; ">
                <!-- Left navbar links -->
                <ul class="d-flex flex-fill align-items-center navbar-nav w-100">
                    <li class="nav-item">
                        <a class="d-none d-md-block nav-link" data-widget="pushmenu" href="javascript:void(0);" role="button">
                            <i class="fas fa-bars text-white"></i>
                        </a>                        
                        <a class="d-block d-md-none mr-2" data-widget="pushmenu" href="javascript:void(0);" role="button">
                            <img :src="appSquareLogo" style="height: 2.7rem;" />
                        </a>
                    </li>
                    <li class="d-flex flex-fill">
                        <serverReport :openPopup="openPopup"/>
                    </li>
                </ul>
            </nav>
            <!-- Main Sidebar Container -->
            <aside class="main-sidebar sidebar-dark-primary elevation-4">
                <div class="mt-3 px-2">
                    <img :src="appLogoWhite" style="height: 40px; max-width: 100%;" />
                </div>
                <div class="d-flex mt-3 ml-1" style="font-size: 0.75rem; height">
                    <div class="d-flex justify-content-center" style="width: 4.5rem;">
                        <el-dropdown @command="contextMenuAction" trigger="click">
                            <span class="el-dropdown-link">
                                <v-avatar class="position-relative" size="3rem">
                                    <img :src="`/img/avatars/${userInfo.userEmail}.jpg`" />
                                </v-avatar>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <!-- Change password -->
                                <el-dropdown-item command="changePassword">
                                    <div class="no-select d-flex flex-row align-items-center"
                                         style="text-decoration: none">
                                        <div class="d-flex justify-content-center"
                                             style="width: 2rem">
                                            <i style="margin-right: 0.95rem" class="el-icon-lock"></i>
                                        </div>
                                        <span class="font-size-9" style="margin-bottom: 0.1rem">Change password</span>
                                    </div>
                                </el-dropdown-item>

                                <!-- Logout -->
                                <el-dropdown-item command="logout" divided>
                                    <div class="no-select d-flex flex-row align-items-center"
                                         style="text-decoration: none">
                                        <div class="d-flex justify-content-center"
                                             style="width: 2rem">
                                            <i style="margin-right: 0.95rem; color: #f44336"
                                               class="el-icon-switch-button"></i>
                                        </div>
                                        <span class="font-size-9" style="margin-bottom: 0.1rem">Logout</span>
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                        <div class="pt-1" style="font-size: 0.85rem; color: white">
                            {{ userInfo.fullName }}
                        </div>
                        <div class="pb-1" style="color: white; opacity: 0.5">
                            {{ userInfo.userEmail }}
                        </div>
                    </div>
                </div>
                
                <div class="text-white w-100 pl-3 pr-3 pt-3 pb-3"
                    v-if="countDown600Days2023Val"
                    @mouseover="countDownIsHover = true" @mouseleave="countDownIsHover = false">
                    <vue-countdown tag="div" 
                        v-if="!countDownIsHover"
                        :time="countDown600Days2023Val" 
                        v-slot="{ days, hours, minutes, seconds }">                        
                        <div class="d-flex w-100 align-items-center justify-content-center">
                            <div class="day-countdown text-center flex-fill">
                                <div><h4 class="mb-0" style=" line-height: 1.3rem;"><strong>{{ days }}</strong></h4></div>
                                <div style="font-size: 80%;">days</div>
                            </div>
                            <div class="hour-countdown text-center flex-fill">
                                <div><h4 class="mb-0" style=" line-height: 1.3rem;"><strong>{{ hours }}</strong></h4></div>
                                <div style="font-size: 80%;">hours</div>
                            </div>
                            <div class="minute-countdown text-center flex-fill">
                                <div><h4 class="mb-0" style=" line-height: 1.3rem;"><strong>{{ minutes }}</strong></h4></div>
                                <div style="font-size: 80%;">mins</div>
                            </div>
                            <div class="minute-countdown text-center flex-fill">
                                <div><h4 class="mb-0" style=" line-height: 1.3rem;"><strong>{{ seconds }}</strong></h4></div>
                                <div style="font-size: 80%;">secs</div>
                            </div>
                        </div>
                    </vue-countdown>
                    <div v-if="countDownIsHover" class="text-center">
                        <h2 class="mb-0">
                            <strong><i class="el-icon-date"></i> {{ countDownTargetDay }}</strong>
                        </h2>
                    </div>
                </div>
                <!-- Sidebar Menu -->
                <VuePerfectScrollbar class="d-flex mr-2"
                                     :settings="{ suppressScrollY: false }"
                                     style="height: calc(100dvh - 14rem)">
                    <navigation />
                </VuePerfectScrollbar>
            </aside>

            <!-- Content Wrapper. Contains page content -->
            <div class="content-wrapper" style="height: calc(100dvh - 4.02rem)"
                 v-loading="$root.globalLoading">
                <progress-bar></progress-bar>
                <router-view :key="$route.path" style="padding: 0.5rem"></router-view>
            </div>

            <footer class="main-footer pt-1 pb-1">
                <VuePerfectScrollbar class="w-100 hide-x-rails" :settings="{ suppressScrollY : true }"
                                     style="padding-bottom: 0 !important;">
                    <div class="d-flex align-items-center"
                         style="min-width: 100%;">
                        <div class="text-nowrap">
                            <strong v-if="countImageOverState !== null">
                                    <span class="mr-2 text-white">
                                        <i class="el-icon-monitor"></i>
                                    </span>
                                <router-link to="/gpuhub-machines?status=shuttingdown">
                                    <span class="mr-2 text-warning">
                                        {{ countImageOverState.shuttingDownCount }} Shutting down
                                        <span v-if="countImageOverState.maxShutingdownTime">
                                            ({{ countImageOverState.maxShutingdownTime | secondDuration }})
                                        </span>
                                    </span>
                                </router-link>
                                <router-link to="/gpuhub-machines?status=booting">
                                    <span class="mr-2 text-white">
                                        {{ countImageOverState.bootingCount }} Booting
                                        <span v-if="countImageOverState.maxBootingTime">
                                            ({{ countImageOverState.maxBootingTime | secondDuration }})
                                        </span>
                                    </span>
                                </router-link>
                                <router-link to="/gpuhub-machines?status=running">
                                    <span class="mr-2 text-orange">
                                        {{ countImageOverState.runningCount }}/{{countImageOverState.totalServers}} Running                                        
                                        <span v-if="(rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))">
                                            ({{countImageOverState.totalGpuUsed}} GPUs used - {{ Math.round( (countImageOverState.totalGpuUsed / countImageOverState.totalGpuCard + Number.EPSILON) * 100 ) }}%)
                                        </span>
                                    </span> 
                                </router-link>
                            </strong>
                            <div class="d-inline-block" v-if="countImageOverState !== null && (rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))">                                
                                <div class="mr-2" v-if="countImageOverState.totalUserOnline > 0">
                                    <el-popover placement="top" class="d-md-block d-none"
                                                width="1000"
                                                trigger="click">
                                        <div class="d-flex w-100 flex-column" style="height: 700px;" >
                                            <div class="text-center">
                                                <h5><strong class="text-primary">Detail user activities</strong></h5>
                                            </div>
                                            <VuePerfectScrollbar class="flex-fill" >
                                                <userActiveDetail />
                                            </VuePerfectScrollbar>
                                        </div>
                                        <el-button type="text" class="p-0 m-0 text-maroon" 
                                                   style="color: #d81b60 !important;"
                                                   slot="reference">
                                            <strong>{{ countImageOverState.totalUserOnline }} Users activity</strong>
                                        </el-button>
                                    </el-popover>
                                     <span class="p-0 m-0 text-maroon d-block d-md-none" 
                                                style="color: #d81b60 !important;">
                                        <strong>{{ countImageOverState.totalUserOnline }} Users activity</strong>
                                    </span>
                                </div>
                            </div> 
                            <strong v-if="statistics" class="ml-3">Octane {{ statistics.OctaneTotal -
                                        statistics.OctaneActived }}A/{{ statistics.OctaneTotal }}T</strong>
                            <strong v-if="statistics" class="ml-3">C4d NL {{ statistics.C4dNodeLockTotal -
                                        statistics.C4dNodeLockActived }}A/{{ statistics.C4dNodeLockTotal }}T</strong>
                            <strong v-if="statistics" class="ml-3">Redshift NL {{ statistics.RsNodeLockTotal -
                                        statistics.RsNodeLockActived }}A/{{ statistics.RsNodeLockTotal }}T</strong>
                        </div>
                        <div class="flex-fill d-none d-md-block"></div>
                        <div v-if="currentCsStaffData" class="mr-2 text-nowrap">
                            <img :src="`/img/avatars/${currentCsStaffData.emailAddress}.jpg`"
                                 style="height: 1.2rem; margin-top: -5px; border-radius: 50px;"
                                 class="mr-1"
                                 aria-describedby="el-tooltip-8375"
                                 tabindex="0" />
                            CS <b class="text-warning">{{currentCsStaffData ? currentCsStaffData.fullName : ""}}</b>
                        </div> 
                        <div class="text-nowrap"
                             v-if="CurrentSupportStaff">
                            <img :src="`/img/avatars/${CurrentSupportStaff.emailAddress}.jpg`"
                                 style="height: 1.2rem; margin-top: -5px; border-radius: 50px;"
                                 class="mr-1"
                                 aria-describedby="el-tooltip-8375"
                                 tabindex="0" />
                            Tech <b class="text-warning">{{CurrentSupportStaff.fullName}} - {{CurrentSupportStaff.phoneNumber}}</b>
                        </div>
                    </div>
                </VuePerfectScrollbar>

            </footer>
            <!-- /.content-wrapper -->
            <!-- Control Sidebar -->
            <aside class="control-sidebar control-sidebar-dark">
                <!-- Control sidebar content goes here -->
            </aside>
            <!-- /.control-sidebar -->
        </div>
        <!-- ./wrapper -->

        <div class="modal-region">
            <!-- Modal -->
            <div class="modal fade"
                 id="detailUserInfoGlobal"
                 tabindex="-1"
                 role="dialog"
                 aria-labelledby="exampleModalLabel"
                 aria-hidden="true"
                 style="overflow: hidden">
                <div class="modal-dialog modal-dialog-centered"
                     role="document"
                     style="max-width: 95vw">
                    <div class="modal-content" style="width: 95vw !important; height: 90vh">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                <strong class="text-muted">
                                    <i class="fas fa-info-circle text-primary"></i>&nbsp; Detail
                                    info for user
                                    <span class="text-primary" v-if="userDetailInfo !== null">
                                        {{ userDetailInfo.fullName }}
                                    </span>
                                </strong>
                            </h5>
                            <button type="button"
                                    class="close"
                                    @click="$closeUserDetailInfo()"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body d-flex">
                            <detailUser v-if="userDetailInfo !== null"
                                        :detailUserItem="userDetailInfo" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Change settings user -->
            <div class="modal fade"
                 style="justify-content: center !important"
                 id="user-settings"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content pos-relative">
                        <UserChangeSettingsUser v-if="userChangeSettingsUser > 0 && currentUser" :key="userChangeSettingsUser"
                                                :element="currentUser" />
                    </div>
                </div>
            </div>

            <!-- Create new machine fixed rental -->
            <div :class="`modal fade ${modalCenterFixedRentalCreateNew}`"
                 style="justify-content: center !important"
                 id="fixed-rental-create-new"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg"
                     :style="`${$root.windowWidth >= 1000 ? '' : 'width: 100%;'}`">
                    <div class="modal-content pos-relative m-auto"
                         :style="`max-width: 90%; ${$root.windowWidth >= 1000 ? 'width: 80vw;' : ''}`">
                        <FixedRentalCreateNew v-if="fixedRentalCreateNewKey > 0" :key="fixedRentalCreateNewKey" />
                    </div>
                </div>
            </div>

            <!-- Edit mutiple machine fixed rental -->
            <div :class="`modal fade ${modalCenterFixedRentalEdit}`"
                 style="justify-content: center !important"
                 id="fixed-rental-edit"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered"
                     style="width: 70vw">
                    <div class="modal-content">
                        <FixedRentalEdit v-if="fixedRentalEditKey" :key="fixedRentalEditKey"
                                         :elements="currentListMachine" />
                    </div>
                </div>
            </div>

            <!-- FIXED RENTAL ___ DETAILS -->
            <div :class="`modal fade ${modalCenterFixedRentalDetails}`"
                 style="justify-content: center !important"
                 id="fixed-rental-details"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered"
                     style="width: 95vw">
                    <div class="modal-content">
                        <FixedRentalDetails v-if="fixedRentalDetailsKey && currentUsername" :key="fixedRentalDetailsKey"
                                            :fixedRentalId="currentFixedRentalId"
                                            :username="currentUsername" />
                    </div>
                </div>
            </div>

            <!-- View fixed rental detail by package  -->
            <div :class="`modal fade ${modalCenterFixedRentalDetailsByPackage}`"
                 style="justify-content: center !important"
                 id="fixed-rental-details-by-package"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content pos-relative" style="width: 95vw">
                        <FixedRentalDetailsByPackage v-if="fixedRentalDetailsByPackageKey > 0 && modalFixedRentalDetailsByPackageData" 
                                :key="fixedRentalDetailsByPackageKey"
                                :packageItem="modalFixedRentalDetailsByPackageData" />
                    </div>
                </div>
            </div>

            <!-- USER SAME IP LIST -->
            <div :class="`modal fade ${modalCenterUserSameIpList}`"
                 style="justify-content: center !important"
                 id="user-same-ip-list"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <UserSameIpList v-if="userSameIpListKey > 0 && userSameIpListRequest" 
                            :key="userSameIpListKey"
                            :element="userSameIpListRequest" />
                    </div>
                </div>
            </div>

            <!-- FIXED RENTAL ___ DEACTIVATE -->
            <div :class="`modal fade ${modalCenterFixedRentalDeactivate}`"
                 style="justify-content: center !important"
                 id="fixed-rental-deactivate"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered"
                     style="width: 30vw">
                    <div class="modal-content">
                        <FixedRentalDeactivate v-if="fixedRentalDeactivateKey > 0" 
                            :key="fixedRentalDeactivateKey" />
                    </div>
                </div>
            </div>

            <!-- USER Report user -->
            <div :class="`modal fade ${modalCenterUserReportUser}`"
                 style="justify-content: center !important"
                 id="user-report-user"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered"
                     style="width: 40rem">
                    <div class="modal-content">
                        <UserReportUser v-if="userReportUserKey > 0 && currentUsername" 
                            :key="userReportUserKey" 
                            :element="currentUsername" />
                    </div>
                </div>
            </div>

            <!-- USER Report IP address -->
            <div :class="`modal fade ${modalCenterUserReportIp}`"
                 style="justify-content: center !important"
                 id="user-report-ip"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered"
                     style="width: 40rem">
                    <div class="modal-content">
                        <UserReportIp v-if="userReportIpKey > 0 && currentIp" 
                            :key="userReportIpKey" 
                            :element="currentIp" />
                    </div>
                </div>
            </div>

            <!-- USER HISTORY Add IP address to restricted list -->
            <div :class="`modal fade ${modalCenterUserHistoryAddRestrictedList}`"
                 style="justify-content: center !important"
                 id="user-history-add-restricted-list"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered"
                     style="width: 40rem">
                    <div class="modal-content">
                        <UserHistoryAddRestrictedList v-if="userHistoryAddRestrictedListKey > 0 && currentRecordUserHistory"
                            :key="userHistoryAddRestrictedListKey"
                            :element="currentRecordUserHistory" />
                    </div>
                </div>
            </div>

            <!-- SYSTEM MANAGER ___ Act in doubt -->
            <div :class="`modal fade ${modalCenterSystemManagerActInDoubt}`"
                 style="justify-content: center !important"
                 id="system-manager-act-in-doubt"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered"
                     style="width: 99vw">
                    <div class="modal-content">
                        <SystemManagerActInDoubt v-if="systemManagerActInDoubtKey > 0" 
                            :key="systemManagerActInDoubtKey" />
                    </div>
                </div>
            </div>

            <!-- SYSTEM Retricted IP address -->
            <div :class="`modal fade ${modalCenterSystemSettingsSystemRetrictedIpAddress}`"
                 style="justify-content: center !important"
                 id="system-settings-system-retricted-ip-address"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered"
                     style="width: 118rem">
                    <div class="modal-content">
                        <SystemSettingsSystemRetrictedIpAddress v-if="systemSettingsSystemRetrictedIpAddressKey > 0" 
                            :key="systemSettingsSystemRetrictedIpAddressKey" />
                    </div>
                </div>
            </div>

            <!-- POLICY Deposit limit -->
            <div :class="`modal fade ${modalCenterSystemSettingsPolicyDepositLimit}`"
                 style="justify-content: center !important"
                 id="system-settings-policy-deposit-limit"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered"
                     style="width: 115rem">
                    <div class="modal-content">
                        <SystemSettingsPolicyDepositLimit v-if="systemSettingsPolicyDepositLimitKey > 0" 
                            :key="systemSettingsPolicyDepositLimitKey" />
                    </div>
                </div>
            </div>

            <!-- POLICY Promotion deposit -->
            <div :class="`modal fade ${modalCenterSystemSettingsPolicyPromotionDeposit}`"
                 style="justify-content: center !important"
                 id="system-settings-policy-promotion-deposit"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content pos-relative" style="width: 60rem">
                        <SystemSettingsPolicyPromotionDeposit 
                            v-if="systemSettingsPolicyPromotionDepositKey > 0"
                            :key="systemSettingsPolicyPromotionDepositKey" />
                    </div>
                </div>
            </div>

            <!-- POLICY Verified email -->
            <div :class="`modal fade ${modalCenterSystemSettingsPolicyVerifiedEmail}`"
                 style="justify-content: center !important"
                 id="system-settings-policy-verified-email"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered"
                     style="width: 60rem">
                    <div class="modal-content">
                        <SystemSettingsPolicyVerifiedEmail 
                            v-if="systemSettingsPolicyVerifiedEmailKey > 0"
                            :key="systemSettingsPolicyVerifiedEmailKey" />
                    </div>
                </div>
            </div>

            <!-- POLICY Blacklist domain email -->
            <div class="modal fade"
                 style="justify-content: center !important"
                 id="system-settings-policy-blacklist-domain-email"
                 data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content pos-relative" style="width: 60rem">
                        <SystemSettingsPolicyBlacklistDomainEmail 
                            v-if="systemSettingsPolicyBlacklistDomainEmailKey"
                            :key="systemSettingsPolicyBlacklistDomainEmailKey" />
                    </div>
                </div>
            </div>

            <!-- Modal change password -->
            <div class="modal fade"
                 id="changePasswordAcount"
                 tabindex="-1"
                 role="dialog"
                 aria-labelledby="exampleModalLabel"
                 aria-hidden="true"
                 style="overflow: hidden">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                <strong class="text-muted">
                                    <i class="fas fa-lock text-primary"></i>&nbsp; Change account
                                    password
                                </strong>
                            </h5>
                            <button type="button"
                                    class="close"
                                    @click="closeChangePasswordAcount()"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body d-flex">
                            <adminChangePassword @changePasswordCompleted="logoutAccount()"/>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal enable 2 FA-->
            <div class="modal fade"
                 id="enableTfaModal"
                 tabindex="-1"
                 role="dialog"
                 aria-labelledby="enableTfaModal"
                 aria-hidden="true"
                 style="overflow: hidden">
                <div class="modal-dialog modal-dialog-centered modal-lg-custom"
                     style="width: 95%; max-width: 50rem !important">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                <strong class="text-muted">
                                    <i class="el-icon-mobile-phone text-primary mr-2"></i>&nbsp; Enable 2-Factor Auth
                                </strong>
                            </h5>
                            <button type="button"
                                    class="close"
                                    @click="closeEnableTfaModal()"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body d-flex">
                            <adminEnableTfa @enableTfaCompleted="logoutAccount(); closeEnableTfaModal();"/>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade"
                 id="startupPopupEventModal"
                 tabindex="-1"
                 role="dialog"
                 aria-labelledby="exampleModalLabel"
                 aria-hidden="true"
                 style="z-index: 99999">
                <div class="modal-dialog modal-dialog-centered"
                     style="min-width: 40% !important"
                     role="document">
                    <div class="modal-content p-0">
                        <div class="modal-body p-0" v-if="startupPopupEventContent !== null">
                            <div v-html="startupPopupEventContent.htmlContent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script> 
    import ProgressBar from '@/components/ProgressBar.vue';
    import lazyLoadComponent from "@/scripts/lazyLoadComponent";
    import SkeletonBox from "@/components/SkeletonBox";
    import "@/assets/admin-css/general.css";
    import { mapState } from "vuex";
    import authApi from "@/api/common/auth";
    import usersApi from "@/api/common/users";
    import vmMachineApi from "@/api/gpuHub/vmMachines";
    import staffShiftApi from "@/api/common/staffShiftApi";
    import baseComponent from "@/scripts/baseComponent";
    import EventBus from "@/event/EventBus"; 
    import VueCountdown from '@chenfengyuan/vue-countdown';
    import moment from 'moment';
    
    const appSystem = process.env.VUE_APP_SYSTEM;   
    const twoFactorEnabled = process.env.VUE_APP_TWO_FACTOR_ENABLED === "true";
    const appLogoWhite = process.env.VUE_APP_LOGO_WHITE;
    const appSquareLogo = process.env.VUE_APP_SQUARE_LOGO;
    import { securityHelper } from '@/helpers/securityHelper.js';
    export default {
        extends: baseComponent,

        components: {
            VueCountdown,
            ProgressBar,
            detailUser: lazyLoadComponent({
                componentFactory: () => import("@/views/users/DetailUserInfo"),
                loading: SkeletonBox,
            }),

            // User
            UserChangeSettingsUser: lazyLoadComponent({
                componentFactory: () => import("@/views/modal/UserChangeSettingsUser"),
                loading: SkeletonBox,
            }),

            // User history
            UserHistoryAddRestrictedList: lazyLoadComponent({
                componentFactory: () =>
                    import("@/views/modal/UserHistoryAddRestrictedList"),
                loading: SkeletonBox,
            }),
            UserReportUser: lazyLoadComponent({
                componentFactory: () => import("@/views/modal/UserReportUser"),
                loading: SkeletonBox,
            }),
            UserReportIp: lazyLoadComponent({
                componentFactory: () => import("@/views/modal/UserReportIp"),
                loading: SkeletonBox,
            }),

            // FIXED RENTAL
            FixedRentalCreateNew: lazyLoadComponent({
                componentFactory: () => import("@/views/modal/FixedRentalCreateNew"),
                loading: SkeletonBox,
            }),
            FixedRentalEdit: lazyLoadComponent({
                componentFactory: () => import("@/views/modal/FixedRentalEdit"),
                loading: SkeletonBox,
            }),
            FixedRentalDetails: lazyLoadComponent({
                componentFactory: () => import("@/views/modal/FixedRentalDetails"),
                loading: SkeletonBox,
            }),
            FixedRentalDetailsByPackage: lazyLoadComponent({
                componentFactory: () => import("@/views/modal/FixedRentalDetailByPackage"),
                loading: SkeletonBox,
            }),
            FixedRentalDeactivate: lazyLoadComponent({
                componentFactory: () => import("@/views/modal/FixedRentalDeactivate"),
                loading: SkeletonBox,
            }),
            UserSameIpList: lazyLoadComponent({
                componentFactory: () => import("@/views/modal/UserSameIpList"),
                loading: SkeletonBox,
            }),

            // System manager
            SystemManagerActInDoubt: lazyLoadComponent({
                componentFactory: () => import("@/views/modal/SystemManagerActInDoubt"),
                loading: SkeletonBox,
            }),

            // System settings
            SystemSettingsSystemRetrictedIpAddress: lazyLoadComponent({
                componentFactory: () =>
                    import("@/views/modal/SystemSettingsSystemRetrictedIpAddress"),
                loading: SkeletonBox,
            }),

            // Policy settings
            SystemSettingsPolicyDepositLimit: lazyLoadComponent({
                componentFactory: () =>
                    import("@/views/modal/SystemSettingsPolicyDepositLimit"),
                loading: SkeletonBox,
            }),
            SystemSettingsPolicyPromotionDeposit: lazyLoadComponent({
                componentFactory: () =>
                    import("@/views/modal/SystemSettingsPolicyPromotionDeposit"),
                loading: SkeletonBox,
            }),
            SystemSettingsPolicyVerifiedEmail: lazyLoadComponent({
                componentFactory: () =>
                    import("@/views/modal/SystemSettingsPolicyVerifiedEmail"),
                loading: SkeletonBox,
            }),
            SystemSettingsPolicyBlacklistDomainEmail: lazyLoadComponent({
                componentFactory: () =>
                    import("@/views/modal/SystemSettingsPolicyBlacklistDomainEmail"),
                loading: SkeletonBox,
            }),
            //Global component
            userActiveDetail: lazyLoadComponent({
                componentFactory: () => import("@/views/components/UserActiveView"),
                loading: SkeletonBox,
            }),
            adminChangePassword: lazyLoadComponent({
                componentFactory: () => import("@/views/modal/AdminChangePassword"),
                loading: SkeletonBox,
            }),
            adminEnableTfa: lazyLoadComponent({
                componentFactory: () => import("@/views/modal/AdminEnableTfa"),
                loading: SkeletonBox,
            }),
            navigation: lazyLoadComponent({
                componentFactory: () => import("./Navigation.vue"),
                loading: SkeletonBox,
            }),
            serverReport: lazyLoadComponent({
                componentFactory: () => import("./ServerReport.vue"),
                loading: SkeletonBox,
            }),
        },
        data() {
            return {
                appLogoWhite,
                appSquareLogo,
                countDown600Days2023Val: 0,
                countDownTargetDay: null,
                countDownIsHover: false,
                currentDateUncache: (new Date()).getTime(),
                dayOfWeek: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                intervalUpdateGeneralData: null,
                countImageOverState: null,
                lowBalanceCount: 0,
                currentListMachine: [],
                intervalCheckLowBalance: null,
                isLoading: false,
                // User
                currentUser: {},
                userSameIpListRequest: {},

                // User history
                currentRecordUserHistory: {},
                currentUsername: "",
                currentIp: "",

                // User
                userChangeSettingsUser: 0,
                userReportUserKey: 0,
                userReportIpKey: 0,
                userSameIpListKey: 0,

                // User history
                userHistoryAddRestrictedListKey: 0,

                // FIXED RENTAL
                fixedRentalCreateNewKey: 0,
                fixedRentalEditKey: 0,
                fixedRentalDetailsKey: 0,
                fixedRentalDetailsByPackageKey: 0,
                fixedRentalDeactivateKey: 0,

                // System manager
                systemManagerActInDoubtKey: 0,

                // System settings
                systemSettingsSystemRetrictedIpAddressKey: 0,

                // Policy settings
                systemSettingsPolicyDepositLimitKey: 0,
                systemSettingsPolicyPromotionDepositKey: 0,
                systemSettingsPolicyVerifiedEmailKey: 0,
                systemSettingsPolicyBlacklistDomainEmailKey: 0,

                // User
                modalCenterUserReportUser: "",
                modalCenterUserReportIp: "",
                modalCenterUserSameIpList: "",

                // User history
                modalCenterUserHistoryAddRestrictedList: "",

                // FIXED RENTAL
                modalCenterFixedRentalCreateNew: "",
                modalCenterFixedRentalEdit: "",
                modalCenterFixedRentalDetails: "",
                modalCenterFixedRentalDetailsByPackage: "",
                modalCenterFixedRentalDeactivate: "",

                // System manager
                modalCenterSystemManagerActInDoubt: "",

                // System settings
                modalCenterSystemSettingsSystemRetrictedIpAddress: "",

                // Policy settings
                modalCenterSystemSettingsPolicyDepositLimit: "",
                modalCenterSystemSettingsPolicyPromotionDeposit: "",
                modalCenterSystemSettingsPolicyVerifiedEmail: "",
                startupPopupEventContent: null,

                // Machine
                currentFixedRentalId: null,

                isPackageDrag: false,
                currentCsStaffData: null,
                modalFixedRentalDetailsByPackageData: null,
            };
        },
        created() {
            this.getCommonInformation();
            EventBus.$on("openPopup", this.openPopup);
            EventBus.$on("closePopup", this.closePopup);
        },
        mounted() {
            this.countDown600Days2023();
            this.getCountImageOverState();
            this.getCurrentCsStaff();
            this.intervalUpdateGeneralData = setInterval(() => {
                this.getCountImageOverState();
                this.getCurrentCsStaff();
                this.$store.dispatch("getExtraServiceRequestCount");
            }, 10000);
            this.loadRentalPackage();
            this.getLowBalanceUser();
            this.getCommonInformation();
            this.$store.dispatch("auth/getUserInfo");
            this.$store.dispatch("auth/getUserRole");
            this.$store.dispatch("getListStaff");
            this.$store.dispatch("getEmailAllowedChangeSource", "QA");
            this.$store.dispatch("getPrimeUserMinAmount");
            this.$store.dispatch("getUserConfiguaration");
            this.$store.dispatch("getExtraServiceRequestCount");
            this.$store.dispatch("common/currentDayOfWeek");
            this.$store.dispatch("common/getCalendarIndex");
            this.$store.dispatch("common/getTechnicalShift");
            this.$store.dispatch("license/getStatistics");
            this.intervalCheckLowBalance = setInterval(() => {
                this.getCommonInformation();
                this.$store.dispatch("common/currentDayOfWeek");
                this.$store.dispatch("common/getCalendarIndex");
                this.$store.dispatch("license/getStatistics");
                //this.$store.dispatch("common/getTechnicalShift");
            }, 10000);
            setTimeout(() => {
                this.getStartupPopupEvent();
            }, 500);

            if (this.userInfo.isDefaultPassword) {
                this.changePassword();
                setInterval(() => {
                    if (this.userInfo.isDefaultPassword) {
                        this.changePassword();
                    }
                }, 10000);
            }
            else {
                if (twoFactorEnabled) {
                    if (!this.userInfo.isIpWhiteListApply && !this.userInfo.twoFactorEnabled) {
                        this.openEnableTfaModal();
                    }
                    setInterval(() => {
                        if (!this.userInfo.isIpWhiteListApply && !this.userInfo.twoFactorEnabled) {
                            this.openEnableTfaModal();
                        }
                    }, 10000);
                }
            }
        },
        computed: {
            ...mapState({
                userInfo: (state) => state.auth.user,
                rolesInfo: (state) => state.auth.roles,
                listServicePack: (state) => state.common.listServicePack,
                availabilityServiceList: (state) => state.common.availabilityServiceList,
                availabilityServiceAIList: (state) => state.common.availabilityServiceAIList,
                decentralization: (state) => state.auth.decentralization,
                extraServiceRequestCount: (state) => state.extraServiceRequestCount,
                calendarData: (state) => state.common.technicalShift,
                currentCalendarIndex: (state) => parseInt(state.common.calendarIndex),
                currentDayOfWeek: (state) => parseInt(state.common.currentDayOfWeek),
                statistics: state => state.license.statistics,
            }),
            userDetailInfo() {
                return this.$root.userDetailInfo;
            },
            listServicePackDict() {
                return this.listServicePack.reduce(function (acc, cur, i) {
                    acc[cur.id] = cur;
                    return acc;
                }, {});
            },
            CurrentSupportStaff() {
                if (this.calendarData !== null) {
                    let rowTarget = this.calendarData[this.currentCalendarIndex];
                    if (rowTarget) {
                        if (this.currentDayOfWeek === 0) {
                            if (this.dayOfWeek[6]) {
                                let cellTarget = rowTarget[this.dayOfWeek[6].toLowerCase()];
                                return JSON.parse(cellTarget);
                            }
                        }
                        else {
                            if (this.dayOfWeek[this.currentDayOfWeek - 1]) {
                                let cellTarget = rowTarget[this.dayOfWeek[this.currentDayOfWeek - 1].toLowerCase()];
                                return JSON.parse(cellTarget);
                            }
                        }
                    }
                }
                return null;
            },
        },
        beforeDestroy() {
            clearInterval(this.intervalCheckLowBalance);
            clearInterval(this.intervalUpdateGeneralData);
        },
        watch: {
            $route(to, from) {
                this.getCountImageOverState();
            }
        },
        methods: {
            countDown600Days2023() {
                var now = moment();
                var then = appSystem === "IRENDER" ? moment("01/01/2023 00:00:00","DD/MM/YYYY HH:mm:ss").add(1000, 'days') : moment("01/06/2024 00:00:00","DD/MM/YYYY HH:mm:ss");
                var currentDate = then.diff(now);
                this.countDownTargetDay = then.format("DD-MM-YYYY");
                if(!now.isAfter(then)) this.countDown600Days2023Val = currentDate;
                else this.countDown600Days2023Val = 0;
            },
            getCountImageOverState() {
                let funcTarget = "getCountImageOverState";
                vmMachineApi[funcTarget]()
                    .then((response) => {
                        if (
                            response.data &&
                            response.data.result === 0 &&
                            response.data.data !== null
                        ) {
                            this.$set(this, 'countImageOverState', response.data.data);
                        } else {
                            //this.countImageOverState = null;
                            this.$set(this, 'countImageOverState', null);
                            if (
                                response.data &&
                                response.data.message !== null &&
                                response.data.message !== ""
                            ) {
                                //console.error(response.data.message || this.$lang.common.error);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        //this.countImageOverState = null;
                        this.$set(this, 'countImageOverState', null);
                    });
            },
            getCurrentCsStaff() {
                staffShiftApi.getCurrentStaff().then((response) => {
                    if (response.data.result === 0) {
                        this.currentCsStaffData = response.data.data;
                    }
                }).catch(error => {
                    //console.error(error);
                });
            },
            // Context menu action
            contextMenuAction(command) {
                switch (command) {
                    case "changePassword":
                        this.changePassword();
                        break;
                    case "logout":
                        this.logoutAccount();
                        break;
                }
            },
            getStartupPopupEvent() {
                authApi.getStartupPopupEvent().then((response_data) => {
                    if (response_data.data.result === 0) {
                        this.$set(this, "startupPopupEventContent", response_data.data.data);
                        if (response_data.data.data !== null) {
                            $("#startupPopupEventModal").modal("show");
                        }
                    }
                });
            },
            // Open pop-up
            openPopup(param) {
                $(`#${param.type}`).modal({ backdrop: "static" });
                switch (param.type) {
                    // Users
                    case "user-settings":
                        this.userChangeSettingsUser++;
                        this.currentUser = param.user;
                        break;
                    case "user-report-user":
                        this.modalCenterUserReportUser = "d-flex";
                        this.userReportUserKey++;
                        this.currentUsername = param.username;
                        break;
                    case "user-report-ip":
                        this.modalCenterUserReportIp = "d-flex";
                        this.userReportIpKey++;
                        this.currentIp = param.ip;
                        break;
                    case "user-same-ip-list":
                        this.modalCenterUserSameIpList = "d-flex";
                        this.userSameIpListKey++;
                        this.userSameIpListRequest = param.data;
                        break;

                    // Users history
                    case "user-history-add-restricted-list":
                        this.modalCenterUserHistoryAddRestrictedList = "d-flex";
                        this.userHistoryAddRestrictedListKey++;
                        this.currentRecordUserHistory = param.record;
                        break;

                    // FIXED RENTAL
                    case "fixed-rental-create-new":
                        this.modalCenterFixedRentalCreateNew = "d-flex";
                        this.fixedRentalCreateNewKey++;
                        break;
                    case "fixed-rental-edit":
                        this.modalCenterFixedRentalEdit = "d-flex";
                        this.fixedRentalEditKey++;
                        this.currentListMachine = param.listMachine;
                        break;
                    case "fixed-rental-details":
                        this.modalCenterFixedRentalDetails = "d-flex";
                        this.fixedRentalDetailsKey++;
                        this.currentFixedRentalId = param.data.fixedRentalId;
                        this.currentUsername = param.data.username;
                        break;
                    case "fixed-rental-details-by-package":
                        this.modalFixedRentalDetailsByPackageData = param.data;
                        this.fixedRentalDetailsByPackageKey++;
                        this.modalCenterFixedRentalDetailsByPackage = "d-flex";
                        break;
                    case "fixed-rental-deactivate":
                        this.modalCenterFixedRentalDeactivate = "d-flex";
                        this.fixedRentalDeactivateKey++;
                        break;

                    // System manager
                    case "system-manager-act-in-doubt":
                        this.modalCenterSystemManagerActInDoubt = "d-flex";
                        this.systemManagerActInDoubtKey++;
                        break;

                    // System settings
                    case "system-settings-system-retricted-ip-address":
                        this.modalCenterSystemSettingsSystemRetrictedIpAddress = "d-flex";
                        this.systemSettingsSystemRetrictedIpAddressKey++;
                        break;

                    // Policy settings
                    case "system-settings-policy-deposit-limit":
                        this.modalCenterSystemSettingsPolicyDepositLimit = "d-flex";
                        this.systemSettingsPolicyDepositLimitKey++;
                        break;
                    case "system-settings-policy-promotion-deposit":
                        this.modalCenterSystemSettingsPolicyPromotionDeposit = "d-flex";
                        this.systemSettingsPolicyDepositKey++;
                        break;
                    case "system-settings-policy-verified-email":
                        this.modalCenterSystemSettingsPolicyVerifiedEmail = "d-flex";
                        this.systemSettingsPolicyVerifiedEmailKey++;
                        break;
                    case "system-settings-policy-blacklist-domain-email":
                        this.systemSettingsPolicyBlacklistDomainEmail++;
                        break;
                }
            },

            // Close pop-up
            closePopup(popup) {
                $(`#${popup}`).modal("hide");
                switch (popup) {
                    case "user-same-ip-list":
                        this.modalCenterUserSameIpList = "";
                        this.userSameIpListKey = 0;
                        this.userSameIpListRequest = null;
                        break;

                    // Users
                    case "user-settings":
                        this.userChangeSettingsUser = 0;
                        this.currentUser = null;
                        break;
                    case "user-report-user":
                        this.modalCenterUserReportUser = "";
                        this.userReportUserKey = 0;
                        this.currentUsername = null;
                        break;
                    case "user-report-ip":
                        this.modalCenterUserReportIp = "";
                        this.userReportIpKey = 0;
                        this.currentIp = null;
                        break;

                    // Users history
                    case "user-history-add-restricted-list":
                        this.modalCenterUserHistoryAddRestrictedList = "";
                        this.userHistoryAddRestrictedListKey = 0;
                        this.currentRecordUserHistory = null;
                        break;

                    // FIXED RENTAL
                    case "fixed-rental-create-new":
                        this.modalCenterFixedRentalCreateNew = "";
                        this.fixedRentalCreateNewKey = 0;
                        break;
                    case "fixed-rental-edit":
                        this.modalCenterFixedRentalEdit = "";
                        this.fixedRentalEditKey = 0;
                        this.currentListMachine = null;
                        break;
                    case "fixed-rental-details":
                        this.modalCenterFixedRentalDetails = "";
                        this.fixedRentalDetailsKey = 0;
                        this.currentFixedRentalId = null;
                        this.currentUsername = null;
                        break;
                    case "fixed-rental-details-by-package":
                        this.modalFixedRentalDetailsByPackageData = null;
                        this.fixedRentalDetailsByPackageKey = 0;
                        this.modalCenterFixedRentalDetailsByPackage = "";
                        break;
                    case "fixed-rental-deactivate":
                        this.modalCenterFixedRentalDeactivate = "";
                        this.fixedRentalDeactivateKey = 0;
                        break;

                    // System manager
                    case "system-manager-act-in-doubt":
                        this.modalCenterSystemManagerActInDoubt = "";
                        this.systemManagerActInDoubtKey = 0;
                        break;

                    // System settings
                    case "system-settings-system-retricted-ip-address":
                        this.modalCenterSystemSettingsSystemRetrictedIpAddress = "";
                        this.systemSettingsSystemRetrictedIpAddressKey = 0;
                        break;

                    // Policy settings
                    case "system-settings-policy-deposit-limit":
                        this.modalCenterSystemSettingsPolicyDepositLimit = "";
                        this.systemSettingsPolicyDepositLimitKey = 0;
                        break;
                    case "system-settings-policy-promotion-deposit":
                        this.modalCenterSystemSettingsPolicyPromotionDeposit = "";
                        this.systemSettingsPolicyDepositKey = 0;
                        break;
                    case "system-settings-policy-verified-email":
                        this.modalCenterSystemSettingsPolicyVerifiedEmail = "";
                        this.systemSettingsPolicyVerifiedEmailKey = 0;
                        break;
                    case "system-settings-policy-blacklist-domain-email":
                        this.systemSettingsPolicyBlacklistDomainEmail = 0;
                        break;
                }
            },

            getCommonInformation() {
                this.getLowBalanceUser();
                this.$store.dispatch("auth/getUserRole");
                this.$store.dispatch("getListStaff");
                this.$store.dispatch("getEmailAllowedChangeSource", "QA");
                this.$store.dispatch("common/getServicePack");
                if(!this.isPackageDrag) this.$store.dispatch("common/getAvailabilityService");
                this.$store.dispatch("auth/basicInformation");
            },

            loadRentalPackage() {
                vmMachineApi
                    .getPackageList()
                    .then((response) => {
                        if (
                            response.data &&
                            response.data.result === 0 &&
                            response.data.data !== null
                        ) {
                            var dataObject = (() => {
                                var rv = {};
                                for (var i = 0; i < response.data.data.data.length; ++i)
                                    rv[response.data.data.data[i].id] = response.data.data.data[i];
                                return rv;
                            })();
                            this.$store.dispatch("setgpuNode", response.data.data.data);
                            this.$store.dispatch("setgpuNodeName", dataObject);
                        } else {
                            this.$store.dispatch("setgpuNode", []);
                            this.$store.dispatch("setgpuNodeName", {});
                            if (
                                response.data.message !== null &&
                                response.data.message !== ""
                            ) {
                                console.error(response.data.message || this.$lang.common.error);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$store.dispatch("setgpuNode", []);
                        this.$store.dispatch("setgpuNodeName", {});
                    });
            },
            getLowBalanceUser() {
                usersApi
                    .getLowBalanceUser()
                    .then((response) => {
                        if (
                            response.data &&
                            response.data.result === 0 &&
                            response.data.data !== null
                        ) {
                            var userLowBalance = response.data.data; //.filter(item => item.warning === 1);
                            this.$store.dispatch("setLowBalanceUser", userLowBalance);
                            this.lowBalanceCount = userLowBalance;
                            //if (userLowBalance.length > 0) {
                            //  this.showWaringToast(
                            //    `<strong>${userLowBalance.length}</strong> User has low balance for GPU Hub Service. Please check and notify to user`
                            //  );
                            //}
                        } else {
                            this.$store.dispatch("setLowBalanceUser", []);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$store.dispatch("setLowBalanceUser", []);
                    });
            },
            loadStaffList() { },
            logoutAccount() {
                console.log("logout");
                authApi
                    .logout()
                    .then((response_data) => {
                        localStorage.removeItem("user");
                        window.location = "/login";
                    })
                    .catch((error) => {
                        console.error("isAuthenticated", error);
                    });
            },
            changePassword() {
                this.$nextTick(() => {
                    $("#changePasswordAcount").modal({
                        backdrop: "static",
                        keyboard: true,
                    });
                });
            },
            closeChangePasswordAcount() {
                $("#changePasswordAcount").modal("hide");
            },
            openEnableTfaModal() {
                this.$nextTick(() => {
                    $("#enableTfaModal").modal({
                        backdrop: "static",
                        keyboard: true,
                    });
                });
            },
            closeEnableTfaModal() {
                $("#enableTfaModal").modal("hide");
            },
            onUpdateSortPackage: function (event) {
                console.log('event', event);
            },
            pakageDragStart(event) {
                this.isPackageDrag = true;
                console.log('pakageDragStart', event);
            },
            pakageDragEnd(event) {
                this.isPackageDrag = false;
                console.log('pakageDragEnd', event);
            }
        },
    };
</script>
<style>
    .card, .el-card {
        -webkit-border-radius: 10px !important;
        -moz-border-radius: 10px !important;
        border-radius: 10px !important;
    }
</style>
<style scoped>
    .content-wrapper {
        background: rgba(24, 24, 24, 0.64);
    }
    .main-sidebar, .main-footer {
        background: rgba(24, 24, 24, 0.64);
        background-color: rgba(24, 24, 24, 0.64);
        box-shadow: none !important;
        color: #fff;
    }
    .main-footer {
        background: transparent !important;
        background-color: transparent !important;
        border: none;
        color: #fff;
        border: none !important;
    }
    .nav-item.has-treeview ul .router-link-exact-active.active i,
    .nav-item.has-treeview ul .router-link-exact-active.active div {
        color: #343a40 !important;
    }

    @media screen and (max-width: 991px){
        .main-sidebar, .main-footer {
            background: rgba(24, 24, 24, 0.95) !important;
            background-color: rgba(24, 24, 24, 0.95) !important;
            box-shadow: none !important;
            color: #fff;
            z-index: 2001;
        }
    }

    .hover-visible-ctn {
        cursor: pointer;
    }

    .hover-visible {
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s;
        -moz-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        /*animation: blink 8s linear infinite;*/
    }

    .hover-visible-ctn:hover .hover-visible {
        opacity: 1;
        visibility: visible;
    }

    @keyframes blink {
        0% {
            opacity: 0;
            visibility: hidden;
        }

        20% {
            opacity: 0;
            visibility: hidden;
        }

        30% {
            opacity: 1;
            visibility: visible;
        }

        70% {
            opacity: 1;
            visibility: visible;
        }

        80% {
            opacity: 0;
            visibility: hidden;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    *,
    *:focus,
    *:hover {
        outline: none !important;
    }

    .flip-list-move {
        transition: transform 0.5s;
    }

    .no-move {
        transition: transform 0s;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .ps__rail-y, .ps__rail-x {
        background: transparent !important;
    }
</style>