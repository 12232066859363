import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
import usersApi from '@/api/common/users';
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBoxWithoutLoading from "@/components/SkeletonBoxWithoutLoading";
const queryString = require('query-string');
import { syncStatus } from "@/constant/config";
export default {

    extends: baseComponent,
    components: {
        paidTimes: lazyLoadComponent({
            componentFactory: () => import("@/views/components/common/PaidTimes"),
            loading: SkeletonBoxWithoutLoading,
        }),
    },
    props: {
        userId: {
            type: String,
            default: null
        },
    },

    data() {
        return {
            element: {
                totalRecharge: "...",
                balance: "...",
                totalImage: "...",
                imageThreshold: "...",
                useNewSyncSystem: false,
                totalStorage: "...",
                storageThreshold: "...",
                totalStorageY: "...",
                storageThresholdY: "...",
                extraBalance: "...",
                lastSyncAt: null,
                syncDataStatus: 0,
                totalImageRunning: 0,
                totalImageError: 0,
                totalImageOff: 0,
                totalImageStarting: 0,
                totalImageCreating: 0,
                totalImageShuttingDown: 0,
            },
            syncStatusConst : syncStatus
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
        }),
    },
    mounted() {
        this.getAdditionalInfo1();
    },
    methods: {
        getAdditionalInfo1() {
            if (this.userId !== null) {
                let executeFn = () => {
                    usersApi
                        .getAdditionalInfo1(this.userId)
                        .then((response) => {
                            if (response.data && response.data.result === 0) {
                                this.$set(this, "element", {
                                    totalRecharge: response.data.data.totalRecharge,
                                    balance: response.data.data.gpuHubAccountBalance,
                                    extraBalance: response.data.data.gpuHubAccountExtraBalance,
                                    totalImage: response.data.data.totalImage,
                                    imageThreshold: response.data.data.imageThreshold,

                                    useNewSyncSystem : response.data.data.useNewSyncSystem,
                                    totalStorage: response.data.data.totalStorage,
                                    storageThreshold: response.data.data.storageThreshold,
                                    totalStorageY: response.data.data.totalStorageY,
                                    storageThresholdY: response.data.data.storageThresholdY,
                                    
                                    lastSyncAt: response.data.data.lastSyncAt,
                                    syncDataStatus: response.data.data.syncDataStatus,
                                    totalImageRunning: response.data.data.totalImageRunning,
                                    totalImageError: response.data.data.totalImageError,
                                    totalImageOff: response.data.data.totalImageOff,
                                    totalImageStarting: response.data.data.totalImageStarting,
                                    totalImageCreating: response.data.data.totalImageCreating,
                                    totalImageShuttingDown: response.data.data.totalImageShuttingDown
                                });
                            } else {
                                if (response.data && response.data.message !== null && response.data.message !== "") {
                                    console.error(response.data.message || this.$lang.common.error);
                                }
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                };
                executeFn();
            }
        }
    }
}