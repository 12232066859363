import authApi from "@/api/common/auth";
import Vue from "vue";
const state = {
    status: {
        success: true,
        message: ""
    },
    user: {},
    roles: [],
    decentralization: [],
    systemManagerInformation: {},
    userHistoryCode: [],
    reportIssuesType: [],
};

const getters = {
    authStatus: state => state.status.success,
    user: state => state.user,
    roles: state => state.roles,
    decentralization: state => state.decentralization,
    systemManagerInformation: state => state.systemManagerInformation,
    userHistoryCode: state => state.userHistoryCode,
    reportIssuesType: state => state.reportIssuesType,
};

const mutations = {
    auth_request(state, check) {
        state.status = {
            success: true,
            message: "",
            status: check
        };
    },
    auth_error(state, resp) {
        state.status = {
            success: false,
            message: resp,
            status: false
        };
    },
    auth_response(state, resp) {
        state.status = {
            success: resp.success,
            message: resp.message,
            status: false
        };
        if (resp.message == null) {
            state.status.message = resp.error;
        }
        if (resp.success === true && resp.data !== null) {
            state.user = resp.data;
        }
    },
    get_user_info(state, user) {
        state.user = user;
    },
    getRoles(state, roles) {
        state.roles = roles;
    },
    logout(state) {
        state.status = {
            success: true,
            message: ""
        };
        //state.token = "";
    },

    get_basicInformation(state, data) {
        state.user.twoFactorEnabled = data.userInfomation.twoFactorEnabled;
        state.user.isIpWhiteListApply = data.userInfomation.isIpWhiteListApply;
        state.userHistoryCode = data.userHistoryCode;
        state.systemManagerInformation = data.systemManagerInformation;
        state.decentralization = data.decentralization;
        state.reportIssuesType = data.reportIssuesType;
    },

    get_decentralizationInfo(state, data) {
        state.decentralization = data.decentralization;
    },

    get_user_history_code(state, userHistoryCode) {
        console.log(JSON.stringify(userHistoryCode));
        state.userHistoryCode = userHistoryCode;
    }
};

const actions = {
    getUserInfo({ commit }) {
        const userInfo = JSON.parse(localStorage.getItem("user"));
        commit("get_user_info", userInfo);
    },
    getUserRole({ commit }) {
        authApi.getAllRoles().then((res) => {
            if (res.data.result === 0) {
                commit("getRoles", res.data.data);
            }
        });
    },

    // Basic information
    basicInformation({ commit }) {
        authApi.basicInformation().then((res) => {
            try {
                commit("get_basicInformation", res.data);
            } catch (error) {
                console.log(error);
            }
        })
        .catch(error => {
            console.log(error);
        });
    },
    decentralizationInfo({ commit }, data) {
        authApi.decentralizationInfo().then((res) => {
            try {
                commit("get_decentralizationInfo", res.data);
                if (data.callback) data.callback(res.data);
            } catch (error) {
                console.log(error);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
};

export default { namespaced: true, state, getters, mutations, actions };
