
import { MessageBox } from 'element-ui';
export default {
    showConfirm(content, confirmCallback, cancelCallback, title = `Confirm`) {
        this.showCustomWarning(title, content, confirmCallback, cancelCallback);
    },
    showSuccess(content, callback = null, title = `Success`) {
        this.showCustomSuccess(title, content, callback);
    },
    showError(content, callback = null, title = `Error`) {
        this.showCustomError(title, content, callback);
    },
    onError(errorData) {
        let errorMsg = "";
        window.console.log("errorData", errorData);
        if (errorData.message !== null && errorData.message !== '') {
            errorMsg += `${errorData.message}`;
        } else {
            errorMsg += `Sorry, something when wrong. Please try again later.<br>`;
        }
        this.showCustomError(`Failed`, errorMsg);
    },
    on500Error(errorData) {
        if (!errorData.status === 500) {
            let errorMsg = "";
            if (errorData.message !== null && errorData.message !== '') {
                errorMsg += `${errorData.message}.<br>`;
            } else {
                errorMsg += `Sorry, something when wrong. Please try again later.<br>`;
            }
            this.showCustomError(`Failed`, errorMsg);
        }
    },


    // Dialog Element
    getDialogContentHTML(content, icon) {
        return `
            <div>
                <div class="d-flex flex-row">
                    <div class="col-2 p-0"><img src="/static/image/${icon}.svg" height="50" />
                    </div>
                    <div class="p-0 col-10 position-relative">
                        <div style="color: #212121; font-weight: 500; margin-bottom: 2.5rem;" class="position-relative">
                            <div>${content}</div>
                        </div>
                    </div>
                </div>
            </div>
            `
    },
    // Warning
    showCustomWarning(title, content, confirmAction, cancelAction, confirmButtonText) {
        if (title === undefined || title === null || title === '') {
            title = 'Waring';
        }
        if (content === undefined || content === null || content === '') {
            content = 'Are you sure want action this?';
        }
        if (confirmButtonText === undefined || confirmButtonText === null || confirmButtonText === '') {
            confirmButtonText = '\xa0\xa0\xa0OK\xa0\xa0\xa0';
        }
        let icon = "notification-warning";
        MessageBox.confirm(this.getDialogContentHTML(content, icon), title, {
            dangerouslyUseHTMLString: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: 'Cancel',
            closeOnPressEscape: true,
            roundButton: true,
        }).then(() => {
            if (confirmAction) confirmAction();
        }).catch(() => {
            if (cancelAction) cancelAction();
        });
    },

    // Confirm
    showCustomConfirm(title, content, confirmAction, cancelAction) {
        if (title === undefined || title === null || title === '') {
            title = 'Confirm';
        }
        if (content === undefined || content === null || content === '') {
            content = 'Are you sure want action this?';
        }

        let icon = "notification-confirm";
        MessageBox.confirm(this.getDialogContentHTML(content, icon), title, {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '\xa0\xa0\xa0OK\xa0\xa0\xa0',
            cancelButtonText: 'Cancel',
            closeOnPressEscape: true,
            roundButton: true,
        }).then(() => {
            if (confirmAction) confirmAction();
        }).catch(() => {
            if (cancelAction) cancelAction();
        });
    },

    // Success
    showCustomSuccess(title, content, callback) {
        if (title === undefined || title === null || title === '') {
            title = 'Success';
        }
        if (content === undefined || content === null || content === '') {
            content = 'Thank you.';
        }
        let icon = "notification-success";
        MessageBox.alert(this.getDialogContentHTML(content, icon), title, {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '\xa0\xa0\xa0OK\xa0\xa0\xa0',
            closeOnPressEscape: true,
            roundButton: true,
            callback: () => {
                if (callback) callback();
            }
        });
    },

    // Error
    showCustomError(title, content, callback) {
        if (title === undefined || title === null || title === '') {
            title = 'Error';
        }
        if (content === undefined || content === null || content === '') {
            content = 'An unknown error has occurred, please try again later or contact support. Thank you.';
        }
        let icon = "notification-error";

        MessageBox.alert(this.getDialogContentHTML(content, icon), title,
            {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '\xa0\xa0\xa0OK\xa0\xa0\xa0',
                closeOnPressEscape: true,
                roundButton: true,
                callback: () => {
                    if (callback) callback();
                }
            });
    },

    // Unkown error
    showUnknowError(title, content, callback) {
        if (title === undefined || title === null || title === '') {
            title = 'Oops!';
        }
        if (content === undefined || content === null || content === '') {
            content = 'An unknown error has occurred, please try again later or contact support. Thank you.';
        }
        let icon = "notification-error";
        MessageBox.alert(this.getDialogContentHTML(content, icon), title, {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '\xa0\xa0\xa0OK\xa0\xa0\xa0',
            closeOnPressEscape: true,
            roundButton: true,
            callback: () => {
                if (callback) callback();
            }
        });
    }
}