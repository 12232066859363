import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index";
import authApi from "@/api/common/auth";
import { publicRoute, protectedRoute } from "./config";
import { checkAccessFunction } from "@/utils";
import $eventHub from "@/eventHub";
const routes = publicRoute.concat(protectedRoute);

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  routes: routes,
});

router.beforeEach((to, from, next) => {
  //console.log('router to', to);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //if (window.isLoaded) {
    //    Vue.prototype.axiosController.abort();
    //    Vue.prototype.axiosController = new AbortController();
    //    console.log('window.isLoaded from vue router');
    //}
    store.dispatch("CANCEL_PENDING_REQUESTS");
    $eventHub.$emit("asyncComponentLoading", to);
    authApi
      .isAuthenticated()
      .then((response_data) => {
        let isAuthenticated = response_data && response_data.data ? response_data.data.data || false : false;
        if (isAuthenticated) {
          if (to.meta.decentralizationName && to.meta.decentralizationName !== null) {
            const decentralization = store.state.auth.decentralization;
            if (decentralization.length === 0) {
              store.dispatch("auth/decentralizationInfo", {
                callback(decentralizationData) {
                  //console.log('decentralizationData', decentralizationData);
                  if (checkAccessFunction(decentralizationData, to.meta.decentralizationName)) {
                    next();
                    return;
                  } else {
                    next("/forbidden");
                    return;
                  }
                },
              });
            } else {
              if (checkAccessFunction(decentralization, to.meta.decentralizationName)) {
                next();
                return;
              } else {
                next("/forbidden");
                return;
              }
            }
          } else {
            next();
            return;
          }
        } else {
          next("/login");
        }
      })
      .catch((error) => {
        console.error("isAuthenticated", error);
        //next("/login");
      });
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  $eventHub.$emit("asyncComponentLoaded"); // Stop progress bar
  next();
});

export default router;
