import authApi from '@/api/common/auth';
import usersApi from '@/api/common/users';
import countryList from '@/constant/countryList';
import periodStatus from '@/constant/periodStatus';
import userLogStatus from '@/constant/userLogStatus';
import machineStatus from '@/constant/machineStatus';
import functionalCodeWebadmin from '@/constant/functionalCodeWebadmin';
import staffLogStatus from '@/constant/staffLogStatus';
import providerStatus from '@/constant/providerStatus';
import { actInDoubtStatus, actInDoubtListStatus } from '@/constant/actInDoubtStatus';
import actInDoubtCode from '@/constant/actInDoubtCode';
import restrictedIpAddressStatus from '@/constant/restrictedIpAddressStatus';
import moment from 'moment'
const appSystem = process.env.VUE_APP_SYSTEM;
const appLogoWhite = process.env.VUE_APP_LOGO_WHITE;
const appLogoBlack = process.env.VUE_APP_LOGO_BLACK;
const appSquareLogo = process.env.VUE_APP_SQUARE_LOGO;
const appLoadingImage = process.env.VUE_APP_LOADING;
const appPointIcon = process.env.VUE_APP_POINT_ICON;

const primeFeature = process.env.VUE_APP_PRIME_MENU_ENABLED;
const licenseFeature = process.env.VUE_APP_LICENSE_MENU_ENABLED;
const fixedRentalFeature = process.env.VUE_APP_FIXEDRENTAL_MENU_ENABLED;
const pointNameShort = process.env.VUE_APP_POINT_NAME_SHORT
export default {
    install(Vue, options) {
        Vue.prototype.$appSystem = appSystem;
        Vue.prototype.$appLogoWhite = appLogoWhite;
        Vue.prototype.$appLogoBlack = appLogoBlack;
        Vue.prototype.$appSquareLogo = appSquareLogo;
        Vue.prototype.$appLoadingImage = appLoadingImage;
        Vue.prototype.$appPointIcon = appPointIcon;
        Vue.prototype.$pointNameShort = pointNameShort;

        Vue.prototype.$primeFeature = primeFeature === "true";
        Vue.prototype.$licenseFeature = licenseFeature === "true";
        Vue.prototype.$fixedRentalFeature = fixedRentalFeature === "true";

        Vue.prototype.$lang = {
            common: {
                error: `Đã có lỗi xảy ra. Vui lòng kiểm tra và thử lại`,
                ItTakesfew: "It takes a few minutes to load...",
                AddressLine: "Address line",
                WorkingTime: "Working time",
                Hotline: "Hotline",
                SkypeInternational: "Skype International",
                SkypeVietnam: "Skype Vietnam",
                EmailAddress: "Email address",
                InformationDialogTitle: "Information",
                ErrorDialogTitle: "Error",
                WarningDialogTitle: "Warning",
                RemoveConfirmDialogTitle: `Remove confirm`
            },
        };

        Vue.prototype.$datePickerOptions = {
            format: 'DD/MM/YYYY',
            useCurrent: false,
        };

        Vue.prototype.$elDatePickerFormat = 'dd/MM/yyyy';
        Vue.prototype.$elDateTimePickerFormat = 'dd/MM/yyyy HH:mm:ss';
        Vue.prototype.$elDatePickerValueFormat = 'yyyy-MM-dd';
        Vue.prototype.$elDateTimePickerValueFormat = 'yyyy-MM-dd HH:mm:ss';
        Vue.prototype.$countryList = countryList;
        Vue.prototype.$periodStatus = periodStatus;
        Vue.prototype.$userLogStatus = userLogStatus;
        Vue.prototype.$machineStatus = machineStatus;
        Vue.prototype.$functionalCodeWebadmin = functionalCodeWebadmin;
        Vue.prototype.$staffLogStatus = staffLogStatus;
        Vue.prototype.$providerStatus = providerStatus;
        Vue.prototype.$actInDoubtStatus = actInDoubtStatus;
        Vue.prototype.$actInDoubtListStatus = actInDoubtListStatus;
        Vue.prototype.$actInDoubtCode = actInDoubtCode;
        Vue.prototype.$restrictedIpAddressStatus = restrictedIpAddressStatus;
        Vue.prototype.$appCode = {
            "piRender": "bg-navy",
            "gpuHub": "bg-warning",
            "iRenderGPU": "bg-navy"
        };

        Vue.prototype.$intersectionOptions = {
            root: null,
            rootMargin: '0px 0px 0px 0px',
            threshold: [0, 1] // [0.25, 0.75] if you want a 25% offset!
        };

        Vue.prototype.$pretyDateUtc = function (value) {
            if (!value) value = new Date();
            return moment.utc(value).local().calendar(null, {
                sameDay: '[Today] HH:mm:ss',
                lastDay: '[Yesterday] HH:mm:ss',
                lastWeek: 'DD/MM/YYYY HH:mm:ss',
                sameElse: 'DD/MM/YYYY HH:mm:ss',
            });
        };

        Vue.prototype.$getUserDetailInfo = function (userId) {
            this.$set(this.$root, "globalLoading", true);
            usersApi.getUserDetail(userId)
                .then((res) => {
                    this.$set(this.$root, "userDetailInfo", res.data.data);
                    console.log("this.$root.$userDetailInfo", this.$root.userDetailInfo);
                    this.$nextTick(() => {
                        $("#detailUserInfoGlobal").modal({ backdrop: 'static', keyboard: true, });
                        this.$set(this.$root, "globalLoading", false);
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.$set(this.$root, "globalLoading", false);
                });
        };

        Vue.prototype.$closeUserDetailInfo = function () {
            $("#detailUserInfoGlobal").modal("hide");
            this.$set(this, "$userDetailInfo", null);
        };

    }
}