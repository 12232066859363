import { extraServiceRequestConf } from '@/constant/config.js';
import axios from "axios";
const queryString = require('query-string');
export default {
    getElementsList(requestParam) {
        let queryParams = queryString.stringify(requestParam);
        return axios({
            method: "GET",
            url: `${extraServiceRequestConf.listExtraServiceRequest}?${queryParams}`,
            withCredentials: true,
        });
    },
    getExtraServiceRequest(id) {
        return axios({
            method: "GET",
            url: `${extraServiceRequestConf.getExtraServiceRequest.format(id)}`,
            withCredentials: true,
        });
    },
    changedStatus(id, status, data) {
        return axios({
            method: "POST",
            url: `${extraServiceRequestConf.changedStatus}`,
            data: {
                requestId: id,
                status: status,
                note: data !== null ? data.note : null,
                serviceDetailsUpdated: data !== null && data.serviceDetailsUpdated ? data.serviceDetailsUpdated : null,
                deviceInformation: data !== null ? data.deviceInformation : null,
            },
            withCredentials: true,
        });
    },
    getCountRequest() {
        return axios({
            method: "GET",
            url: `${extraServiceRequestConf.getCountRequest}`,
            withCredentials: true,
        });
    },
    closeIssues(data) {
        return axios({
            method: "POST",
            data: data,
            url: `${extraServiceRequestConf.closeIssues}`,
            withCredentials: true,
        });
    }
};
