export default [
    {
        status: 1,
        text: 'Error',
        color: 'red'
    },
    {
        status: 2,
        text: 'Running',
        color: 'forestgreen'
    },
    {
        status: 3,
        text: 'Off',
        color: 'red'
    },
    {
        status: 6,
        text: 'Ready',
        color: 'dodgerblue'
    },
    {
        status: 8,
        text: 'Shutting down (Clone)',
        color: 'darkorange'
    },
    {
        status: 9,
        text: 'Paused',
        color: 'darkorange'
    },
    {
        status: 10,
        text: 'Starting',
        color: 'rebeccapurple'
    },
    {
        status: 11,
        text: 'Reset',
        color: 'darkorange'
    },
    {
        status: 12,
        text: 'Restaring',
        color: 'darkorange'
    },
    {
        status: 13,
        text: 'Processing',
        color: 'darkorange'
    },
    {
        status: 32773,
        text: 'Creating',
        color: 'darkorange'
    },
    {
        status: 32776,
        text: 'Pausing',
        color: 'darkorange'
    },
    {
        status: 32777,
        text: 'Resuming',
        color: 'darkorange'
    },
    {
        status: 32779,
        text: 'Fast saved',
        color: 'darkorange'
    },
    {
        status: 32780,
        text: 'Fast saving',
        color: 'darkorange'
    },
];

export const runningStatus = [2];
export const startingStatus = [10];
export const restartingStatus = [11, 12];