/* eslint-disable */
import { authServiceConfig, EXECUTE_RESULT } from "@/constant/config";
import axios from "axios";
import moment from "moment";
export default {
    precheckTwoFactorEnabled(loginRawData) {
        return axios({
            method: "POST",
            withCredentials: true,
            data: loginRawData,
            url: authServiceConfig.precheckTwoFactorEnabled,
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    authLogin: function (loginRawData) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.LoginUrl,
                data: loginRawData,
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(resp => {
                let response = resp.data;
                if (response.result === EXECUTE_RESULT.SUCCESS) {
                    let user = response.data;
                    localStorage.setItem("user", JSON.stringify(user));
                    resolve(user);
                    return;
                }
                reject(resp);
                return;
            }).catch(err => {
                reject(err);
            });
        });
    },
    getEnableTwoFactorAuthInfo() {
        return axios({
            method: "GET",
            withCredentials: true,
            url: authServiceConfig.getEnableTwoFactorAuthInfo,
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    postEnableTwoFactorAuth(data) {
        return axios({
            method: "POST",
            withCredentials: true,
            url: authServiceConfig.postEnableTwoFactorAuth,
            data: data,
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    getStartupPopupEvent() {
        return axios({
            method: "GET",
            withCredentials: true,
            url: authServiceConfig.getStartupPopupEvent,
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    isAuthenticated() {
        return axios({
            method: "POST",
            url: authServiceConfig.isAuthenticatedUrl,
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    addFcmToken(token) {
        return axios({
            method: "POST",
            withCredentials: true,
            url: authServiceConfig.addFcmToken,
            data: { token : token },
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    logout() {
        return axios({
            method: "POST",
            url: authServiceConfig.logoutUrl,
            withCredentials: true,
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    changePassword(changePasswordModel) {
        return axios({
            method: "POST",
            data: changePasswordModel,
            withCredentials: true,
            url: authServiceConfig.changePasswordUrl,
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    getAllUser() {
        return axios({
            method: "GET",
            withCredentials: true,
            url: authServiceConfig.getAllUser,
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    getAllRoles() {
        return axios({
            method: "GET",
            withCredentials: true,
            url: authServiceConfig.getAllRoles,
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    getAllUsersNotInDept(intDept) {
        return axios({
            method: "GET",
            withCredentials: true,
            url: authServiceConfig.getAllUsersNotInDept.format(intDept),
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    getUserOfDepartment(intDept) {
        return axios({
            method: "GET",
            withCredentials: true,
            url: authServiceConfig.getUserOfDepartment.format(intDept),
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    getRankingUserOfDepartment(intDept, timeRanking) {
        return axios({
            method: "GET",
            withCredentials: true,
            url: `${authServiceConfig.getRankingUserOfDepartment}/${intDept !== null ? intDept : ''}?timeRanking=${timeRanking !== null ? timeRanking : ''}`,
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
    getUserInRole(roleName) {
        return axios({
            method: "GET",
            withCredentials: true,
            url: `${authServiceConfig.getUserInRole.format(roleName)}`,
            headers: {
                "Content-Type": "application/json"
            }
        })
    },

    // Get decentralization
    getDecentralization: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: authServiceConfig.getDecentralizationUrl,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },


    // Basic information
    basicInformation: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: authServiceConfig.basicInformationUrl,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) resolve(response);
                        else reject(response);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    decentralizationInfo() {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: authServiceConfig.decentralizationInfoUrl,
                withCredentials: true,
            }).then(res => {
                try {
                    let resTemp = res.toJSON();
                    if (resTemp.name === 'Error') {
                        reject(resTemp);
                    }
                } catch {
                    let response = res.data;
                    if (response.result === EXECUTE_RESULT.SUCCESS) resolve(response);
                    else reject(response);
                }
            })
            .catch(error => {
                reject(error);
            });
        })
    },

    // Get user history code
    getUserHistoryCode: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: authServiceConfig.getUserHistoryCodeUrl,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // Get staff history
    getStaffHistory: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${authServiceConfig.getStaffHistoryUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    //SYSTEM MANAGER ___ Act in doubt ___ VIEW
    systemManagerActInDoubtView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${authServiceConfig.systemManagerActInDoubtViewUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    //SYSTEM MANAGER ___ Act in doubt ___ MODIFY
    systemManagerActInDoubtModify: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.systemManagerActInDoubtModifyUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    //Policy verified email view
    policyVerifiedEmailView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: authServiceConfig.policyVerifiedEmailViewUrl,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // Policy verified email modify
    policyVerifiedEmailModify: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.policyVerifiedEmailModifyUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    //System retricted Ip address add
    systemRetrictedIpAddressAdd: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.systemRetrictedIpAddressAddUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    //System retricted Ip address view
    systemRetrictedIpAddressView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${authServiceConfig.systemRetrictedIpAddressViewUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    //Policy deposit limit view
    policyDepositLimitView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: authServiceConfig.policyDepositLimitViewUrl,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // Policy deposit limit modify
    policyDepositLimitModify: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.policyDepositLimitModifyUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // Report user
    reportUser: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.reportUserUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // Report IP address
    reportIpAddress: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.reportIpAddressUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },





    //#region Staff management ___ View
    staffManagementView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${authServiceConfig.staffManagementViewUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Role management ___ View
    roleManagementView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${authServiceConfig.roleManagementViewUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Role management ___ Modify
    roleManagementModify: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.roleManagementModifyUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Decentralized ___ View
    decentralizedView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${authServiceConfig.decentralizedViewUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Restricted email domain ___ View
    restrictedEmailDomainView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${authServiceConfig.restrictedEmailDomainViewUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Restricted email domain ___ Create
    restrictedEmailDomainCreate: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.restrictedEmailDomainCreateUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Restricted email domain ___ Modify
    restrictedEmailDomainModify: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.restrictedEmailDomainModifyUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Restricted email domain ___ Delete 
    restrictedEmailDomainDelete: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.restrictedEmailDomainDeleteUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Restricted software ___ View
    restrictedSoftwareView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${authServiceConfig.restrictedSoftwareViewUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Restricted software ___ Create
    restrictedSoftwareCreate: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.restrictedSoftwareCreateUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Restricted software ___ Modify
    restrictedSoftwareModify: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.restrictedSoftwareModifyUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Restricted software ___ Delete
    restrictedSoftwareDelete: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.restrictedSoftwareDeleteUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Restricted IP ___ View
    restrictedIpView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${authServiceConfig.restrictedIpViewUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Restricted IP ___ Modify
    restrictedIpModify: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.restrictedIpModifyUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Restricted IP ___ Delete
    restrictedIpDelete: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.restrictedIpDeleteUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Excluded IP ___ View
    excludedIpView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${authServiceConfig.excludedIpViewUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Excluded IP ___ Create
    excludedIpCreate: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.excludedIpCreateUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Excluded IP ___ Modify
    excludedIpModify: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.excludedIpModifyUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Excluded IP ___ Delete
    excludedIpDelete: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.excludedIpDeleteUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Admin functions ___ View
    adminFunctionsView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${authServiceConfig.adminFunctionsViewUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Admin functions ___ Create
    adminFunctionsCreate: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.adminFunctionsCreateUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Admin functions ___ Modify
    adminFunctionsModify: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.adminFunctionsModifyUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    //#region Admin functions ___ Delete
    adminFunctionsDelete: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: authServiceConfig.adminFunctionsDeleteUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

}