export default [
    {
        code: 10000,
        name: 'The registered country and payment card country are different',
        icon: '/actInDoubt/stripe.svg',
    },
    {
        code: 10100,
        name: 'Warning radar scores',
        icon: '/actInDoubt/stripe.svg',
    },
]