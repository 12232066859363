import Vue from "vue";
import Vuex from "vuex";
import auth from "./module/auth";
import common from "./module/common";
import license from "./module/license";
import authApi from "@/api/common/auth";
import usersApi from "@/api/common/users";
import extraServiceRequest from '@/api/common/extraServiceRequest';
Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        auth,
        common,
        license,
    },
    strict: true,
    state: {
        emailAllowedChangeSource: [],
        cancelTokens: [],
        lowBalanceUser: [],
        gpuNode: [],
        gpuNodeName: {},
        staffs: [],
        resyncHoldOn: [],
        primeUserMinAmount: [],
        userConfiguaration: [],
        extraServiceRequestCount: {
            countWaitingRequest: 0,
            countMachineRequestCompleted: 0,            
            countUserRequestCompleted: 0,
        }
    },
    getters: {
        cancelTokens(state) {
            return state.cancelTokens;
        },
        lowBalanceUserId: state => {
            return Array.from(state.lowBalanceUser, x => x.userId);
        }
    },
    mutations: {
        ADD_CANCEL_TOKEN(state, token) {
            state.cancelTokens.push(token);
        },
        CLEAR_CANCEL_TOKENS(state) {
            state.cancelTokens = [];
        },
        setLowBalanceUser(state, dataLowBalanceUser) {
            state.lowBalanceUser = dataLowBalanceUser;
        },
        setgpuNode(state, gpuNode) {
            state.gpuNode = gpuNode;
        },
        setgpuNodeName(state, gpuNodeName) {
            state.gpuNodeName = gpuNodeName;
        },
        getListStaff(state, listStaff) {
            state.staffs = listStaff;
        },
        getEmailAllowedChangeSource(state, listEmail) {
            state.emailAllowedChangeSource = listEmail;
        },
        getUserConfiguaration(state, userConfiguaration) {
            state.userConfiguaration = userConfiguaration;
        },
        addResyncHoldOn(state, resyncHoldOnUserItem) {
            let foundIndex = state.resyncHoldOn.findIndex(x => x.userId === resyncHoldOnUserItem.userId);
            if (foundIndex > -1) {
                state.resyncHoldOn[foundIndex].lastExecuted = resyncHoldOnUserItem.lastExecuted;
            }
            else {
                state.resyncHoldOn.push(resyncHoldOnUserItem);
            }
        },
        getResyncHoldOn(state, resyncHoldOnUser) {
            state.resyncHoldOn = resyncHoldOnUser;
        },
        getPrimeUserMinAmount(state, amount) {
            state.primeUserMinAmount = amount;
        },
        getExtraServiceRequestCount(state, countRequest) {
            state.extraServiceRequestCount = countRequest;
        },
    },
    actions: {
        CANCEL_PENDING_REQUESTS(context) {
            console.log("CANCEL_PENDING_REQUESTS");
            // Cancel all request where a token exists
            context.state.cancelTokens.forEach((request, i) => {
                if (request.cancel) {
                    request.cancel();
                }
            });
            // Reset the cancelTokens store
            context.commit('CLEAR_CANCEL_TOKENS');
        },
        setLowBalanceUser({ commit }, dataLowBalanceUser) {
            commit('setLowBalanceUser', dataLowBalanceUser);
        },
        setgpuNode({ commit }, gpuNode) {
            commit('setgpuNode', gpuNode);
        },
        setgpuNodeName({ commit }, gpuNodeName) {
            commit('setgpuNodeName', gpuNodeName);
        },
        getListStaff({ commit }) {
            authApi.getAllUser().then((res) => {
                //console.log(res.data.data);
                if (res.data.result === 0) {
                    commit("getListStaff", res.data.data);
                }
            });
        },
        getEmailAllowedChangeSource({ commit }, roleName) {
            Promise.all([authApi.getUserInRole("QA"), authApi.getUserInRole("OPERATOR_MANAGER ")]).then((resArray) => {
                let userAllowed = [];
                resArray.forEach(res => {
                    if (res.data.result === 0) {
                        userAllowed = userAllowed.concat(res.data.data);
                    }
                });
                commit("getEmailAllowedChangeSource", userAllowed);
            });
        },
        getUserConfiguaration({ commit }) {
            usersApi.getAllUserConfiguaration()
                .then((response) => {
                    if (response.data && response.data.result === 0) {
                        commit("getUserConfiguaration", response.data.data);
                    } 
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        addResyncHoldOn({ commit }, resyncHoldOnUserItem) {
            commit('addResyncHoldOn', resyncHoldOnUserItem);
        },
        getResyncHoldOn({ commit }, resyncHoldOnUser) {
            commit('getResyncHoldOn', resyncHoldOnUser);
        },
        getPrimeUserMinAmount({ commit }) {
            usersApi.getValMinimumRechargeOfPrimeUser().then((res) => {
                if (res.data.result === 0) {
                    commit('getPrimeUserMinAmount', res.data.data);
                }
                else commit('getPrimeUserMinAmount', 1500);
            });
        },
        getExtraServiceRequestCount({ commit }) {
            extraServiceRequest.getCountRequest().then((res) => {
                if (res.data.result === 0) {
                    commit('getExtraServiceRequestCount', res.data.data);
                }
                else commit('getExtraServiceRequestCount', 1000);
            });
        },
    }
});