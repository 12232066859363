import softwareLicenseApi from '@/api/common/softwareLicense';
const state = { 
    statistics: null, 
};

const getters = {
    statistics: state => state.statistics, 
};

const mutations = {
    getStatistics(state, data) {
        state.statistics = data;
    }, 
};

const actions = {
    getStatistics({ commit }) { 
        softwareLicenseApi.getLicenseStatistics().then((res) => {
            try {
                if (res.data.result === 0) {                           
                    commit("getStatistics", res.data.data);
                }
            } catch (error) {
                console.log("getLicenseStatistics -> error", error);
            }
        }).catch(error => {
            console.log("getLicenseStatistics -> error", error);
        });
    }, 
};

export default { namespaced: true, state, getters, mutations, actions };
