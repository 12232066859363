// Base execute result
export const EXECUTE_RESULT = {
  SUCCESS: 0,
  ERROR: 1,
};

// API call status
export const API_CALL_STATUS = {
  LOADING: 0,
  SUCCESS: 1,
  FAILED: 2,
};

// Pagination status
export const PAGINATION_STATUS = {
  ALL: 0,
  ACTIVATED: 1,
  DEACTIVATED: 2,
};

// Pop-up ID
export const POPUP_ID = {
  RESTRICTED_IP_MODIFY: 'restricted-ip-modify',
  BLACKLIST_RULE_MODIFY: 'blacklist-ip-modify'
}