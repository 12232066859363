export default [
    {
        status: 1,
        name: 'week'
    },
    {
        status: 2,
        name: 'month'
    },
    {
        status: 3,
        name: 'day'
    }
]