import { usersCnf, EXECUTE_RESULT } from '@/constant/config.js';
import axios from "axios";
const queryString = require('query-string');
export default {
    getElementsList(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${usersCnf.getListUser}?${queryParams}`,
            withCredentials: true,
        });
    },
    exportUsersList(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        //return `${usersCnf.exportListUser}?${queryParams}`;
        return axios({
            method: "GET",
            url: `${usersCnf.exportListUser}?${queryParams}`,
            withCredentials: true,
        });
    },
    getUserDetail(userId) {
        return axios({
            method: "GET",
            url: `${usersCnf.getUserDetail.format(userId)}`,
            withCredentials: true,
        });
    },
    getUserDetailByUsername(usernameOrEmail) {
        return axios({
            method: "GET",
            url: `${usersCnf.getUserDetailByUsername.format(usernameOrEmail)}`,
            withCredentials: true,
        });
    },
    getUserReferal(userId) {
        return axios({
            method: "GET",
            url: `${usersCnf.getUserReferal.format(userId)}`,
            withCredentials: true,
        });
    },
    getUserHistory(userId, pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${usersCnf.getUserHistory.format(userId)}?${queryParams}`,
            withCredentials: true,
        });
    },
    getElementsListIdle(pageNumber, requestParam, numberOfDay) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${usersCnf.getListUserIdle.format(numberOfDay)}?${queryParams}`,
            withCredentials: true,
        });
    },
    exportListUserIdle(pageNumber, requestParam, numberOfDay) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        //return `${usersCnf.exportListUserIdle.format(numberOfDay)}?${queryParams}`;
        return axios({
            method: "GET",
            url: `${usersCnf.exportListUserIdle.format(numberOfDay)}?${queryParams}`,
            withCredentials: true,
        });
    },
    getListUserSameIp(userId, pageNumber, requestParam) {
        let queryParams = queryString.stringify({
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        });

        return axios({
            method: "GET",
            url: `${usersCnf.getListUserSameIp.format(userId)}?${queryParams}`,
            withCredentials: true,
        });
    },
    getAllTop100MostUsed(pageNumber, requestParam) {
        let queryParams = queryString.stringify({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        });

        return axios({
            method: "GET",
            url: `${usersCnf.getAllTop100MostUsed}?${queryParams}`,
            withCredentials: true,
        });
    },
    getUsersReferalList(userId, pageNumber, requestParam) {
        let queryParams = queryString.stringify({
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        });

        return axios({
            method: "GET",
            url: `${usersCnf.getUsersReferalList.format(userId)}?${queryParams}`,
            withCredentials: true,
        });
    },
    getUsersBlacklist(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${usersCnf.getUsersBlacklist}?${queryParams}`,
            withCredentials: true,
        });
    },
    getPrimeUserList(querySearch) {
        return axios({
            method: "GET",
            url: `${usersCnf.getPrimeUserList}?querySearch=${querySearch}`,
            withCredentials: true,
        });
    },
    getPrimeUserReportData(userId) {
        return axios({
            method: "GET",
            url: `${usersCnf.getPrimeUserReportData}/${userId}`,
            withCredentials: true,
        });
    },
    getPrimeUserReportDataFull(userId) {
        return axios({
            method: "GET",
            url: `${usersCnf.getPrimeUserReportDataFull}/${userId}`,
            withCredentials: true,
        });
    },
    exportUsersBlacklist(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        //return `${usersCnf.exportUsersBlacklist}?${queryParams}`;
        return axios({
            method: "GET",
            url: `${usersCnf.exportUsersBlacklist}?${queryParams}`,
            withCredentials: true,
        });
    },
    getUsersPaid(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
            targetView: requestParam.targetView,
            targetRegion: requestParam.targetRegion
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${usersCnf.getUsersPaid}?${queryParams}`,
            withCredentials: true,
        });
    },
    exportUsersPaid(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        //return `${usersCnf.exportUsersPaid}?${queryParams}`;
        return axios({
            method: "GET",
            url: `${usersCnf.exportUsersPaid}?${queryParams}`,
            withCredentials: true,
        });
    },
    getUsersByStaff(staffId, pageNumber, requestParam) {
        let queryParams = queryString.stringify({
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        });

        return axios({
            method: "GET",
            url: `${usersCnf.getUsersByStaff.format(staffId)}?${queryParams}`,
            withCredentials: true,
        });
    },
    getPaidUsersByStaff(staffId, pageNumber, requestParam) {
        let queryParams = queryString.stringify({
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        });

        return axios({
            method: "GET",
            url: `${usersCnf.getPaidUsersByStaff.format(staffId)}?${queryParams}`,
            withCredentials: true,
        });
    },
    removeUser(userId) {
        return axios({
            method: "GET",
            url: usersCnf.removeUser.format(userId),
            withCredentials: true,
        });
    },
    getLowBalanceUser() {
        return axios({
            method: "GET",
            url: `${usersCnf.getLowBalanceUser}`,
            withCredentials: true,
        });
    },
    warningLowBalanceUser(userId) {
        return axios({
            method: "POST",
            url: `${usersCnf.warningLowBalanceUser.format(userId)}`,
            withCredentials: true,
        });
    },
    saveDetailUserNote(detailUserNote) {
        return axios({
            method: "POST",
            url: usersCnf.saveDetailUserNote,
            data: detailUserNote,
            withCredentials: true,
        });
    },
    getNoteById(noteId) {
        return axios({
            method: "GET",
            url: usersCnf.getNoteById.format(noteId),
            withCredentials: true,
        });
    },
    getNoteElementsList(module, itemId, pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${usersCnf.getNoteByUser.format(module, itemId)}?${queryParams}`,
            withCredentials: true,
        });
    },
    deleteNoteById(noteId) {
        return axios({
            method: "GET",
            url: usersCnf.deleteNoteById.format(noteId),
            withCredentials: true,
        });
    },
    rechargeBalance(formData) {
        return axios({
            method: "POST",
            url: usersCnf.rechargeBalance,
            data: formData,
            withCredentials: true,
        });
    },
    balanceDeduction(formData) {
        return axios({
            method: "POST",
            url: usersCnf.balanceDeduction,
            data: formData,
            withCredentials: true,
        });
    },
    getTotalRechargeInfo(userId) {
        return axios({
            method: "GET",
            url: usersCnf.getTotalRechargeInfo.format(userId),
            withCredentials: true,
        });
    },
    getUserSource() {
        return axios({
            method: "GET",
            url: usersCnf.getUserSource,
            withCredentials: true,
        });
    },
    sendWarningUserIdle(emailList) {
        return axios({
            method: "POST",
            url: usersCnf.sendWarningUserIdle,
            data: emailList,
            withCredentials: true,
        });
    },
    updateSourceUser(userId, staffId) {
        return axios({
            method: "GET",
            url: usersCnf.updateSourceUser.format(userId, staffId),
            withCredentials: true,
        });
    },
    setRegisterSource(userId, registerSource) {
        return axios({
            method: "GET",
            url: usersCnf.setRegisterSource.format(userId, registerSource),
            withCredentials: true,
        });
    },
    updateUserTakeCare(request) {
        return axios({
            method: "POST",
            url: usersCnf.updateUserTakeCare,
            withCredentials: true,
            data: request
        });
    },
    updateUserTakeCareMultiple(request) {
        return axios({
            method: "POST",
            url: usersCnf.updateUserTakeCareMultiple,
            withCredentials: true,
            data: request
        });
    },
    acceptTakeCare(request) {
        return axios({
            method: "POST",
            url: usersCnf.acceptTakeCare,
            withCredentials: true,
            data: request
        });
    },
    deactiveUser(data) {
        return axios({
            method: "POST",
            url: usersCnf.deactiveUser,
            withCredentials: true,
            data: data
        });
    },
    addtoSystemUser(data) {
        return axios({
            method: "POST",
            url: usersCnf.addtoSystemUser,
            withCredentials: true,
            data: data
        });
    },
    verifyEmailManual(data) {
        return axios({
            method: "POST",
            url: usersCnf.verifyEmailManual,
            withCredentials: true,
            data: data
        });
    },
    sendEmailManual(data) {
        return axios({
            method: "POST",
            url: usersCnf.sendEmailManual,
            withCredentials: true,
            data: data
        });
    },
    sendEmailReactiveManual(data){
        return axios({
            method: "POST",
            url: usersCnf.sendEmailReactiveManual,
            withCredentials: true,
            data: data
        });
    },
    getUserIpLocation(ipAddress) {
        return axios({
            method: "GET",
            url: usersCnf.getIpLocation.format(ipAddress),
            withCredentials: false,
        });
    },
    resyncUserData(data) {
        return axios({
            method: "POST",
            url: usersCnf.resyncUserData,
            withCredentials: true,
            data: data
        });
    },
    getValMinimumRechargeOfPrimeUser() {
        return axios({
            method: "GET",
            url: usersCnf.getValMinimumRechargeOfPrimeUser,
            withCredentials: true,
        });
    },
    getCountRealTransaction(userId) {
        return axios({
            method: "GET",
            url: `${usersCnf.getCountRealTransaction.format(userId)}`,
            withCredentials: true,
        });
    },
    getAdditionalInfo1(userId) {
        return axios({
            method: "GET",
            url: `${usersCnf.getAdditionalInfo1.format(userId)}`,
            withCredentials: true,
        });
    },
    getAdditionalInfoCountSameIp(userId) {
        return axios({
            method: "GET",
            url: `${usersCnf.getAdditionalInfoCountSameIp.format(userId)}`,
            withCredentials: true,
        });
    },
    // Change setting user
    changeSettingsUser: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: usersCnf.changeSettingsUserUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // Get users history
    getUsersHistory: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${usersCnf.getUsersHistoryUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // Get IP analysis
    getIpAnalysis: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${usersCnf.getIpAnalysisUrl}/${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // User same IP list view
    getUserSameIpView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${usersCnf.getUserSameIpViewUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    //User activity match view
    getUserActivityMatchView: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${usersCnf.getUserActivityMatchViewUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    //region User basic user information ___ View by Stripe customer Id
    basicUserInformationViewByStripeCustomerId: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${usersCnf.basicUserInformationViewByStripeCustomerIdUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    //#endregion

    getLicenseInfo(userId) {
        return axios({
            method: "GET",
            url: `${usersCnf.getLicenseInfo.format(userId)}`,
            withCredentials: true,
        });
    },
    getLicenseConnected(userId) {
        return axios({
            method: "GET",
            url: `${usersCnf.getLicenseConnected.format(userId)}`,
            withCredentials: true,
        });
    },
    getAllLicenseConnected() {
        return axios({
            method: "GET",
            url: `${usersCnf.getAllLicenseConnected}`,
            withCredentials: true,
        });
    },
    setLicenseInfo(requestInfo) {
        return axios({
            method: "POST",
            url: `${usersCnf.setLicenseInfo}`,
            data: requestInfo,
            withCredentials: true,
        });
    },
    disconnectedLicenseClient(requestInfo) {
        return axios({
            method: "POST",
            url: `${usersCnf.disconnectedLicenseClient}`,
            data: requestInfo,
            withCredentials: true,
        });
    },
    checkLicenseClient(requestInfo) {
        return axios({
            method: "POST",
            url: `${usersCnf.checkLicenseClient}`,
            data: requestInfo,
            withCredentials: true,
        });
    },
    getAllUserConfiguaration() {
        return axios({
            method: "GET",
            url: `${usersCnf.getAllUserConfiguaration}`,
            withCredentials: true,
        });
    },
    setUserConfiguaration(requestInfo) {
        return axios({
            method: "POST",
            url: `${usersCnf.setUserConfiguaration}`,
            data: requestInfo,
            withCredentials: true,
        });
    },
    changeUserPrioriry(requestInfo) {
        return axios({
            method: "POST",
            url: `${usersCnf.changeUserPrioriry}`,
            data: requestInfo,
            withCredentials: true,
        });
    },
    setSeedingInfo(requestInfo) {
        return axios({
            method: "POST",
            url: `${usersCnf.setSeedingInfo}`,
            data: requestInfo,
            withCredentials: true,
        });
    },
    getDomainInfomation(domain) {
        return axios({
            method: "GET",
            withCredentials: false,
            url: `https://www.whoisxmlapi.com/whoisserver/WhoisService?apiKey=at_QeXoCEkaFk0enmYZ6LCJ5nbMsoHj3&domainName=${domain}&outputFormat=json`
        });
    },
    getEstimateUse(userId) {
        return axios({
            method: "GET",
            withCredentials: true,
            url: `${usersCnf.getEstimateUse}/${userId}`
        });
    },
    getRenderSoftwareFocusByUser(userId) {
        return axios({
            method: "GET",
            withCredentials: true,
            url: `${usersCnf.getRenderSoftwareFocusByUser}/${userId}`
        });
    },
    getlazyLoadInfo(userId) {
        return axios({
            method: "GET",
            withCredentials: true,
            url: usersCnf.getlazyLoadInfo.format(userId)
        });
    },
    saveSettingsUsers(request) {
        return axios({
            method: "POST",
            url: `${usersCnf.saveSettingsUsers}`,
            data: request,
            withCredentials: true,
        });
    },
    setMaxonNodelockLicense(userId, isUseNodelockLicense){
        return axios({
            method: "POST",
            url: `${usersCnf.setMaxonNodelockLicense}`,
            data: {
                userId,
                isUseNodelockLicense
            },
            withCredentials: true,
        });
    }
};
