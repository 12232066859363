const basePathUrl = `${process.env.VUE_APP_SSO_AUTHORITY}`;
const userInfoUrl = `${process.env.VUE_APP_SSO_AUTHORITY}/connect/userinfo`;
export const oidcConfigs = {
  homepage: "/dashboard",
  loginPage: "/dashboard",
  issuer: basePathUrl,
  metadataPath: `${basePathUrl}/.well-known/openid-configuration`,
  authority: `${process.env.VUE_APP_SSO_AUTHORITY}`,
  client_id: `${process.env.VUE_APP_SSO_CLIENT_ID}`,
  redirect_uri: `${process.env.VUE_APP_SSO_CLIENT_URI}/auth-callback`,
  post_logout_redirect_uri: `${process.env.VUE_APP_SSO_CLIENT_URI}/login`,
  response_type: "code",
  scope: "openid profile webadmin.api promotion.api",
  response_mode: "fragment",
  filterProtocolClaims: true,
};

export { userInfoUrl };