/* eslint-disable */
//import Vue from 'vue';
import { Notification } from 'element-ui';
import EventBus from "@/event/EventBus";
import { mapState } from "vuex";
import { API_CALL_STATUS } from "@/constant/config";
import moment from "moment";
import { checkAccessFunction } from "@/utils";
import base from '@/scripts/base';

export default {
    extends: base,
    name: 'baseComponent',
    components: {
        CaretTop: () => import("@/views/components/Icon/CaretTop.vue"),
        CaretBottom: () => import("@/views/components/Icon/CaretBottom.vue"),

        ColumnUser: () => import("@/views/components/column/User.vue"),
        ColumnBalance: () => import("@/views/components/column/Balance.vue")
    },
    data() {
        return {
            baseUrl: window.location.origin,
            isLoading: false,
            loadingStatus: {
                color: '#9E9E9E',
                date: moment().format("DD/MM/YYYY hh:mm:ss [GMT]Z"),
                text: 'unknow',
            },
            msgPage: 'unknown'
        };
    },
    computed: {
        ...mapState({
            $primeUserMinAmount: (state) => state.primeUserMinAmount,
            userInfo: (state) => state.auth.user,
            rolesInfo: (state) => state.auth.roles,
            decentralization: state => state.auth.decentralization,
        }),
    },
    methods: {

        // Check access
        checkAccess(functionCode) {
            return checkAccessFunction(this.decentralization, functionCode);
        },
        getMaskedEmail(target) {
            var email = target;
            var hiddenEmail = "";
            for (let i = 0; i < email.length; i++) {
                if (i > 0 && (i < email.indexOf("@") || (i > email.indexOf("@") && i < email.lastIndexOf(".")))) {
                    hiddenEmail += "*";
                } else {
                    hiddenEmail += email[i];
                }
            }
            return hiddenEmail;
        },
        getMaskedNumber(number) {
            if (number) {
                const endDigits = number.slice(-3);
                return endDigits.padStart(number.length, '*');
            }
            else return "********"; 
        },
        none() { },
        isVisibleEmail(staffTakeCare) {
            if (["yendph@irender.vn", "thuongqth@irender.vn"].includes(this.userInfo.userEmail) || (this.rolesInfo.includes('OWNED') || this.rolesInfo.includes('QA'))) return true;
            if(staffTakeCare && staffTakeCare !== ''){
                let isShowFullMail = this.checkAccess('USER_VIEW_EMAIL') && (this.userInfo.userEmail.toLowerCase() === staffTakeCare.toLowerCase());
                return isShowFullMail;
            }
            return false;
        },
        isVisiblePhone(staffTakeCare) {
            if (["yendph@irender.vn", "thuongqth@irender.vn"].includes(this.userInfo.userEmail) || (this.rolesInfo.includes('OWNED') || this.rolesInfo.includes('QA'))) return true;
            if(staffTakeCare && staffTakeCare !== ''){
                let isShowFullPhone = this.checkAccess('USER_VIEW_PHONE') && (this.userInfo.userEmail.toLowerCase() === staffTakeCare.toLowerCase());
                return isShowFullPhone;
            }
            return false;
        },
        processEmailUser(staffTakeCare, userEmailAddress) {
            let isShowFullMail = this.isVisibleEmail(staffTakeCare);
            return (isShowFullMail ? userEmailAddress : this.getMaskedEmail(userEmailAddress));
        },
        processUserPhone(staffTakeCare, userPhone) {
            let isShowFullPhone = this.isVisiblePhone(staffTakeCare);
            return isShowFullPhone ? userPhone : this.getMaskedNumber(userPhone);
        },
        processCopyPhone(staffTakeCare, userPhone, inputId) {
            if (userPhone) {
                if (this.isVisiblePhone(staffTakeCare)) {
                    let inputIdCopy = inputId || '#userCopy';
                    this.performAction('copyToClipboard', { inputId: inputIdCopy, data: userPhone, });
                }
            }
        },
        processCopyEmail(staffTakeCare, userEmailAddress, inputId) {
            if (userEmailAddress) {
                if (this.isVisibleEmail(staffTakeCare)) {
                    let inputIdCopy = inputId || '#userCopy';
                    this.performAction('copyToClipboard', { inputId: inputIdCopy, data: userEmailAddress, });
                }
            }
        },
        stripHtml(html)
        {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        },
        isCaptainGroup() {
            return (["yendph@irender.vn", "thuongqth@irender.vn"].includes(this.userInfo.userEmail));
        },
        isValidJson(text) {
            if (text  && /^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
                replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
                replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                //the json is ok
                return true;

            } else {

                //the json is not ok
                return false;
            }
        },
        // Close pop-up
        closePopup(popup) {
            EventBus.$emit("closePopup", popup);
        },

        showLoading() {
            $("#loading-element").fadeIn(200);
            this.isLoading = true;
        },
        hideLoading() {
            $("#loading-element").fadeOut(200);
            this.isLoading = false;
        },
        copyToClipboard(inputId, value) {
            const el = document.querySelector(inputId);
            $(inputId).val(value);
            $(inputId).show();
            el.select();
            el.focus();
            var successful = document.execCommand("copy");
            $(inputId).hide();
            this.showSuccessToast(`Copied value <strong>${value}</strong> to clipboard`);
        },
        showInfoToast(message) {
            this.$notify.info({
                title: 'Info',
                dangerouslyUseHTMLString: true,
                message: message
            });
        },
        showSuccessToast(message) {
            this.$notify({
                title: 'Success',
                dangerouslyUseHTMLString: true,
                message: message,
                type: 'success'
            });
        },
        showWaringToast(message) {
            this.$notify({
                title: 'Warning',
                dangerouslyUseHTMLString: true,
                message: message,
                type: 'warning'
            });
        },
        showDangerToast(message) {
            this.$notify.error({
                title: 'Error',
                dangerouslyUseHTMLString: true,
                message: message
            });
        },
        showLineLoading(data_id) {
            $(`.line-progress[data-progress-id=${data_id}]`).fadeIn(200);
        },
        hideLineLoading(data_id) {
            $(`.line-progress[data-progress-id=${data_id}]`).fadeOut(200);
        },
        arrayToMap(array) {
            const result = Object.assign({}, ...array.map(s => ({ [s.id]: s })));
            return result;
        },
        getFileExt(fileName) {
            return (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName)[0] : undefined;
        },
        imgUrlAlt(event) {
            event.target.src = "/static/image/Extension/file.png"
        },
        showToast(title, message, type) {
            this.$notify({
                group: 'notifi-message',
                type: type,
                title: title,
                text: message
            });
        },
        async showNotify(title, message, color, btnText) {
            const res = await this.$dialog.confirm({
                text: message,
                title: title,
                actions: {
                    true: {
                        color: color,
                        text: btnText ? btnText : 'OK',
                        handle: () => {
                            return new Promise(resolve => {
                                resolve();
                            });
                        }
                    }
                }
            });
        },
        async showConfirm(title, message, callbackYes) {
            const res = await this.$dialog.confirm({
                text: message,
                title: title,
                actions: {
                    false: 'NO',
                    true: {
                        color: 'primary',
                        text: 'Yes I do',
                        handle: () => {
                            return new Promise(resolve => {
                                if (callbackYes) callbackYes();
                                resolve();
                            });
                        }
                    }
                }
            });
        },

        isNumeric(str) {
            if (typeof str != "string") return false // we only process strings!  
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },


        // Duration tiome accept
        diffTimeAccept(start, end) {
            let value = moment.utc(end).valueOf() - start;
            if (value < 0) value = 1000;
            let second = value / 1000;
            let hrs = Math.floor(second / 3600);
            let min = Math.floor((second - hrs * 3600) / 60);
            let sec = Math.floor(second - hrs * 3600 - min * 60);
            let result = "";
            if (second >= 0) {
                if (second < 1) {
                    result = parseFloat(second).toFixed(2) + "s";
                } else {
                    result += hrs > 0 ? hrs + "h " : "";
                    result += min > 0 ? min + "m " : "";
                    result += sec > 0 ? sec + "s" : "";
                }
            } else result = "- - : - -";
            return result;
        },

        // Get information client
        getInformationClient() {
            let information = {
                sourceRequest: 'WEB',
                browserDetails: '',
                timeZone: '',
                resolutionDevice: '',
            };
            let navigatorList = {
                appCodeName: navigator.appCodeName,
                appName: navigator.appName,
                appVersion: navigator.appVersion,
                cookieEnabled: navigator.cookieEnabled,
                geolocation: navigator.geolocation,
                language: navigator.language,
                onLine: navigator.onLine,
                platform: navigator.platform,
                product: navigator.product,
                userAgent: navigator.userAgent
            };
            try {
                information.browserDetails = JSON.stringify(navigatorList);

                let offset = new Date().getTimezoneOffset(),
                    o = Math.abs(offset);
                let timeZoneBrowse = (offset < 0 ? "+" : "-") + Math.floor(o / 60);
                information.timeZone = timeZoneBrowse;

                information.resolutionDevice = `${window.screen.width}x${window.screen.height}`;
            } catch { }
            return information;
        },

        // Notification
        notificationSuccess(title, message) {
            Notification.success({
                title: title,
                dangerouslyUseHTMLString: true,
                duration: 3000,
                offset: 60,
                showClose: false,
                message: message,
                type: 'success'
            });
        },
        notificationInfo(title, message) {
            Notification.message({
                title: title,
                dangerouslyUseHTMLString: true,
                duration: 3000,
                offset: 60,
                showClose: false,
                message: message,
                type: 'warning'
            });
        },
        notificationWarning(title, message) {
            Notification.warning({
                title: title,
                dangerouslyUseHTMLString: true,
                duration: 3000,
                offset: 60,
                showClose: false,
                message: message,
                type: 'warning'
            });
        },
        notificationError(title, message) {
            Notification.error({
                title: title,
                dangerouslyUseHTMLString: true,
                duration: 3000,
                offset: 60,
                showClose: false,
                message: message,
                type: 'error'
            });
        },

        // Feed status
        feedStatusLoading(type, data) {
            switch (type) {
                case API_CALL_STATUS.LOADING:
                    this.loadingStatus = {
                        color: '#9E9E9E',
                        date: moment().format("DD/MM/YYYY hh:mm:ss [GMT]Z"),
                        text: 'Loading data ...',
                    }
                    this.isLoading = true;
                    this.msgPage = 'Loading ...';
                    break;
                case API_CALL_STATUS.SUCCESS:
                    setTimeout(() => {
                        this.loadingStatus = {
                            color: 'green',
                            date: moment().format("DD/MM/YYYY hh:mm:ss [GMT]Z"),
                            text: 'The data has been updated successfully !',
                        }
                        this.isLoading = false;
                        if (data) {
                            if (data.length > 0) {
                                this.msgPage = 'Success';
                            } else {
                                this.msgPage = 'No data';
                            }
                        }
                    }, 500);
                    break;
                case API_CALL_STATUS.FAILED:
                    setTimeout(() => {
                        this.loadingStatus = {
                            color: 'red',
                            date: moment().format("DD/MM/YYYY hh:mm:ss [GMT]Z"),
                            text: 'Updated data failed !',
                        }
                        this.isLoading = false;
                        this.msgPage = 'Failed !';
                    }, 500);
                    break;
                default:
                    this.loadingStatus = {
                        color: '#9E9E9E',
                        date: moment().format("DD/MM/YYYY hh:mm:ss [GMT]Z"),
                        text: 'unknow',
                    }
                    this.isLoading = false;
                    break;
            }
        },

        //Display duration 
        caclDurationDiffDate(startTime, endTime) {
            let totalDurations = moment.duration(moment(endTime).diff(moment(startTime))).asDays();
            return Number.parseFloat(Number.parseFloat(totalDurations).toFixed(2).toString()) + ' day';
        },

        // Compare date now
        compareDateNow(date) {
            if (moment.utc(date).valueOf() > moment().local().valueOf()) {
                return true;
            } else {
                return false;
            }
        },

        // Count down
        countDown(date) {

            let result = '';

            // Set the date we're counting down to
            let countDownDate = new Date(date).getTime();

            let now = new Date();
            let nowUTC = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

            // Find the distance between now and the count down date
            let distance = countDownDate - nowUTC;

            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (seconds > 0) {
                if (seconds < 1) {
                    seconds = parseFloat(seconds).toFixed(2);
                } else {
                    result += (days > 0 ? days + 'd ' : '');
                    result += (hours > 0 ? hours + 'h ' : '');
                    result += (minutes > 0 ? minutes + 'm ' : '');
                }
            }
            else result = 'Expired !';
            return result;
        },

        // Count down
        countDown1(date) {

            let result = '';

            // Set the date we're counting down to
            let countDownDate = new Date(date).getTime();

            let now = new Date();
            let nowUTC = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

            // Find the distance between now and the count down date
            let distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (seconds > 0) {
                if (seconds < 1) {
                    seconds = parseFloat(seconds).toFixed(2);
                } else {
                    result += (days > 0 ? days + 'd ' : '');
                    result += (hours > 0 ? hours + 'h ' : '');
                    result += (minutes > 0 ? minutes + 'm ' : '');
                }
            }
            else result = 'Expired !';
            return result;
        },

        // Filter data browser
        getBrowserInfo(val) {
            if (val !== null && val.nAgt !== null && val.appVersion !== null) {
                var browserName = '';
                var fullVersion = '' + parseFloat(val.appVersion);
                var majorVersion = parseInt(val.appVersion, 10);
                var nameOffset, verOffset, ix;

                //Cốc Cốc
                if ((verOffset = val.nAgt.indexOf("coc_coc_browser")) != -1) {
                    browserName = "Cốc Cốc";
                    fullVersion = val.nAgt.substring(verOffset + 16);
                }

                //Facebook In-App Browser
                else if ((verOffset = val.nAgt.indexOf("FB_IAB")) != -1) {
                    browserName = "Facebook In-App Browser";
                    fullVersion = val.nAgt.substring(verOffset + 25);
                }

                //Messenger Lite
                else if ((verOffset = val.nAgt.indexOf("MessengerLiteForiOS")) != -1) {
                    browserName = "Messenger Lite";
                    fullVersion = val.nAgt.substring(verOffset + 25);
                }

                //Microsoft Edge
                else if ((verOffset = val.nAgt.indexOf("Edg")) != -1) {
                    browserName = "Microsoft Edge";
                    fullVersion = val.nAgt.substring(verOffset + 4);
                }

                //Microsoft Internet Explorer
                else if ((verOffset = val.nAgt.indexOf("MSIE")) != -1) {
                    browserName = "Microsoft Internet Explorer";
                    fullVersion = val.nAgt.substring(verOffset + 5);
                }

                //Chrome
                else if ((verOffset = val.nAgt.indexOf("Chrome")) != -1) {
                    browserName = "Chrome";
                    fullVersion = val.nAgt.substring(verOffset + 7);
                }

                //Safari
                else if ((verOffset = val.nAgt.indexOf("Safari")) != -1) {
                    browserName = "Safari";
                    fullVersion = val.nAgt.substring(verOffset + 7);
                    if ((verOffset = val.nAgt.indexOf("Version")) != -1)
                        fullVersion = val.nAgt.substring(verOffset + 8);
                }

                //Firefox
                else if ((verOffset = val.nAgt.indexOf("Firefox")) != -1) {
                    browserName = "Firefox";
                    fullVersion = val.nAgt.substring(verOffset + 8);
                }

                //Opera
                else if ((verOffset = val.nAgt.indexOf("Opera")) != -1) {
                    browserName = "Opera";
                    fullVersion = val.nAgt.substring(verOffset + 6);
                    if ((verOffset = val.nAgt.indexOf("Version")) != -1)
                        fullVersion = val.nAgt.substring(verOffset + 8);
                }

                // In most other browsers, "name/version" is at the end of userAgent 
                else if ((nameOffset = val.nAgt.lastIndexOf(' ') + 1) < (verOffset = val.nAgt.lastIndexOf('/'))) {
                    browserName = val.nAgt.substring(nameOffset, verOffset);
                    fullVersion = val.nAgt.substring(verOffset + 1);
                    if (browserName.toLowerCase() == browserName.toUpperCase()) {
                        browserName = navigator.appName;
                    }
                }

                // trim the fullVersion string at semicolon/space if present
                if ((ix = fullVersion.indexOf(";")) != -1)
                    fullVersion = fullVersion.substring(0, ix);
                if ((ix = fullVersion.indexOf(" ")) != -1)
                    fullVersion = fullVersion.substring(0, ix);

                majorVersion = parseInt('' + fullVersion, 10);
                if (isNaN(majorVersion)) {
                    fullVersion = '' + parseFloat(val.appVersion);
                    majorVersion = parseInt(val.appVersion, 10);
                }

                var unknown = '-';
                var os = unknown;
                var clientStrings = [
                    { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
                    { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
                    { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
                    { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
                    { s: 'Windows Vista', r: /Windows NT 6.0/ },
                    { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
                    { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
                    { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
                    { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
                    { s: 'Windows 98', r: /(Windows 98|Win98)/ },
                    { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
                    { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
                    { s: 'Windows CE', r: /Windows CE/ },
                    { s: 'Windows 3.11', r: /Win16/ },
                    { s: 'Android', r: /Android/ },
                    { s: 'Open BSD', r: /OpenBSD/ },
                    { s: 'Sun OS', r: /SunOS/ },
                    { s: 'Chrome OS', r: /CrOS/ },
                    { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
                    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
                    { s: 'Mac OS X', r: /Mac OS X/ },
                    { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
                    { s: 'QNX', r: /QNX/ },
                    { s: 'UNIX', r: /UNIX/ },
                    { s: 'BeOS', r: /BeOS/ },
                    { s: 'OS/2', r: /OS\/2/ },
                    { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
                ];

                for (var id in clientStrings) {
                    var cs = clientStrings[id];
                    if (cs.r.test(val.nAgt)) {
                        os = cs.s;
                        break;
                    }
                }

                var osVersion = unknown;

                if (/Windows/.test(os)) {
                    osVersion = /Windows (.*)/.exec(os)[1];
                    os = 'Windows';
                }

                switch (os) {
                    case 'Mac OS X':
                        if (/Mac OS X (10[\.\_\d]+)/.exec(val.nAgt) === null) {
                            osVersion = /Mac OS X (11[\.\_\d]+)/.exec(val.nAgt)[1];
                        } else {
                            osVersion = /Mac OS X (10[\.\_\d]+)/.exec(val.nAgt)[1];
                        }
                        break;

                    case 'Android':
                        osVersion = /Android ([\.\_\d]+)/.exec(val.nAgt)[1];
                        break;

                    case 'iOS':
                        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(val.appVersion);
                        osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
                        break;
                }

                return {
                    browserName: browserName,
                    fullVersion: fullVersion,
                    systemOS: os,
                    versionOS: osVersion,
                }
            } else {
                return {
                    browserName: null,
                    fullVersion: null,
                    systemOS: null,
                    versionOS: null,
                }
            }
        },
    },
}