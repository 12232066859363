import Vue from 'vue'
import moment from 'moment'
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);
//Render filter
Vue.filter('formatSize', function (size) {
    if (size === null) return "0";
    if (size >= 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
    } else if (size >= 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
    } else if (size >= 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
    } else if (size >= 1024) {
        return (size / 1024).toFixed(2) + ' KB'
    }
    if (size && size !== null) return size.toString() + ' B';
    else return "0";
})

Vue.filter('truncate', function (text, length, clamp) {
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.filter('DateTime', value => {
    if (!value) value = new Date();
    return moment(value).format('MMM DD YYYY HH:mm:ss');//.format('MM/DD/YYYY HH:mm')
})

Vue.filter('DateTimeNormal', value => {
    if (!value) value = new Date();
    return moment(value).format('DD/MM/YYYY HH:mm:ss');//.format('MM/DD/YYYY HH:mm')
})


Vue.filter('DateTimeCoupon', value => {
    if (value == undefined || value == null) return '';
    var offset = new Date().getTimezoneOffset();
    var localVal = moment(value).add(-offset, 'minutes');
    return moment(localVal).calendar(null, {
        sameDay: '[Today] hh:mm A',
        lastDay: '[Yesterday] hh:mm A',
        lastWeek: 'MMM DD YYYY hh:mm A',
        sameElse: 'MMM DD YYYY hh:mm A',
    });
})

Vue.filter('SimplyDate', value => {
    if (value == null || value == undefined || value == 0) return "";
    return moment(value).format('MMM DD YYYY');//.format('MM/DD/YYYY HH:mm')
})

Vue.filter('SimplyDate1', value => {
    if (value == null || value == undefined || value == 0) return "";
    return moment(value).format('DD-MM-YYYY');//.format('MM/DD/YYYY HH:mm')
})

Vue.filter('frameFormat', value => {
    if (!value) return "";
    return ("000000000" + value).substr(("000000000" + value).length - 4)
})

Vue.filter('totalMinute', value => {
    if (!value) value = '00:00:00';
    var hms = value;   // your input string
    var a = hms.split(':'); // split it at the colons

    // Hours are worth 60 minutes.
    var minutes = (+a[0]) * 60 + (+a[1]);

    return minutes;
})

Vue.filter('roundedMoney', value => {
    if (!value) return "-- -- -- --";
    return Math.round(value * 10000) / 10000;
})

Vue.filter('sumMoneyTask', value => {
    if (!value) return "-- -- -- --";
    let valSum = value.reduce((total, frameIt) => {
        return total + frameIt.totalCharges;
    }, 0);
    return Math.round(valSum * 10000) / 10000;
})

Vue.filter('sumDurationTask', durations => {
    if (!durations) return "-- -- -- --";
    let totalDurations = durations
        .reduce((prev, cur) => {
            return moment.duration(cur.renTimeStampTimeStampStr).add(prev);
        }, moment.duration('00:00:00'));
    let durationFormat = `${(totalDurations._data.hours > 0 ? 'HH[h]' : '')}`;
    durationFormat += `${(totalDurations._data.minutes > 0 ? 'mm[m]' : '')}`;
    durationFormat += `${(totalDurations._data.seconds > 0 ? 'ss[s]' : 's[s]')}`;
    return totalDurations.format(durationFormat);
})

Vue.filter('durationToNow', durations => {
    if (!durations) return "-- -- -- --";
    let totalSecondsVal = moment.utc().diff(moment.utc(durations), 'seconds', true)
    let totalDurations = moment.duration(totalSecondsVal, 'seconds');
    let durationFormat = `${(totalDurations._data.days > 0 ? 'D[d]' : '')}`;
    durationFormat += `${(totalDurations._data.hours > 0 ? 'H[h]' : '')}`;
    durationFormat += `${(totalDurations._data.minutes > 0 ? 'm[m]' : '')}`;
    durationFormat += `${(totalDurations._data.seconds > 0 ? 's[s]' : 's[s]')}`;
    return totalDurations.format(durationFormat, {
        trim: false
    });
});

Vue.filter('timeLeft', durations => {
    if (!durations) return "-- -- -- --";
    let totalSecondsVal = moment(durations).diff(moment(), 'seconds', true);
    let totalDurations = moment.duration(totalSecondsVal, 'seconds');
    let durationFormat = `${(totalDurations._data.days > 0 ? 'D[d]' : '')}`;
    durationFormat += `${(totalDurations._data.hours > 0 ? ' H[h]' : '')}`;
    durationFormat += `${(totalDurations._data.minutes > 0 ? ' m[m]' : '')}`;
    //durationFormat += `${(totalDurations._data.seconds > 0 ? ' s[s]' : ' s[s]')}`;
    return totalDurations.format(durationFormat, {
        trim: false
    });
});

Vue.filter('timeLeftHumanize', date => {
    if (!date) return "-- -- -- --";
    let totalSecondsVal = moment.utc(date).diff(moment.utc(), 'seconds', true);
    return moment.duration(totalSecondsVal, "seconds").humanize(true);
});

Vue.filter('durationToScheduler', durations => {
    if (!durations) return "-- -- -- --";
    let totalSecondsVal = moment.utc(durations).diff(moment.utc(), 'seconds', true)
    let totalDurations = moment.duration(totalSecondsVal, 'seconds');
    let durationFormat = `${(totalDurations._data.days > 0 ? 'D[d]' : '')}`;
    durationFormat += `${(totalDurations._data.hours > 0 ? 'H[h]' : '')}`;
    durationFormat += `${(totalDurations._data.minutes > 0 ? 'm[m]' : '')}`;
    durationFormat += `${(totalDurations._data.seconds > 0 ? 's[s]' : 's[s]')}`;
    return totalDurations.format(durationFormat, {
        trim: false
    });
})

Vue.filter('hourDuration', durations => {
    if (!durations) return "-- -- -- --";
    if (durations === 0) return "0s";
    let totalDurations = moment.duration(parseFloat(durations), 'hours');

    let durationFormat = `${(totalDurations._data.days > 0 ? 'D [days] ' : '')}`;
    durationFormat += `${(totalDurations._data.hours > 0 ? 'H [hours] ' : '')}`;
    durationFormat += `${(totalDurations._data.minutes > 0 ? 'm [minutes] ' : '')}`;
    durationFormat += `${(totalDurations._data.seconds > 0 ? 's [seconds]' : 's [seconds]')}`;
    return totalDurations.format(durationFormat);
})

Vue.filter('secondDuration', durations => {
    if (!durations) return "-- -- -- --";
    if (durations === 0) return "0s";
    let totalDurations = moment.duration(parseFloat(durations), 'seconds');
    let durationFormat = `${(totalDurations._data.days > 0 ? 'D[d]' : '')}`;
    durationFormat += `${(totalDurations._data.hours > 0 ? 'H[h]' : '')}`;
    durationFormat += `${(totalDurations._data.minutes > 0 ? 'm[m]' : '')}`;
    durationFormat += `${(totalDurations._data.seconds > 0 ? 's[s]' : 's[s]')}`;
    return totalDurations.format(durationFormat);
})

Vue.filter('secondDurationFull', durations => {
    if (!durations) return "-- -- -- --";
    if (durations === 0) return "0s";
    let totalDurations = moment.duration(parseFloat(durations), 'seconds');
    let durationFormat = `${(totalDurations._data.days > 0 ? 'D [days] ' : '')}`;
    durationFormat += `${(totalDurations._data.hours > 0 ? 'H [hours] ' : '')}`;
    durationFormat += `${(totalDurations._data.minutes > 0 ? 'm [minutes] ' : '')}`;
    durationFormat += `${(totalDurations._data.seconds > 0 ? 's [seconds]' : 's [seconds]')}`;
    return totalDurations.format(durationFormat);
})

Vue.filter('durationIrFormat', durations => {
    if (!durations) return "-- -- -- --";
    let durationsVal = moment.duration(durations);
    let durationFormat = `${(durationsVal._data.hours > 0 ? 'HH[h]' : '')}`;
    durationFormat += `${(durationsVal._data.minutes > 0 ? 'mm[m]' : '')}`;
    durationFormat += `${(durationsVal._data.seconds > 0 ? 'ss' : 's')}`;
    durationFormat += `${(durationsVal._data.milliseconds > 0 ? '[.]SSS[s]' : '[s]')}`;
    return durationsVal.format(durationFormat);
});

Vue.filter('secondDurationInhour', durations => {
    if (!durations) return "-- -- -- --";
    if (durations === 0) return "0s";
    let totalDurations = moment.duration(parseFloat(durations), 'seconds');    
    return (Math.round((totalDurations.asHours() + Number.EPSILON) * 100) / 100);
});

Vue.filter('getFileExtension', value => {
    return (/[.]/.exec(value)) ? /[^.]+$/.exec(value)[0] : undefined;
})

Vue.filter('pretyDate', value => {
    if (!value) value = new Date()
    return moment(value).calendar(null, {
        sameDay: '[Today] HH:mm:ss',
        lastDay: '[Yesterday] HH:mm:ss',
        lastWeek: 'DD/MM/YYYY HH:mm:ss',
        sameElse: 'DD/MM/YYYY HH:mm:ss',
    });
})

Vue.filter('pretyDateUtc', value => {
    if (!value) value = new Date()
    return moment.utc(value).local().calendar(null, {
        sameDay: '[Today] HH:mm:ss',
        lastDay: '[Yesterday] HH:mm:ss',
        lastWeek: 'DD/MM/YYYY HH:mm:ss',
        nextWeek: 'DD/MM/YYYY HH:mm:ss',
        nextDay: '[Tomorrow] HH:mm:ss',
        sameElse: 'DD-MM-YYYY HH:mm:ss',
    });
});

Vue.filter('pretyFromNow', value => {
    if (!value) value = new Date()
    return moment.utc(value).fromNow(true);
});

Vue.filter('pretyDateUtcWithoutTime', value => {
    if (!value) value = new Date()
    return moment.utc(value).local().calendar(null, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: 'DD/MM/YYYY',
        nextWeek: 'DD/MM/YYYY',
        nextDay: '[Tomorrow]',
        sameElse: 'DD/MM/YYYY',
    });
})

Vue.filter('dateFullTimezone', value => {
    if (!value) value = new Date()
    return moment.utc(value).local().format('MMM DD YYYY HH:mm:ss [GMT]Z')
})

Vue.filter('dateFull', value => {
    if (!value) value = new Date()
    return moment.utc(value).local().format('DD-MM-YYYY HH:mm:ss')
})

Vue.filter('dateFull1', value => {
    if (!value) value = new Date()
    return moment.utc(value).local().format('DD/MM/YYYY HH:mm:ss')
})

Vue.filter('stringTonumber', value => {
    if (!value) value = '-';
    return parseFloat(value);
})

Vue.filter('breakLineText', value => {
    return value.replace(/\n/g, '<br>\n');
})

Vue.filter('colorStatus', value => {
    switch (value) {
        case 40:
            return "Preparing";
        case 42:
            return "Analyzing";
        case 43:
            return "Success";
        case -43:
            return "Failed";
        case 4:
            return "Submited";
    }
})

Vue.filter('analysisDate', value => {
    if (!value) value = new Date()
    return moment.utc(value).local().format('MMM DD YYYY HH:mm:ss')
})

Vue.filter('detailLog', value => {
    if (!value) value = new Date()
    return moment.utc(value).local().format('MMM DD YYYY HH:mm:ss [GMT]Z')
})

Vue.filter('promotType', function (value) {
    switch (value) {
        case 1: {
            return 'Announce';

        }
        case 2: {
            return 'Promotion';

        }
        case 3: {
            return 'With link redirec';

        }
        case 4: {
            return 'Popup one time';

        }
        case 5: {
            return 'DashBoad';

        }
        case 6: {
            return 'Email notification';

        }
        default: {
            return 'Promotion';

        }
    }
});

Vue.filter('promotRecipType', function (value) {
    switch (value) {
        case 1: {
            return 'Everyone';

        }
        case 2: {
            return 'Username list';

        }
        case 3: {
            return 'Email list';

        }
        case 4: {
            return 'User by filter';

        }
        default: {
            return 'Unknown';

        }
    }
});

Vue.filter('couponStatus', function (value) {
    if (value.isUsed === true) {
        return "Used";
    } else {
        return "Unused";
    }
});

Vue.filter('campaignStatus', function (value) {
    var result = 'On running';
    switch (value) {
        case 0: {
            result = 'On running';
            break;
        }
        case 1: {
            result = 'Expired';

            break;
        }
        case 2: {
            result = 'Inactive';
            break;
        }
        case 3: {
            result = 'Waiting';
            break;
        }
    }
    return result;

});

Vue.filter('pretyDateUtcToLocal', value => {
    if (!value) value = new Date();
    var offset = new Date().getTimezoneOffset();
    var localVal = moment(value).add(-offset, 'minutes');
    return moment(localVal).calendar(null, {
        sameDay: '[Today] hh:mm A',
        lastDay: '[Yesterday] hh:mm A',
        lastWeek: 'MMM DD YYYY hh:mm A',
        sameElse: 'MMM DD YYYY hh:mm A',
    });
})

Vue.directive('numericOnly', {
    bind(el) {
        el.addEventListener('keyup', () => {
            let regex = /^[0-9]*$/
            if (!regex.test(el.value)) {
                el.value = el.value.slice(0, -1)
            }
        })
    }
})