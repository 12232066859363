import axios from "axios";
import { EXECUTE_RESULT } from "@/constant/config";

export default {
    getData: function (weekOffset = 0) {
        return axios({
            method: "GET",
            url: `/api/system-tracking-calendar/get-calendar/${weekOffset}`,
            withCredentials: true,
        });
    },
    updateCalendar: function (data) {
        return axios({
            method: "POST",
            url: `/api/system-tracking-calendar/update-calendar`,
            withCredentials: true,
            data: data
        });
    }
}