import axios from "axios";
//techshiftConfig.json
export default {
  getNumberOfTechStaff() {
    return axios({
      method: "GET",
      url: `/techshiftConfig.json`,
      baseURL: '/',
      withCredentials: true,
    });
  },
};
