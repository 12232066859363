<template>
    <button @click="sortChanged(colId)" style="border: none !important; outline: none !important;">
        {{colCaption}} 
        <span v-show="colId===orderBy">
            <span class="text-navy" v-show="directionSort === 'asc'"><i class="fas fa-caret-up"></i></span>
            <span class="text-navy" v-show="directionSort === 'desc'"><i class="fas fa-caret-down"></i></span>
        </span>
    </button>
</template>

<script>
    export default {
        props: {
            colId: {
                type: String,
                default: 'ID'
            },
            colCaption: {
                type: String,
                default: '---'
            },
            orderBy: {
                type: String,
                default: 'ID'
            },
            directionSort: {
                type: String,
                default: 'desc'
            }
        },
        methods: {
            sortChanged(colIdTarget) {
                let targetDirectionSort = this.directionSort;
                if (this.colId === colIdTarget) {                    
                    if (this.directionSort === 'desc') targetDirectionSort = 'asc';
                    else targetDirectionSort = 'desc';
                }
                else {
                    targetDirectionSort = 'desc';
                }

                this.$emit('sortChanged', {
                    orderBy: this.colId,
                    directionSort : targetDirectionSort
                });
            }
        }
    }
</script>