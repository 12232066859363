export default [

    //Deactive user
    {
        status: 10000,
        name: 'Deactive user',
        blockedUserIcon: '/staffLog/blocked.png',
        unlockedUserIcon: '/staffLog/unlocked.png',
        blockedUserDescription: 'The account has been blocked',
        unlockedUserDescription: 'The account has been unlocked',
    },

    //Update user take care
    {
        status: 10001,
        name: 'Change user take care',
        icon: '/staffLog/change-user-take-care.png',
    },

    //Accept take care user
    {
        status: 10002,
        name: 'Accept take care user',
        icon: '/staffLog/accept-take.png',
    },

    //Add note user
    {
        status: 10003,
        name: 'Add note user',
        icon: '/staffLog/add-note.png',
    },

    //Recharge
    {
        status: 10004,
        name: 'Recharge',
        icon: '/staffLog/recharge.png',
    },

    //Re-sync data
    {
        status: 10005,
        name: 'Re-sync data',
        icon: '/staffLog/resync-data.png',
    },

    //Change settings user
    {
        status: 10006,
        name: 'Change settings user',
        icon: '/staffLog/change-settings-user.png',
    },

    //Add note machine
    {
        status: 20000,
        name: 'Add note machine',
        icon: '/staffLog/add-note.png',
    },

    //Reset machine
    {
        status: 20001,
        name: 'Reset machine',
        icon: '/staffLog/reset-machine.png',
    },

    //Shutdown machine
    {
        status: 20002,
        name: 'Shutdown machine',
        icon: '/staffLog/shutdown-machine.png',
    },

    //Remove image
    {
        status: 20003,
        name: 'Remove image',
        icon: '/staffLog/remove-image.png',
    },

    //Cancel boot image
    {
        status: 20004,
        name: 'Cancel boot image',
        icon: '/staffLog/cancel-boot.png',
    },

    //Edit transaction
    {
        status: 30000,
        name: 'Edit transaction',
        icon: '/staffLog/edit-transaction.png',
    },
]