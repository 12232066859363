<template>
  <i
    :style="`color: ${directionSort === 'DESC' ? '#1e88e5' : '#BDBDBD'}`"
    class="el-icon-caret-bottom"
  ></i>
</template>

<script>
export default {
  props: {
    directionSort: {
      type: String,
      default: null,
    },
  },
};
</script>