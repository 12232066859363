import { gpuHubCnf } from '@/constant/config.js';
import axios from "axios";
const queryString = require('query-string');
import { fixedRentalConfig, EXECUTE_RESULT } from "@/constant/config";
export default {
    getPackageList() {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getListPackage}`,
            withCredentials: true,
        });
    },
    getAllCategory() {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getAllCategory}`,
            withCredentials: true,
        });
    },
    getAllSoftware() {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getAllSoftware}`,
            withCredentials: true,
        });
    },
    getAllServerReport() {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getAllServerReport}`,
            withCredentials: true,
        });
    },
    rdpGatewayAdminGetAll(data) {
        return axios({
            method: "POST",
            url: `${gpuHubCnf.rdpGatewayAdminGetAll}`,
            data: data,
            withCredentials: true,
        });
    },
    rdpGatewayAdminGetAllGateway() {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.rdpGatewayAdminGetAllGateway}`,
            withCredentials: true,
        });
    },
    rdpGatewayAdminGetGatewayMetrics() {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.rdpGatewayAdminGetGatewayMetrics}`,
            withCredentials: true,
        });
    },
    getAllServerReportAI() {
        return axios({
            method: "GET",
            url: `https://superadmin-api.irender.cloud/api/rental/get-server-report-anonymous`,
            withCredentials: false,
        });
    },
    getMachineByUser(userId) {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getMachineByUser.format(userId)}`,
            withCredentials: true,
        });
    },
    getElementsList(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
            orderBy: requestParam.orderBy,
            directionSort: requestParam.directionSort,
            isB2bList : requestParam.isB2bList
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${gpuHubCnf.getListMachine}/${requestParam.ownedTarget !== null ? requestParam.ownedTarget : ''}?${queryParams}`,
            withCredentials: true,
        });
    },
    getListMachineAbandoned(numberOfDay, pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
            orderBy: requestParam.orderBy,
            directionSort: requestParam.directionSort,
            numberOfDay: numberOfDay,
            notifyStatus: requestParam.notifyStatus,
            userLevel: requestParam.userLevel,
            staff: requestParam.staff,
            partnerId: requestParam.partnerId,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${gpuHubCnf.getListMachineAbandoned}/${requestParam.ownedTarget !== null ? requestParam.ownedTarget : ''}?${queryParams}`,
            withCredentials: true,
        });
    },
    getVmMachineFptList(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
            orderBy: requestParam.orderBy,
            directionSort: requestParam.directionSort,
            isB2bList: requestParam.isB2bList
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `https://superadmin-api.irender.cloud/api/vmMachines/get-machine-main-sv/${requestParam.ownedTarget !== null ? requestParam.ownedTarget : ''}?${queryParams}`,
            withCredentials: true,
        });
    },
    getMachineFptByUser(userId) {
        return axios({
            method: "GET",
            url: `https://superadmin-api.irender.cloud/api/vmMachines/get-machine-by-user-main-sv/${userId}`,
            withCredentials: true,
        });
    },
    exportListMachineAbandoned(numberOfDay, pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
            orderBy: requestParam.orderBy,
            directionSort: requestParam.directionSort,
            numberOfDay: numberOfDay,
        }, requestParam.filterItem));

        return `${gpuHubCnf.exportListMachineAbandoned}/${requestParam.ownedTarget !== null && requestParam.ownedTarget !== undefined ? requestParam.ownedTarget : ''}?${queryParams}`;
    },
    createBilling(machineId) {
        return axios({
            method: "POST",
            url: `${gpuHubCnf.createBilling.format(machineId)}`,
            withCredentials: true,
        });
    },
    createBillingFromHistory(machineHistoryId) {
        return axios({
            method: "POST",
            url: `${gpuHubCnf.createBillingFromHistory.format(machineHistoryId)}`,
            withCredentials: true,
        });
    },
    createBillingManual(machineid, data) {
        if (machineid === null || machineid === undefined || machineid === '') {
            data.machineId = null;
        }
        return axios({
            method: "POST",
            data: data,
            url: `${gpuHubCnf.createBillingManual}/${machineid !== null && machineid !== undefined && machineid !== '' ? machineid : ''}`,
            withCredentials: true,
        });
    },
    getMachineBootHistoryData(machineId) {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getMachineBootHistoryData.format(machineId)}`,
            withCredentials: true,
        });
    },
    getStatisticMachineBootHistoryData(machineId) {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getStatisticMachineBootHistoryData.format(machineId)}`,
            withCredentials: true,
        });
    },
    getEventLogByMachineId(machineId) {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getEventLogByMachineId.format(machineId)}`,
            withCredentials: true,
        });
    },
    getCountImageOverState() {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getCountImageOverState}`,
            withCredentials: true,
        });
    },
    getCountImageOverStateAI() {
        return axios({
            method: "GET",
            url: `https://superadmin-api.irender.cloud/api/vmMachines/get-count-image-over-state-main-sv/`,
            withCredentials: true,
        });
    },
    shutdownMachine(request) {
        return axios({
            method: "POST",
            url: gpuHubCnf.shutdownMachine,
            withCredentials: true,
            data: request
        });
    },
    resetMachine(request) {
        return axios({
            method: "POST",
            url: gpuHubCnf.resetMachine,
            withCredentials: true,
            data: request
        });
    },
    updateBootReady(machineId) {
        return axios({
            method: "POST",
            url: `${gpuHubCnf.updateBootReady.format(machineId)}`,
            withCredentials: true,
        });
    },
    removeMachine(request) {
        return axios({
            method: "POST",
            url: gpuHubCnf.removeMachine,
            withCredentials: true,
            data: request
        });
    },
    confirmToKeep(request) {
        return axios({
            method: "POST",
            url: gpuHubCnf.confirmToKeep,
            withCredentials: true,
            data: request
        });
    },
    getSoftwareInstalledByMachineId(machineId) {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getSoftwareInstalledByMachineId.format(machineId)}`,
            withCredentials: true,
        });
    },
    getProccessByMachineId(machineId) {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getProccessByMachineId.format(machineId)}`,
            withCredentials: true,
        });
    },
    notifySelectedMachines(machines) {
        return axios({
            method: "POST",
            url: gpuHubCnf.notifiedSelectedMachines,
            data:machines,
            withCredentials: true,
        });
    },
    trustedImage(machineId) {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.trustedImage.format(machineId)}`,
            withCredentials: true,
        });
    },
    removeSelectedMachine(machines) {
        return axios({
            method: "POST",
            url: gpuHubCnf.removeSelectedMachine,
            data: machines,
            withCredentials: true,
        });
    },

    fixedRentalDetailsViewByPackage(request) {
        let queryParams = queryString.stringify(request);
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${gpuHubCnf.fixedRentalDetailsViewByPackage}?${queryParams}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getMachineIssues(machineId) {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getMachineIssues}/${machineId}`,
            withCredentials: true,
        });
    },
    getLowBalanceReport() {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.getLowBalanceReport}`,
            withCredentials: true,
        });
    }
};